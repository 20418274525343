<template>
  <button class="button" :type="buttonType" :class="[buttonStyles, buttonSize, ButtonFontClass]" :data-testid="componentID()" @click="clicked">
    <i v-if="iconClasses" class="icon" :class="iconClasses"/> <slot/>
  </button>
</template>

<script>
import buttons from '@/lib/buttons';

export default {
  name: 'base-button',
  props: {
    btnStyle: {
      type: String,
      default: 'primary',
      required: false,
    },
    buttonType: {
      type: String,
      default: 'button',
      required: false,
    },
    btnFont: {
      type: String,
      default: 'inter',
      required: false,
    },
    iconClasses: {
      type: String,
      default: 'left',
      required: false,
    },
    btnSize: {
      type: String,
      default: 'large',
      required: false,
    },
  },
  computed: {
    buttonSize() {
      return buttons.buttonSize(this.btnSize);
    },
    buttonStyles() {
      return buttons.buttonStyles(this.btnStyle);
    },
    ButtonFontClass() {
      if (this.btnFont === 'barlow') {
        return 'btn-barlow-condensed';
      }

      return 'btn-inter';
    },
  },
  methods: {
    clicked(click) {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  &.btn-inter {
    font-family: $martech-display-inter;
  }

  &.btn-barlow-condensed {
    font-family: $martech-display-semi-condensed;
    text-transform: uppercase;
    font-weight: $martech-weight-medium;
    font-size: $martech-type-20;
    letter-spacing: 0.5px;
  }
}
</style>
