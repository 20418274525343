<template>
  <div class="footer-promo">
    <promos-banner-vertical vertical="subscription footer" :domains="siteConfig('global.domain')" placement="subscription promo footer" :promo-size="'native full width|billboard|hero banner'"/>
  </div>
</template>

<script>
import { PromosBannerVertical } from '@tcgplayer/martech-components';

export default {
  name: 'subscriptions-promo-banner',
  components: {
    PromosBannerVertical,
  },
};
</script>

<style lang="scss" scoped>
.footer-promo {
  padding: $martech-spacer-3;
}
</style>
