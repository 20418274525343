import { getField, updateField } from 'vuex-map-fields';

export default ({
  namespaced: true,
  state: {
    game: 'magic',
    magic: {
      deckName: '',
      formats: [],
      author: '',
      cardsInDeck: [],
      eventName: '',
      eventStartDate: '',
      eventEndDate: '',
      eventPlacementType: 'finish',
      eventPlacementWins: '',
      eventPlacementLosses: '',
      eventPlacementTies: '',
      eventPlacementFinish: [],
      marketPriceType: '',
      marketPriceValue: '',
      commander: '',
      companion: '',
      colorType: 'Include',
      colors: [],
      tournamentDecksOnly: false,
    },
    yugioh: {
      deckName: '',
      formats: [],
      author: '',
      cardsInDeck: [],
      eventName: '',
      eventStartDate: '',
      eventEndDate: '',
      eventPlacementType: 'finish',
      eventPlacementWins: '',
      eventPlacementLosses: '',
      eventPlacementTies: '',
      eventPlacementFinish: [],
      marketPriceType: '',
      marketPriceValue: '',
      skillCard: '',
      tournamentDecksOnly: false,
    },
  },
  actions: {
    setGame(context, game) {
      context.commit('setGame', game || 'magic');
    },
    clearGameFields(context) {
      context.commit('clearGameFields');
    },
  },
  getters: {
    getField,
    getGameField(state, field) {
      return getField(state[state.game], field);
    },
  },
  mutations: {
    updateField,
    setGame(state, game) {
      state.game = game || 'magic';
    },
    clearGameFields(state) {
      const keys = Object.keys(state[state.game]);

      for (let i = 0; i < keys.length; i++) {
        if (Array.isArray(state[state.game][keys[i]])) {
          state[state.game][keys[i]] = [];
        } else if (typeof state[state.game][keys[i]] === 'boolean') {
          state[state.game][keys[i]] = false;
        } else {
          state[state.game][keys[i]] = '';
        }
      }
    },
    updateGameField(state, field) {
      updateField(state[state.game], field);
    },
  },
});
