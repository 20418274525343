<template>
  <div class="article-list martech-card shadow" :data-testid="componentID()">
    <box-card
      v-for="article in filteredArticles"
      :key="article.canonicalURL"
      :article="article"
      :show-author="showAuthor"
      :show-date="showDate"
      :show-format="showFormat"
      :show-tag="showTag"
      :show-vertical="showVertical"
      :show-read-time="showReadTime"
      :large="large"
      @click="onClick(article)"/>
  </div>
</template>

<script>
import BoxCard from '@/components/card-elements/BoxCard.vue';

export default {
  name: 'box-view',
  components: {
    BoxCard,
  },
  props: {
    articles: {
      type: Array,
      required: true,
      default: null,
    },
    filter: {
      type: Function,
      required: false,
      default: article => true,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
    showAuthor: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    showFormat: {
      type: Boolean,
      default: false,
    },
    showTag: {
      type: Boolean,
      default: false,
    },
    showReadTime: {
      type: Boolean,
      default: false,
    },
    showVertical: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredArticles() {
      return this.articles.filter(this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
.article-list {
  padding: $martech-spacer-3;
  width: 100%;
  background-color: $martech-white;
}
</style>
