<template>
  <div v-if="deviceType === 'mobile'" class="mobile-product-details__wrapper" :data-testid="componentID()">
    <mobile-product-details-drawer
      :data="cardData"
      :is-flipped="isFlipped"
      :loaded="loaded"
      :show="show"
      default-tab="details"
      :card-product-url="cardProductUrl"
      :image-dimensions="imageDimensions"
      :is-product="isProduct"
      @amplitude-event="clicked"
      @flip="$emit('flip')"
      @close="close"/>
  </div>
</template>

<script>
import { MobileProductDetailsDrawer } from '@tcgplayer/martech-components';
import deviceType from '@/mixins/deviceType';

export default {
  name: 'mobile-product-details-sheet',
  components: {
    MobileProductDetailsDrawer,
  },
  mixins: [ deviceType ],
  props: {
    analyticsData: {
      type: Object,
      required: false,
      // eslint-disable-next-line
      default: () => { return { analyticsCampaign: '', analyticsSource: '', }; }
    },
    cardData: {
      type: Object,
      required: false,
      default: () => {},
    },
    loaded: {
      type: Boolean,
      required: false,
    },
    isProduct: {
      type: Boolean,
      required: false,
    },
    show: {
      type: Boolean,
      required: false,
    },
    isFlipped: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardProductUrl: {
      type: String,
      required: false,
      default: '',
    },
    imageDimensions: {
      type: Object,
      required: false,
      default: () => ({
      }),
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    clicked() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-product-details__wrapper {
  position: fixed;
  z-index: 2147483641;
}

:deep(.mobile-product-details__image) {
  padding: 0.5rem 0 2.5rem;
}
</style>
