<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="events-icon" :data-testid="componentID()"><g data-name="Layer 2"><path d="M14.94 26.85a2.73 2.73 0 000 1 2 2 0 01-.12.19l-2.18 2.17a1.15 1.15 0 01-.7.29 1.21 1.21 0 01-.56-.16l-3.59-2.13-3.5 3.5a1 1 0 01-1.41 0L.29 29.12a1 1 0 010-1.41l3.5-3.5-2.16-3.59a1 1 0 01.12-1.26l2.17-2.18a1 1 0 011.42 0l1.74 1.74 1.51-1.51L10 18.83l-1.51 1.51 3.17 3.17L13.17 22l1.41 1.41-1.5 1.51 1.74 1.74a1.22 1.22 0 01.12.19zm16.77 2.27l-2.59 2.59a1 1 0 01-1.41 0l-3.5-3.5-3.59 2.17a1 1 0 01-1.26-.13l-2.18-2.17a1 1 0 010-1.42l1.74-1.74L1 7 0 1.14A1 1 0 011.14 0L7 1l17.92 17.92 1.74-1.74a1 1 0 011.42 0l2.17 2.18a1.15 1.15 0 01.29.7 1.21 1.21 0 01-.16.56l-2.17 3.59 3.5 3.5a1 1 0 010 1.41zm-11.37-5.61l3.17-3.17L6 2.87l-3.78-.65L2.87 6zm-7.64 3.86L4.63 19.3l-.89.89 2.62 4.28-3.95 3.94 1.18 1.17 3.94-3.94 4.28 2.62zM18.83 10l-1.42-1.41L25 1l5.86-1A1 1 0 0132 1.14L31 7l-7.59 7.59L22 13.17 29.13 6l.65-3.82-3.78.69zm10.76 18.41l-3.95-3.94 2.62-4.28-.89-.89-8.07 8.07.89.89 4.28-2.62 3.94 3.95z" fill="currentColor" data-name="Layer 1"/></g></svg>
</template>

<script>
export default {
  name: 'events-icon',
};
</script>
