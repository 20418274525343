<template>
  <div tabindex="0" class="accessibility-wrapper" :data-testid="componentID()" @keyup.enter="navigateOnEnter">
    <slot/>
  </div>
</template>

<script>
import delve from 'dlv';

export default {
  name: 'on-enter-navigate-card',
  props: {
    data: {
      type: Object,
      required: true,
    },
    externalLink: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    navigateOnEnter(e) {
      const url = delve(this, 'data.canonicalURL');
      e.stopPropagation();

      if (this.externalLink) {
        window.location = url;
      } else {
        this.$router.push({ path: url });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accessibility-wrapper {
  width: 100%;
}
</style>
