<template>
  <div class="no-content-found">
    <div class="heading">
      <h2 class="martech-sub-heading martech-text-capitalize">
        {{ title }}
      </h2>
      <p>
        {{ subtitle }} <template v-if="contentType === 'bestiary'">
          <base-link link-url="#" class="clear-filters" @click="clearFilters">
            clear your filters.
          </base-link>
        </template>
      </p>
    </div>

    <div v-show="articlesLength" class="latest">
      <latest-content
        v-if="contentType === 'articles' || contentType === 'bestiary'"
        skip-article-pool
        show-vertical-link
        :rows="latestContentCardCount"
        :vertical-name="verticalLatestContent"
        :placement="`${format} article landing`"
        content-type="article"
        :grid-row-count="latestContentGridRowCount"
        :series="seriesName"
        :title="`Recent articles ${verticalDisplayName}`"
        @item-count="setArticlesLength"/>
      <decks-grid
        v-else-if="contentType === 'decks'"
        :vertical-name="verticalName"
        :title="`Recent ${verticalDisplayName} Decks`"
        :item-count="8"
        show-vertical-link
        :grid-rows-count="4"/>
      <events-grid
        v-else
        :vertical-name="verticalName"
        :title="`Recent ${verticalDisplayName} Events`"
        :item-count="8"
        show-vertical-link
        :grid-rows-count="4"/>
    </div>
  </div>
</template>

<script setup>
import {
  ref, computed, getCurrentInstance, inject
} from 'vue';
import {
  VerticalHelpers as verticals
} from '@tcgplayer/martech-components';
import LatestContent from '@/components/article-widgets/LatestContent.vue';
import DecksGrid from '@/components/deck-widgets/DecksGrid.vue';
import EventsGrid from '@/components/deck-widgets/EventsGrid.vue';

const props = defineProps({
  verticalName: {
    type: String,
    required: true,
  },
  format: {
    type: String,
    required: false,
    default: '',
  },
  contentType: {
    type: String,
    required: false,
    default: 'articles',
  },
  series: {
    type: String,
    rquired: false,
    default: '',
  },
});

const emit = defineEmits([ 'clear-filters' ]);

const moreGames = inject('moreGames');
const colors = inject('tagLandingCommanderBestiarySeriesColors');
const bestiarySeriesName = inject('tagLandingCommanderBestiarySeriesName');

const seriesName = props.series || bestiarySeriesName;
const articlesLength = ref();

const setArticlesLength = (length) => {
  articlesLength.value = length;
};

const verticalDisplayName = computed(() => {
  if (moreGames) {
    return 'on TCGplayer';
  }

  if (seriesName) return `in ${seriesName}`;

  return `in ${verticals.displayName(props.verticalName)}`;
});

const siteConfig = getCurrentInstance()?.appContext.config.globalProperties.siteConfig;

const verticalLatestContent = computed(() => {
  if (moreGames) {
    return siteConfig('global.moreGames');
  }

  return props.verticalName;
});

const commanderColors = computed(() => {
  if (colors.length === 2) {
    return Object.values(colors).toString().replace(/,(?=[^,]+$)/, ' and ');
  }

  return Object.values(colors).toString().replaceAll(',', ', ').replace(/,(?=[^,]+$)/, ', and ');
});

const latestContentCardCount = computed(() => (props.contentType === 'bestiary' ? 4 : 8));
const latestContentGridRowCount = computed(() => (props.contentType === 'bestiary' ? 1 : 2));

const title = computed(() => {
  const format = props.format ? `${props.format} ` : '';
  const moreGamesVertical = moreGames ? `in ${verticals.displayName(props.verticalName)}` : '';

  switch (props.contentType) {
    case 'articles': return `No ${format} Articles Found ${moreGamesVertical}`;
    case 'bestiary': return `No Articles found matching ${commanderColors.value} commanders.`;
    case 'decks': return `No ${format} Decks Found`;
    case 'events': return `No ${format} Events Found`;
    default: return `No Articles Found ${moreGamesVertical}`;
  }
});

const subtitle = computed(() => {
  const format = props.format ? 'in this format' : '';

  switch (props.contentType) {
    case 'articles': return `We haven't added any articles ${format} yet. Check back soon!`;
    case 'bestiary': return `We haven't added any ${commanderColors.value} commanders yet. Try other color combinations or `;
    case 'decks': return `We haven't added decks ${format} yet. Check back soon!`;
    case 'events': return `We haven't added tournament results ${format} yet. Check back soon!`;
    default: return 'We havent added any articles yet. Check back soon!';
  }
});

const clearFilters = () => {
  emit('clear-filters');
};
</script>

<style lang="scss" scoped>
.no-content-found {
  .heading {
    padding: $martech-spacer-5 $martech-spacer-2 0 $martech-spacer-2;
    text-align: center;

    @include breakpoint(1024) {
      padding: $martech-spacer-5;
    }

    h2 {
      margin-bottom: $martech-spacer-2;

      span {
        text-transform: capitalize;
      }
    }

    p {
      margin-bottom: $martech-spacer-5;
    }
  }

  .latest {
    padding: $martech-spacer-4;
    border-radius: $martech-radius-medium;
    background-color: $martech-gray-0;

    :deep(.latest-content) {
      h2 {
        margin-bottom: $martech-spacer-2;
      }
    }
  }

  .clear-filters {
    color: $martech-blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>