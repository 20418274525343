<template>
  <div v-if="filteredArticles.length > 0" :data-testid="componentID()">
    <transition slot="articles" name="fade-card-result">
      <div class="events-grid">
        <div class="events-wrapper">
          <div v-for="article in filteredArticles" :key="article.uuid" class="item">
            <grid-event-card :data="article" @click="onClick(article)"/>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import delve from 'dlv';
import { GridEventCard } from '@tcgplayer/martech-components';

export default {
  name: 'events-grid',
  components: {
    GridEventCard,
  },
  props: {
    articles: {
      type: Array,
      required: true,
      default: () => [],
    },
    filter: {
      type: Function,
      required: false,
      default: article => true,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
  },
  computed: {
    filteredArticles() {
      return this.articles.filter(this.filter);
    },
  },
  methods: {
    type(article) {
      const type = delve(article, 'type', delve(article, 'type', '')) || 'event';
      return type.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.events-grid {
  display: grid;
  position: relative;
  height: 100%;
  width: 100%;

  .events-wrapper {
    display: block;
    list-style: none;

    @include breakpoint(768) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $martech-spacer-3;
    }

    @include breakpoint(1200) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(1500) {
      grid-template-columns: repeat(4, 1fr);
    }

    .item {
      padding-bottom: $martech-spacer-3;

      :deep(.event) {
        height: 100%;
      }

      @include breakpoint(1024) {
        padding-bottom: 0;
      }
    }

    .item:first-child {
      @include breakpoint(1200) {
        grid-column: 1/3;
        grid-row: 1/3;
        height: 424px;
      }

      :deep(.event) {
        @include breakpoint(1024) {
          height: 424px;
        }

        h3 {
          @include breakpoint(1024) {
            font-size: $martech-type-32;
            line-height: 2.125rem;
          }
        }

        .background-overlay {
          height: 160px;
        }

        :deep(.new-tag) {
          width: 5rem;
          height: 2rem;
        }
      }
    }
  }
}
</style>
