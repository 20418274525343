const state = {
  isVisible: false,
  isAccountVisible: false,
  isNewsVisible: false,
  isExitIntentVisible: false,
};

const actions = {
  toggleNavDrawer(context, isVisible) {
    context.commit('toggleNavDrawer', isVisible);
  },
  toggleAccountDrawer(context, isAccountVisible) {
    context.commit('toggleAccountDrawer', isAccountVisible);
  },
  toggleNewsletterDrawer(context, isNewsletterVisible) {
    context.commit('toggleNewsletterDrawer', isNewsletterVisible);
  },
  toggleNewsDrawer(context, isNewsVisible) {
    context.commit('toggleNewsDrawer', isNewsVisible);
  },
  toggleExitIntent(context, isExitIntentVisible) {
    context.commit('toggleExitIntent', isExitIntentVisible);
  },
};

const mutations = {
  toggleNavDrawer(state, isVisible) {
    state.isVisible = isVisible;
    const body = document.getElementsByTagName('body')[0];
    if (isVisible) {
      state.isVisible = true;
      body.classList.add('no-scroll');
    } else {
      state.isVisible = false;
      body.classList.remove('no-scroll');
    }
  },
  toggleAccountDrawer(state, isAccountVisible) {
    state.isAccountVisible = isAccountVisible;
    const body = document.getElementsByTagName('body')[0];
    if (isAccountVisible) {
      state.isAccountVisible = true;
      body.classList.add('no-scroll');
    } else {
      state.isAccountVisible = false;
      body.classList.remove('no-scroll');
    }
  },
  toggleNewsDrawer(state, isNewsVisible) {
    state.isNewsVisible = isNewsVisible;
  },
  toggleExitIntent(state, isExitIntentVisible) {
    state.isExitIntentVisible = isExitIntentVisible;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
