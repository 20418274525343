export default {
  buttonSize(btnSize) {
    switch (btnSize) {
      case 'x-large':
      case 'medium':
      case 'small':
      case 'x-small':
        return btnSize;
      default:
        return 'large';
    }
  },
  buttonStyles(btnStyle) {
    switch (btnStyle) {
      case 'secondary-outline':
      case 'mint-accent':
      case 'mint-accent-outline':
      case 'primary-mint-alt':
      case 'primary':
      case 'primary-wide':
      case 'text-only':
      case 'clear':
        return btnStyle;
      default:
        return 'primary-black-alt';
    }
  },
};