<template>
  <delay-load @visible="onVisible">
    <div class="card-showcase" :data-testid="componentID()">
      <div class="card-showcase-row">
        <div v-for="card in cards" :key="card.name" class="card-showcase-column" :class="{'break-four' : cards.length === 4}">
          <card-showcase-card :card="card" :is-product="isProduct" :loading="!Object.keys(card).length"/>
          <market-price-template :price="card.marketPrice"/>
        </div>
      </div>
    </div>
  </delay-load>
</template>

<script>
import delve from 'dlv';
import { mapState } from 'vuex';
import Api from '@/api/api';
import CardShowcaseCard from '@/components/card-showcase/Card/Card.vue';
import DelayLoad from '@/components/DelayLoad.vue';
import { MarketPriceTemplate } from '@tcgplayer/martech-components';

export default {
  name: 'card-showcase',
  components: {
    CardShowcaseCard,
    DelayLoad,
    MarketPriceTemplate,
  },
  props: {
    ids: {
      type: String,
      required: true,
      default: 'Comma Separated List of Card Names',
    },
    vertical: {
      type: String,
      required: true,
      default: 'The vertical to which the cards belong. Ignored if isProduct is true.',
    },
    variantSet: {
      type: String,
      required: false,
      default: '',
      note: 'A set abbrieviation to use when choosing which card variants to show',
    },
    isProduct: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      cards: [],
    };
  },
  computed: {
    ...mapState('article', [ 'article', 'analytics' ]),
    productCount() {
      return this.ids.split('||').length;
    },
  },
  methods: {
    async onVisible() {
      let { variantSet } = this;

      if (delve(this, 'article.format', '').toLowerCase() === 'speed duel') {
        variantSet = 'speed duel';
      }

      if (this.ids) {
        for (let i = 0; i < this.productCount; i++) {
          this.cards.push({});
        }

        const vert = this.vertical.toLowerCase();
        const prm = this.ids.split('||')
          .map(s => s.trim())
          .map(s => s.replace('&nbsp;', ''))
          .filter(s => s.length > 0)
          .map(id => (this.isProduct ? Api.getProductByID(id) : Api.getCard(id, vert, null, variantSet)))
          .map(p => p.catch(error => null));
        Promise.all(prm).then((values) => {
          this.cards = [];

          Object.values(values).forEach((c) => {
            if (!c) { return; }

            const card = delve(c, 'data.result.card', delve(c, 'data.result', {})) || {};
            card.image = this.imageURL(delve(card, 'scryfallImageURL', delve(card, 'tcgImageURL')));

            this.cards.push(card);
          });
        });
      }

      if (this.name && this.image) {
        this.render = true;
      }
    },
    imageURL(imageURL) {
      if (!imageURL || !imageURL.includes('/scryfall-cards/')) return imageURL;

      return imageURL.replace('/large/', '/normal/');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-showcase {
  width: 100%;

  .card-showcase-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;

    @include breakpoint(768) {
      max-width: 430px;
    }

    @include breakpoint(1024) {
      max-width: 630px;
    }

    .card-showcase-column {
      margin: 5px;
      position: relative;
      min-width: 135px;

      @include breakpoint(768) {
        min-width: 150px;

        &.break-four {
          flex: 0 32%;
        }
      }
    }
  }
}
</style>
