import { DateHelper as dates } from '@tcgplayer/martech-components';

export default {
  computed: {
    showNewTag() {
      const articleDate = dates.newDate(this.articleDate || this.date);
      const toDays = 1000 * 60 * 60 * 24;
      return Math.floor((new Date() - articleDate) / toDays) < 2;
    },
  },
};
