import delve from 'dlv';
import { getCurrentInstance } from 'vue';

export default {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        componentID(suffix) {
          let parentName;
          let currentParent = delve(this, '$parent');
          // delay-load & base-styles tells us nothing and is more likely to collide, lets grab the next parent as the container
          const skip = [ 'delay-load', 'base-styles' ];

          do {
            parentName = delve(currentParent, '$options.name', '');
            currentParent = delve(currentParent, '$parent');
          } while (currentParent && skip.includes(parentName));

          // Let's see if this is a composition API based component
          if (!parentName) {
            const instance = getCurrentInstance();
            parentName = delve(instance, 'parent.type.__name', '');
          }

          const c = delve(this, '$options.name', '');

          const base = parentName ? `${parentName}__${c}` : c;
          return suffix ? `${base}__${suffix.toLowerCase()}` : base;
        },
      },
    });
  },
};
