<template>
  <div class="restricted-article" :data-testid="componentID()">
    <div class="fade-white">
      <p>Subscribe now to continue reading this article.</p>
    </div>
    <promos-banner-vertical vertical="restricted" :domains="siteConfig('global.domain')" placement="restricted article" promo-size="extra large rectangle"/>
    <p class="sub-login">
      Already a TCGplayer Subscriber? <base-link link-url="#" @click="loginRedirect">
        Log in to your TCGplayer Account
      </base-link>
    </p>
  </div>
</template>

<script setup>
import { BaseLink, PromosBannerVertical, Auth } from '@tcgplayer/martech-components';

async function loginRedirect() {
  const loginUrl = await Auth.getLoginUrl(window.location.href);
  window.location = loginUrl;
}
</script>

<style lang="scss" scoped>
.restricted-article {
  .fade-white {
    height: 150px;
    margin-top: -175px;
    background: linear-gradient( transparent 0%, white 65%);
    z-index: $martech-z-index-50;
    position: relative;

    @include breakpoint(768) {
      background: linear-gradient( transparent 0%, white 100%);
    }

    p {
      position: absolute;
      bottom: $martech-spacer-2;
      left: 0;
      right: 0;
      text-align: center;
      font-weight: $martech-weight-semibold;
      font-size: $martech-type-16;
    }
  }

  :deep(.martech-promos-banner) {
    .sponsored-wrap {
      margin: 0;
    }

    .martech-promos-wrapper {
      margin: auto auto $martech-spacer-2;
    }

  }

  .sub-login {
    font-size: $martech-type-14;
    text-align: center;

    :deep(.martech-base-link) {
      font-size: inherit;
    }
  }
}
</style>