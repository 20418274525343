<template>
  <!--eslint-disable-next-line-->
  <path d="M18.74 16.63l13.2 13.2c.2.26.3.55.3.87 0 .32-.1.58-.3.77l-.86.87c-.2.19-.45.29-.77.29s-.61-.1-.87-.3l-13.2-13.3-13.2 13.3c-.27.2-.55.3-.88.3-.32 0-.57-.1-.77-.3l-.86-.86c-.2-.2-.3-.45-.3-.77s.1-.61.3-.87l13.3-13.2L.53 3.42a1.4 1.4 0 0 1-.3-.87c0-.32.1-.58.3-.77l.86-.87c.2-.19.45-.28.77-.28.33 0 .61.1.87.28l13.2 13.2L29.45.92c.26-.19.55-.28.87-.28.32 0 .58.1.77.28l.87.87c.19.2.29.45.29.77s-.1.61-.3.87l-13.2 13.2z" :data-testid="componentID()"/>
</template>

<script>
export default {
  name: 'close-icon',
};
</script>
