import delve from 'dlv';

export default {
  data() {
    return {
      observer: null,
      visible: false,
    };
  },
  mounted() {
    if (!this.$refs.root) {
      console.warn('root ref must be set for delay load to work.'); // eslint-disable-line
      return;
    }

    this.observer = new IntersectionObserver(this.observerCheck);
    this.observer.observe(this.$refs.root);
  },
  methods: {
    observerCheck(entries, observer) {
      if (delve(entries, '0.isIntersecting') || delve(entries, '1.isIntersecting')) {
        try {
          observer.unobserve(this.$refs.root);
        } catch (err) {
          // If there is a problem swallow the error and make the component visible
        }
        this.visible = true;

        if (this.onVisible) {
          this.onVisible();
        }
      }
    },
  },
};
