import { computed, ref, getCurrentInstance } from 'vue';
import { get, set } from '@vueuse/core';
import { useStore } from 'vuex';

import amplitudeEvent from '@tcgplayer/amplitude';
import url from '@/lib/url';

export default function useSpotlightCore(cardName) {
  const store = useStore();

  // eslint-disable-next-line no-underscore-dangle
  const componentName = getCurrentInstance()?.type.__name;
  const data = ref({});
  const productUrl = ref();
  const flipImage = ref();
  const image = ref();
  const layout = ref();
  const name = ref();
  const rarity = ref();
  const setName = ref();
  const description = ref();
  const mp = ref();
  const manaCost = ref();
  const typeLine = ref();
  const pricePoints = ref({
    Normal: {},
    Foil: {},
  });
  const cost = ref();
  const tcgPlayerID = ref();
  const cardType = ref();
  const game = ref();
  const cardData = ref({});
  const types = ref([]);

  const analytics = computed(() => store.state.article.analytics);
  const article = computed(() => store.state.article.article);
  const author = computed(() => store.state.article.author);

  const cardBuyUrl = computed(() => url.urlUtm(get(analytics), get(productUrl)));

  const getImageURL = (imageURL, tcgURL) => {
    if (imageURL) {
      return imageURL.replace('/large/', '/normal/');
    }
    return tcgURL;
  };

  const processCardResponse = (response) => {
    set(data, response?.data?.result?.card || response?.data?.result || {});
    set(tcgPlayerID, get(data)?.tcgPlayerID || get(data)?.id);
    set(image, getImageURL(get(data)?.scryfallImageURL || get(data)?.tcgImageURL || get(data)?.externalImageURL || ''));
    set(name, get(data)?.displayName || get(data)?.name || '');
    set(description, get(data)?.description || '');
    set(game, get(data)?.game || '');
    set(layout, get(data)?.layout || '');
    set(mp, get(data)?.marketPrice || null);
    set(productUrl, get(data)?.tcgProductURL || '');
    set(rarity, get(data)?.rarity || '');
    set(setName, get(data)?.setName || '');
    set(cardType, get(data)?.cardType || '');
    set(flipImage, getImageURL(get(data)?.scryfallFlipImageURL || get(data)?.tcgFlipImageURL || ''));
    set(manaCost, get(data)?.manaCost || '');
    set(cost, get(data)?.cost || '');
    set(cardType, get(data)?.cardType || '');
    set(typeLine, get(data)?.typeLine || '');
    set(types, get(data)?.types || []);

    set(cardData, {
      flipImageURL: get(flipImage),
      imageURL: get(image),
      layout: get(layout),
      name: get(name),
      rarity: get(rarity),
      setName: get(setName),
      description: get(description),
      marketPrice: get(mp),
      productUrl: get(productUrl),
      pricePoints: get(pricePoints),
      tcgPlayerID: get(tcgPlayerID),
      cardType: get(cardType),
      game: get(game),
      manaCost: get(manaCost),
      typeLine: get(typeLine),
      types: get(types),
    });

    return response;
  };

  const processPricePointsResponse = (response) => {
    const priceData = response?.data || [];
    for (let i = 0; i < priceData.length; i++) {
      const {
        printingType, marketPrice, buylistMarketPrice, listedMedianPrice,
      } = priceData[i];
      get(pricePoints)[printingType].marketPrice = marketPrice;
      get(pricePoints)[printingType].buylistMarketPrice = buylistMarketPrice;
      get(pricePoints)[printingType].listedMedianPrice = listedMedianPrice;
    }
  };

  const processProductResponse = (response) => {
    const productData = response?.data?.result;

    set(tcgPlayerID, productData?.tcgPlayerID || productData?.id);
    set(image, productData.tcgImageURL || '');
    set(name, productData.name || '');
    set(setName, productData?.setName || '');
    set(description, productData.description || '');
    set(game, productData.productLine || '');
    set(mp, productData.marketPrice || productData.lowestPrice || productData.lowestPriceWithShipping || null);
    set(productUrl, productData.tcgProductURL || '');

    set(cardData, {
      name: get(name),
      imageURL: get(image),
      game: get(game),
      description: get(description),
      productUrl: get(productUrl),
      marketPrice: get(mp),
      setName: get(setName),
      tcgPlayerID: get(tcgPlayerID),
      pricePoints: get(pricePoints),
    });
  };

  const sendClickEvent = (view) => {
    amplitudeEvent('martech', 'buyButton', {
      component: componentName,
      component_view: view,
      title: get(article)?.title,
      type: 'card',
      name: get(cardData)?.name,
      game: get(cardData)?.game,
      utm_source: get(author)?.analyticsSource,
      itm_source: get(author)?.analyticsSource,
      author: get(author)?.name,
      date_published: get(article)?.dateTime,
      format: get(article)?.format,
      tags: get(article)?.tags,
    });
  };

  return {
    article,
    getImageURL,
    cardData,
    cardBuyUrl,
    flipImage,
    image,
    name,
    mp,
    productUrl,
    processCardResponse,
    processPricePointsResponse,
    processProductResponse,
    sendClickEvent,
  };
}
