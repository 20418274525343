<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.13 27.88" :data-testid="componentID()"><g data-name="Layer 2"><path d="M1.72.22l13.19 13.19a.74.74 0 010 1.06L1.72 27.66a.75.75 0 01-1.06 0l-.44-.44a.75.75 0 010-1.06l12.22-12.22L.22 1.72a.75.75 0 010-1.06L.66.22a.75.75 0 011.06 0z" fill="currentColor"/></g></svg>
</template>

<script>
export default {
  name: 'chevron-right',
};
</script>
