<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="instagram-icon" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="m91 11.73a10.3 10.3 0 0 1 10.27 10.27 10.3 10.3 0 0 1 -10.27 10.27 10.3 10.3 0 0 1 -10.27-10.27 10.3 10.3 0 0 1 10.27-10.27zm0 16.97c1.85 0 3.42-.66 4.73-1.97a6.45 6.45 0 0 0 1.97-4.73c0-1.85-.66-3.42-1.97-4.73a6.45 6.45 0 0 0 -4.73-1.97c-1.85 0-3.42.66-4.73 1.97a6.45 6.45 0 0 0 -1.97 4.73c0 1.85.66 3.42 1.97 4.73a6.45 6.45 0 0 0 4.73 1.97zm13.13-17.41a2.78 2.78 0 0 1 -.76 1.7c-.45.47-1 .7-1.66.7-.65 0-1.22-.23-1.7-.7a2.32 2.32 0 0 1 -.7-1.7c0-.66.23-1.22.7-1.7a2.32 2.32 0 0 1 1.7-.71c.66 0 1.22.23 1.7.7.48.49.72 1.05.72 1.7zm6.78 2.4c.06 1.67.09 4.44.09 8.31s-.04 6.65-.13 8.35c-.1 1.7-.35 3.17-.76 4.42a10.4 10.4 0 0 1 -6.34 6.34 17.2 17.2 0 0 1 -4.42.76c-1.7.09-4.48.13-8.35.13s-6.65-.04-8.35-.13c-1.7-.1-3.17-.38-4.42-.85a9.52 9.52 0 0 1 -3.88-2.37 10.4 10.4 0 0 1 -2.46-3.88 16.84 16.84 0 0 1 -.76-4.42c-.09-1.7-.13-4.48-.13-8.35s.04-6.65.13-8.35c.1-1.7.35-3.17.76-4.42a10.4 10.4 0 0 1 6.34-6.34 17.2 17.2 0 0 1 4.42-.76c1.7-.09 4.48-.13 8.35-.13s6.65.04 8.35.13c1.7.1 3.17.35 4.42.76a10.4 10.4 0 0 1 6.34 6.34c.41 1.25.68 2.74.8 4.47zm-4.28 20.1c.35-1.02.59-2.62.7-4.83.07-1.3.1-3.15.1-5.53v-2.86c0-2.44-.03-4.28-.1-5.53a18.05 18.05 0 0 0 -.7-4.83 6.44 6.44 0 0 0 -3.84-3.84c-.96-.35-2.56-.59-4.83-.7-1.3-.07-3.15-.1-5.53-.1h-2.86c-2.38 0-4.22.03-5.53.1a19 19 0 0 0 -4.83.7c-1.84.72-3.12 2-3.83 3.84-.36.96-.6 2.56-.72 4.83-.06 1.3-.09 3.15-.09 5.53v2.86c0 2.38.03 4.22.1 5.53a19 19 0 0 0 .7 4.83 6.77 6.77 0 0 0 3.84 3.84c1.02.35 2.62.59 4.83.7 1.3.07 3.15.1 5.53.1h2.86c2.44 0 4.28-.03 5.53-.1 2.27-.11 3.87-.35 4.83-.7a6.77 6.77 0 0 0 3.83-3.84z" fill="currentColor" transform="translate(-71 -2)"/></svg>
</template>

<script>
export default {
  name: 'instagram-icon',
};
</script>

<style lang="scss" scoped>
#instagram-icon {
  width: 28px;
  height: 28px;
}
</style>
