import { siteConfig } from '@tcgplayer/martech-components';

const eventLevel = {
  1: 'Competitive',
  2: 'Major',
  3: 'Professional',
};

const cardTypes = [ 'Creature', 'Planeswalker', 'Sorcery', 'Instant', 'Artifact', 'Enchantment', 'Land', ];

const cardOrder = {
  overview: [ 'Creature', 'Planeswalker', 'Sorcery', 'Instant', 'Artifact', 'Enchantment', 'Land', ],
  rarity: [ 'common', 'uncommon', 'rare', 'mythic', 'promo', ],
  color: [ 'Black', 'Blue', 'Green', 'Red', 'White', 'Multi-Colored', 'Colorless', 'None' ],
  mana: Array.from({ length: 20 }, (_, i) => i),
  quantity: Array.from({ length: 30 }, (_, i) => i + 1),
};

export default {
  getFormats() {
    return siteConfig('global.magicFormats') || {};
  },
  getCardTypes() {
    return cardTypes.map(t => t.toLowerCase());
  },
  getEventLevelName(level) {
    return eventLevel[level] || '';
  },
  getEventLevelNumber(name) {
    name = name.toLowerCase();
    name = name.substr(0, 1).toUpperCase() + name.substr(1);

    const level = Object.keys(eventLevel).find(key => eventLevel[key] === name);

    return parseInt(level) || '';
  },
  getGrouping(groupBy = 'overview') {
    const grouping = {};

    // This helps keep us in the order we want them to appear
    for (let i = 0; i < cardOrder[groupBy].length; i++) {
      grouping[cardOrder[groupBy][i]] = [];
    }

    return grouping;
  },
};
