<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="paw-icon" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" class="" :data-testid="componentID()"><path fill="currentColor" fill-rule="evenodd" d="M4.91766 18.95763C4.91766 18.95763 5.79036 18.08523 6.20586 17.76873 6.89916 17.24013 8.65026 17.23083 9.31056 17.27343 10.33416 17.33913 11.22606 17.14113 12.15066 17.70243 13.07556 18.26403 13.43886 18.99063 13.43886 18.99063 13.43886 18.99063 14.92506 18.00003 15.22236 16.84383 15.51936 15.68793 14.69376 14.03673 14.69376 14.03673 14.69376 14.03673 12.67926 11.75763 11.85366 10.50273 11.02776 9.24753 10.16916 8.62023 9.37626 8.62023 8.58366 8.62023 7.49376 9.11553 6.10656 11.16333 4.71966 13.21083 3.43146 14.66403 3.43146 14.66403 3.43146 14.66403 2.14326 16.71183 4.91766 18.95763M.02961 6.4404C.02961 6.4404-.26769 10.6017 1.02051 12.0549 2.30841 13.5084 3.86091 12.2862 3.86091 12.2862 3.86091 12.2862 4.98381 11.3946 4.32321 10.0404 3.66261 8.6862.02961 6.4404.02961 6.4404M5.34699-.00006C5.34699-.00006 3.29919 4.19454 3.53049 6.04404 3.76179 7.89354 5.84229 8.15784 5.84229 8.15784 5.84229 8.15784 8.38539 8.12484 8.38539 5.71374 8.38539 3.30264 5.34699-.00006 5.34699-.00006M13.40583.09909C13.40583.09909 10.13613 2.84049 10.20213 5.38359 10.26813 7.92669 12.01863 8.15769 12.01863 8.15769 12.01863 8.15769 13.07553 8.35599 14.09943 7.59639 15.12333 6.83649 14.59473 3.86409 14.59473 3.86409L13.40583.09909zM18.52503 6.50646C18.52503 6.50646 14.79303 8.65326 14.33073 9.80916 13.86813 10.96506 14.13243 11.88996 14.52873 12.25326 14.92503 12.61656 16.26303 12.97236 17.17083 12.35226 18.52503 11.42736 18.52503 6.50646 18.52503 6.50646"/></svg>
</template>

<script>
export default {
  name: 'companion-paw',
};
</script>

<style lang="scss" scoped>
#paw-icon {
    width: 20px;
    height: 20px;
    color: $martech-gray-160;
}
</style>
