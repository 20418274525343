<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-label="iconName"
    :role="role"
    :data-testid="componentID()">
    <title
      lang="en">{{ iconName }} icon</title>
    <g
      :class="iconName"
      :fill="iconColor">
      <slot/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'icon-base',
  props: {
    iconName: {
      type: String,
      default: '',
    },
    width: {
      type: [ Number, String ],
      default: 32,
    },
    height: {
      type: [ Number, String ],
      default: 32,
    },
    viewBox: {
      type: [ Number, String ],
      default: '0 0 32 32',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    role: {
      type: String,
      default: 'img',
    },
  },
  computed: {
    href() {
      return `#${this.type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  display: block;
}
</style>
