<template>
  <div class="format-nav" :data-testid="componentID()">
    <base-link
      v-for="(link, index) in links"
      :key="index"
      :link-url="goToFormatLanding(link)"
      :url-target="link?.newWindow ? '_blank' : ''"
      class="nav-links"
      :aria-label="link.display">
      <span aria-hidden="true" class="martech-barlow martech-text-uppercase martech-text-bold">
        {{ link.display }}
        <div v-if="link?.newWindow" class="icon martech-icon martech-external-link-icon-bold martech-black martech-small"/>
      </span>
    </base-link>
  </div>
</template>

<script setup>
import { BaseLink } from '@tcgplayer/martech-components';

const links = {
  standard: { type: 'format', display: 'Standard' },
  modern: { type: 'format', display: 'Modern' },
  pioneer: { type: 'format', display: 'Pioneer' },
  commander: { type: 'format', display: 'Commander', newWindow: true },
  limited: { type: 'format', display: 'Limited' },
  legacy: { type: 'format', display: 'Legacy' },
  strategyAndTheory: { type: 'tag', display: 'Strategy and Theory' },
};

function format(name) {
  return name.toLowerCase().replaceAll(' ', '-');
}

function goToFormatLanding(link) {
  if (link?.newWindow && link.type === 'format') {
    return `${process.env.VUE_APP_TCGPLAYER_INFINITE_URL}/magic-the-gathering/articles/format/${format(link.display)}`;
  }

  if (link.type === 'tag') {
    return {
      name: 'tag',
      params: { tag: format(link.display) },
    };
  }

  return { name: `Magic: The Gathering ${link.display}`, params: { format: `${link.display}` } };
}
</script>

<style lang="scss" scoped>
.format-nav {
  .nav-links {
    display: flex;
    padding: $martech-spacer-3 $martech-spacer-3 $martech-spacer-1;
    border-bottom: 2px solid $martech-cfb-orange-light;
    font-size: $martech-type-22;
    letter-spacing: 0.25px;
    transition: 300ms border-bottom ease-in-out;

    :deep(.martech-base-link) {
        color: $martech-black;
    }

    &:hover {
        border-bottom: 2px solid $martech-cfb-orange-base;
    }

    .icon {
      display: inline-block;
      margin-bottom: 2px;
    }
  }
}
</style>
