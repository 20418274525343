const state = {
  isVisible: false,
};

const actions = {
  alertActive(context, isVisible) {
    context.commit('alertActive', isVisible);
  },
};

const mutations = {
  alertActive(state, isVisible) {
    state.isVisible = isVisible;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
