<template>
  <div class="mobile-navigation" :data-testid="componentID()">
    <div id="main-menu" class="mobile-nav" :class="navDrawerVisibility">
      <div class="mobile-nav__header">
        <navigation-logo/>
        <span class="close-button">
          <close-toggle-button @close="toggleNavDrawer(false)"/>
        </span>
      </div>
      <div class="mobile-nav__links">
        <div class="primary">
          <div v-for="(menu, index) in menus" :key="'menu'+index" class="primary-item">
            <base-link
              v-if="menu.includeMobile"
              :link-url="menu.home">
              {{ menu.title }}
              <div v-show="menu.externalLink" class="martech-icon martech-external-link-icon martech-white"/>
            </base-link>
          </div>
        </div>
        <div class="secondary-menu">
          <ul>
            <li v-for="(item, index) in secondaryMenu" :key="index">
              <base-link :link-url="item.link" class="nav-link" @click="linkClick(item.link, item.title)">
                {{ item.title }}
              </base-link>
              <div v-show="item.externalLink" class="martech-icon martech-external-link-icon martech-white"/>
            </li>
          </ul>
        </div>
        <div class="secondary-menu">
          <ul>
            <li v-for="(item, index) in helpMenu" :key="index">
              <base-link :link-url="item.link" new-window url-target="_blank" class="nav-link" @click="linkClick(item.link, item.title)">
                {{ item.title }}
              </base-link>
              <div v-show="item.externalLink" class="martech-icon martech-external-link-icon martech-white"/>
            </li>
          </ul>
        </div>
        <mobile-newsletter-widget/>
      </div>
    </div>
    <!--screen overlay-->
    <transition name="fade">
      <screen-overlay :class="navDrawerVisibility" @close-overlay="closeDrawer"/>
    </transition>
  </div>
</template>

<script>
import CloseToggleButton from '@/components/elements/CloseToggleButton.vue';
import ScreenOverlay from '@/components/elements/screenOverlay.vue';
import NavigationLogo from '@/components/navigation-elements/NavigationLogo.vue';
import MobileNewsletterWidget from '@/components/newsletters/MobileNewsletterWidget.vue';
import menus from '@/mixins/navigationCfb';
import amplitudeEvent from '@tcgplayer/amplitude';
import { BaseLink } from '@tcgplayer/martech-components';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'mobile-navigation',
  components: {
    CloseToggleButton,
    ScreenOverlay,
    NavigationLogo,
    BaseLink,
    MobileNewsletterWidget,
  },
  mixins: [ menus ],
  props: {
    subscriptionStatus: {
      type: String,
      required: false,
      default: '',
    },
    userName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      proIcon: 'https://mktg-assets.tcgplayer.com/content/channel-fireball/site-images/pro.svg',
      secondaryMenu: [
        {
          title: 'Flesh and Blood',
          link: `${process.env.VUE_APP_TCGPLAYER_INFINITE_URL}/flesh-and-blood`,
          externalLink: true,
        },
        {
          title: 'Archive',
          link: '/archive',
          externalLink: false,
        },
        {
          title: 'What is Team CFB-Ultimate Guard?',
          link: '/article/Introducing%20Team%20CFBUltimateGuard%20for%202023/f62f98c5-4dd4-4149-acc9-3da03ac29062',
          externalLink: false,
        },
      ],
      helpMenu: [],
    };
  },
  computed: {
    ...mapState('sidebar', {
      isDrawerVisible: 'isVisible',
    }),
    navDrawerVisibility() {
      return {
        'is-active': this.isDrawerVisible,
      };
    },
    showSubSignup() {
      return this.subscriptionStatus !== 'active';
    },
  },
  watch: {
    $route(to, from, next) {
      this.closeDrawer();
    },
  },
  mounted() {
    document.addEventListener('keyup', this.escapeNavDrawer);
  },
  unmounted() {
    document.removeEventListener('keyup', this.escapeNavDrawer);
  },
  methods: {
    ...mapActions({
      toggleNavDrawer: 'sidebar/toggleNavDrawer',
    }),
    mainNavClick(i) {
      this.menus[i].open = !this.menus[i].open;

      if (this.menus[i].open) {
        this.linkClick(this.menus[i].home, this.menus[i].title);
      }
    },
    linkClick(linkURL, linkTitle, linkParent) {
      amplitudeEvent('infinite', 'infiniteNavigationClicked', {
        linkURL,
        linkTitle,
        linkParent,
        location: 'primary',
      });
    },
    escapeNavDrawer(event) {
      if (event.keyCode === 27) {
        this.closeDrawer();
      }
    },
    closeDrawer() {
      this.toggleNavDrawer(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-navigation {
  .mobile-nav {
    display: inherit !important;
    position: fixed;
    background: $martech-gray-160;
    top: 0;
    width: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 999;
    height: 100vh;
    transition: all 300ms ease-in-out;
    right: -400px;

    &.is-active {
      right: 0;
      width: 290px;

      @include breakpoint(420) {
        width: 315px;
      }
    }

    &__header {
      height: 60px;
      border-bottom: 1px solid $martech-gray-140;
      padding: 0 $martech-spacer-3;
      display: flex;
      align-items: center;
      width: 100%;

      .close-button {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        :deep(svg) {
            g {
                fill: $martech-white;
            }
        }
      }

      .cfb-logo {
        width: 175px;
      }
    }

    &__links {
      padding: $martech-spacer-2 0;

      .primary {
        border-bottom: 1px solid $martech-gray-140;

        &-item {
          :deep(.martech-base-link) {
            height: 48px;
            padding: 0 $martech-spacer-3;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            font-weight: $martech-weight-semibold;
            transition: 0.15s ease-in;
            border-left: 4px solid $martech-gray-160;
            transform: translate3d(0, 0, 0);
            justify-content: space-between;
            cursor: pointer;
            color: $martech-white;

            &.is-active {
              border-left: 4px solid $martech-cfb-orange-base;
              color: $martech-white;
            }
          }
        }

        &-menu {
          background-color: $martech-white;
          ul {
            border-bottom: 1px solid $martech-gray-140;

            li {
              padding-left: $martech-spacer-3;

              .nav-link, :deep(.martech-base-link) {
                color: $martech-text-primary;
                font-size: $martech-type-14;
                display: flex;
                align-items: center;

                .martech-icon {
                  margin-right: $martech-spacer-3;
                }

                .pro-icon {
                  width: 24px;
                  margin-right: $martech-spacer-3;
                }
              }
            }
          }
        }
      }

      .divider {
        padding: 0 $martech-spacer-3;

        hr {
          margin: $martech-spacer-2 0;
        }
      }

      ul {
        list-style: none;
        border-left: 4px solid $martech-gray-160;

        li {
          padding: 0 $martech-spacer-3;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $martech-text-primary;

          &:hover {
            background: rgba(243, 109, 33, 0.15);
          }
        }
      }
    }

    .secondary-menu {
      border-bottom: 1px solid $martech-gray-140;

      li {
        &:hover {
          background: transparent;
        }
      }

      :deep(.martech-base-link) {
        width: 95%;
        color: $martech-white;

        &:hover,
        &:focus {
          color: $martech-cfb-orange-base;
        }
      }

      &:last-of-type {
        border-bottom: 0px;
      }
    }
  }

  .screen-overlay {
    display: none;

    &.is-active {
      display: block;
      z-index: 35;
    }
  }
}
</style>
