<template>
  <div class="exact-match martech-pill-sm" :data-testid="componentID()">
    <!--eslint-disable-next-line max-len-->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.66 20.95"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M26.74.22l.7.71a.74.74,0,0,1,0,1.06L8.71,20.73a.77.77,0,0,1-1.07,0L.22,13.3a.75.75,0,0,1,0-1.06l.71-.71a.75.75,0,0,1,1.06,0l6.18,6.19L25.68.22A.75.75,0,0,1,26.74.22Z" fill="currentColor"/></g></svg>
    <p class="martech-text-sm martech-text-uppercase">
      Match
    </p>
  </div>
</template>

<script>
export default {
  name: 'exact-match-tag',
};
</script>

<style lang="scss" scoped>
.exact-match {
  background-color: $martech-pink;
  padding: 0.1rem $martech-spacer-2;
  display: flex;
  align-items: center;
  color: $martech-white;

  p {
    color: $martech-white;
    padding-left: $martech-spacer-2;
    font-weight: $martech-weight-bold;
  }

  svg {
    width: 12px;
  }
}
</style>
