<template>
  <div class="format-tag square-large" :data-testid="componentID()">
    <p class="martech-body-sm martech-text-uppercase format">
      {{ format }}
    </p>
    <span v-if="readTime && contentType">
      <p v-if="articleReadTime" class="martech-text-sm read-time martech-text-capitalize">
        <icon-base width="16" height="16" icon-name="read time">
          <clock-icon/>
        </icon-base>{{ articleReadTime + ' ' + timePhrase }}
      </p>
    </span>
    <span v-if="isDeck">
      <p class="martech-text-sm decks martech-text-capitalize">
        <icon-base width="16" height="16" icon-name="deck icon">
          <deck-icon/>
        </icon-base> Deck
      </p>
    </span>
  </div>
</template>

<script>
import IconBase from '@/components/iconography/IconBase.vue';
import ClockIcon from '@/components/iconography/ClockIcon.vue';
import DeckIcon from '@/components/iconography/DeckIcon.vue';

export default {
  name: 'format-tag',
  components: {
    IconBase,
    ClockIcon,
    DeckIcon,
  },
  props: {
    format: {
      type: String,
      required: true,
      default: '',
    },
    articleReadTime: {
      type: Number,
      required: false,
      default: 0,
    },
    readTime: {
      type: Boolean,
      required: false,
    },
    isDeck: {
      type: Boolean,
      required: false,
    },
    contentType: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    timePhrase() {
      switch (this.contentType) {
        case 'Article':
          return 'min read';
        case 'Video':
          return 'min watch';
        case 'Podcast':
          return 'min listen';
        default:
          return 'min read';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.format-tag {
  padding: $martech-spacer-1 $martech-spacer-3;
  position: absolute;
  z-index: 15;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  text-shadow: 1px 1px 2px #000000;
  height: 68px;
  width: 100%;

  .format {
    margin: 0;
    position: absolute;
    bottom: 0;
    font-weight: $martech-weight-extra-bold;
    padding-bottom: $martech-spacer-2;
    color: $martech-white;
  }

  .read-time, .decks {
    margin: 0;
    color: $martech-white;
    font-weight: $martech-weight-thin;
    padding: 0 $martech-spacer-3 $martech-spacer-2 0;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: $martech-spacer-2;
    }
  }
}
</style>
