import delve from 'dlv';
import { DateHelper as dates } from '@tcgplayer/martech-components';
import Api from '@/api/api';

export default {
  Loader(authorId, contentType, exclude, game, format) {
    return ({ rows, offset, featured }) => Api.getAuthor({
      authorId, contentType, offset, rows, featured, exclude, game, format,
    })
      .then((response) => {
        const result = delve(response, 'data.result', { author: {}, articles: [] });
        const articles = delve(result, 'articles', []) || [];

        for (let i = 0; i < articles.length; i++) {
          articles[i].authorName = result.author.name;
          articles[i].authorImageURL = result.author.imageURL;
          articles[i].authorURL = result.author.canonicalURL;

          if (articles[i].date != null) {
            articles[i].date = dates.format(articles[i].date);
          }

          if (articles[i].featured === true) {
            result.featured = articles[i];
          }
        }

        result.articles = articles;
        const { count, total } = result;
        const currentOffset = result.offset;
        result.morePages = delve(response, 'data.meta.hasNextPage', (count + currentOffset) < total);

        return result;
      });
  },
};
