<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="facebook-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g data-name="Layer 2"><path d="M32 3.43v25.14A3.44 3.44 0 0128.57 32h-9.8V21.12h4.12l.79-5.12h-4.91v-3.32a2.55 2.55 0 012.88-2.77h2.24V5.55a27.78 27.78 0 00-4-.34c-4 0-6.69 2.45-6.69 6.89V16H8.73v5.12h4.5V32h-9.8A3.44 3.44 0 010 28.57V3.43A3.44 3.44 0 013.43 0h25.14A3.44 3.44 0 0132 3.43z" fill="currentColor" data-name="Layer 1"/></g></svg>
</template>

<script>
export default {
  name: 'facebook-icon',
};
</script>

<style lang="scss" scoped>
#facebook-icon {
  width: 28px;
  height: 28px;
}
</style>
