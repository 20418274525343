<template>
  <delay-load @visible="onVisible">
    <div class="latest-events" :class="{'block' : blockGrid}" :data-testid="componentID()">
      <div class="header">
        <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          {{ title }}
        </h2>
        <router-link
          v-if="!isMobile && seeMore"
          :to="seeMore"
          :aria-label="`See All ${title}`"
          class="martech-see-all-link">
          See All
        </router-link>
      </div>
      <div class="latest-events--grid">
        <div v-if="loading" class="placeholder-component">
          <full-art-placeholder v-for="p in itemCount" :key="p" class="item"/>
        </div>
        <card-grid
          v-if="!loading && events.length"
          :articles="events"
          :column-count="itemCount"
          :grid-rows-count="gridRowsCount"
          :show-vertical-link="showVerticalLink"
          :include-promo="false"
          :row-count="gridRows"/>
        <router-link
          v-if="isMobile && seeMore"
          :to="seeMore"
          :aria-label="`See All ${title}`"
          class="martech-see-all-link see-all-mobile">
          See All
        </router-link>
      </div>
    </div>
  </delay-load>
</template>

<script setup>
import { ref } from 'vue';
import { set } from '@vueuse/core';
import Api from '@/api/api';

import DelayLoad from '@/components/DelayLoad.vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import CardGrid from '@/components/article-list/CardGrid.vue';
import { useDeviceType } from '@tcgplayer/martech-components';

const props = defineProps({
  verticalName: {
    type: String,
    required: false,
    default: '',
  },
  itemCount: {
    type: Number,
    required: false,
    default: 4,
  },
  gridRows: {
    type: Number,
    required: false,
    default: 2,
  },
  gridRowsCount: {
    type: Number,
    default: null,
    required: false,
    note: 'defines a different count for the grid template rows styling other than column count if needed',
  },
  seeMore: {
    type: [ String, Object ],
    required: false,
    default: '',
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  showVerticalLink: {
    type: Boolean,
    required: false,
    default: false,
  },
  placement: {
    type: String,
    required: false,
    default: '',
  },
});

const { isMobile } = useDeviceType();

const loading = ref(true);
const events = ref([]);

const getLatestEvents = async () => {
  const res = await Api.getEventsFeed({
    rows: props.itemCount, offset: props.offset, game: props.verticalName,
  });

  set(events, res?.data?.result || []);

  set(loading, false);
};

getLatestEvents();
</script>

 <style lang="scss" scoped>
.latest-events {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $martech-spacer-2;
    min-height: 35px;
  }

  .see-all-mobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: $martech-spacer-3;
  }

  .placeholder-component {
    display: flex;
    flex-wrap: wrap;
    grid-gap: $martech-spacer-3;

    @include breakpoint(1024) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(1200) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  :deep(.grid) {
    .grid-item {
      @include breakpoint(1200) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
