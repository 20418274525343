<template>
  <div class="placeholder-list" :data-testid="componentID()">
    <small-card-placeholder v-for="p in placeholderCount" :key="p" class="item"/>
  </div>
</template>

<script>
import SmallCardPlaceholder from '@/components/placeholder-components/SmallCardPlaceholder.vue';

export default {
  name: 'placeholder-list',
  components: {
    SmallCardPlaceholder,
  },
  props: {
    placeholderCount: {
      type: Number,
      required: false,
      default: 8,
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-list {
  width: 100%;
}
</style>
