<template>
  <div class="page-level-error" :data-testid="componentID()">
    <div class="page-level-error__body">
      <div class="page-level-error__body-image">
        <img :src="raycoon" alt="500" class="page-level-error__body-image-raycoon">
        <h1 class="page-level-error__body-image-copy">
          Uh Oh!
        </h1>
      </div>
      <p class="page-level-error__body-error-message">
        We tried
        <em>really, really</em> hard, but it didn't quite work.
      </p>
      <p class="page-level-error__body-error-message">
        Try again in a few minutes!
      </p>
      <div class="page-level-error__body-buttons">
        <base-button btn-style="martech-black" btn-size="martech-medium" @clicked="goHome">
          Take Me Home
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseButton } from '@tcgplayer/martech-components';

export default {
  name: 'page-level-error',
  components: {
    BaseButton,
  },
  data() {
    return {
      raycoon: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/500-raycoon.svg',
    };
  },
  methods: {
    goHome() {
      window.location = '/';
    },
  },
};
</script>

<style lang="scss" scoped>
.page-level-error {
  background: $martech-white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $martech-spacer-5 $martech-spacer-3; // 32px 16px
  height: 100%;

  @include breakpoint(1024) { // 1024px
    padding: $martech-spacer-7 0; // 48px
  }
  &__body {
    width: 100%;
    display: block;
    margin: auto;
    position: relative;
    &-image {
      position: relative;
      width: 300px;
      display: block;
      margin: auto;
      padding-bottom: $martech-spacer-3; // 16px

      @include breakpoint(768) {
        width: 400px;
      }

      @include breakpoint(1024) { // 1024px
        padding-bottom: $martech-spacer-4; // 24px
        width: 500px;
      }

      &-raycoon {
        width: 100%;
        display: block;
        margin: auto;
        position: relative;
      }

      &-copy {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 65px;
        display: block;
        margin: auto;
        text-align: center;
        color: $martech-white;
        font-family: $martech-display-semi-condensed; // Barlow Semi Condensed
        font-weight: $martech-weight-semibold;
        font-size: $martech-type-32; // 32px

        @include breakpoint(768) {
          bottom: 90px;
        }

        @include breakpoint(1024) {
          // 1024px
          bottom: 115px;
          font-size: $martech-type-48; // 48px
        }
      }
    }
    &-error-message {
      text-align: center;
      color: $martech-black;
      font-family: $martech-display-inter; // inter
      font-weight: $martech-weight-thin;
      font-size: $martech-text-primary; // 18px
    }
    &-buttons {
      padding-top: $martech-spacer-3; // 16px
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      @include breakpoint(1024) { // 1024px
        padding-top: $martech-spacer-4; // 24px
      }
    }
  }
}
</style>
