<template>
  <!--eslint-disable-next-line-->
  <path d="M31.06 31.75a.72.72 0 01-.53.25.75.75 0 01-.53-.25l-8.06-8.06a.66.66 0 01-.19-.5v-.56a13.52 13.52 0 01-4 2.5A12.81 12.81 0 0113 26a12.66 12.66 0 01-6.47-1.72A13 13 0 010 13.13a12.34 12.34 0 011.72-6.6 13.36 13.36 0 014.81-4.81A12.34 12.34 0 0113.13 0a13 13 0 0111.15 6.53A12.66 12.66 0 0126 13a12.81 12.81 0 01-.87 4.72 13.52 13.52 0 01-2.5 4h.56a.47.47 0 01.5.19L31.75 30a.75.75 0 01.25.53.72.72 0 01-.25.53zM22.53 7.47A11 11 0 0013 2 11 11 0 002 13a11 11 0 0011 11 11 11 0 0011-11 10.82 10.82 0 00-1.47-5.53zM20 13.25a.75.75 0 01-.75.75H14v5.25a.75.75 0 01-.75.75h-.5a.71.71 0 01-.53-.22.69.69 0 01-.22-.53V14H6.75a.71.71 0 01-.53-.22.69.69 0 01-.22-.53v-.5a.75.75 0 01.75-.75H12V6.75a.75.75 0 01.75-.75h.5a.69.69 0 01.53.22.71.71 0 01.22.53V12h5.25a.69.69 0 01.53.22.71.71 0 01.22.53z" :data-testid="componentID()"/>
</template>

<script>
export default {
  name: 'magnify-spyglass-icon',
};
</script>
