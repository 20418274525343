export default {
  // Props to be reused in router to set states in templates.
  default: {
    backToAllDecks: false,
    bannerHero: true,
    contentTypeTabs: true,
    decksTitle: false,
    eventTitle: false,
    fetchFeatured: true,
    filterOnFormats: false,
    formatButtons: true,
    hasAdvancedSearch: true,
    hasFilter: false,
    hasListView: false,
    hasMobileFilters: false,
    hasSort: false,
    isDefault: true,
    latestDecks: false,
    playerTitle: false,
    hasPagination: false,
    showMoreDecks: false,
    showMoreTournamentDecks: true,
    showSubNav: true,
    ads: {
      every: -1,
      max: -1,
      placement: 'Decks',
    },
  },
  decks: {
    backToAllDecks: true,
    bannerHero: false,
    contentTypeTabs: false,
    decksTitle: true,
    eventTitle: false,
    fetchFeatured: false,
    filterOnFormats: false,
    formatButtons: false,
    hasAdvancedSearch: false,
    hasFilter: false,
    hasListView: true,
    hasMobileFilters: false,
    hasSort: true,
    isDefault: false,
    latestDecks: true,
    latestEvents: false,
    playerTitle: false,
    hasPagination: true,
    showMoreDecks: false,
    showMoreTournamentDecks: false,
    showSubNav: true,
    ads: {
      every: 0,
      max: 0,
      placement: 'Decks',
    },
  },
  fixed: {
    backToAllDecks: true,
    bannerHero: false,
    contentTypeTabs: false,
    decksTitle: true,
    eventTitle: false,
    fetchFeatured: true,
    filterOnFormats: false,
    formatButtons: false,
    hasAdvancedSearch: false,
    hasFilter: false,
    hasListView: true,
    hasMobileFilters: false,
    hasSort: true,
    isDefault: false,
    latestDecks: true,
    latestEvents: false,
    playerTitle: false,
    hasPagination: false,
    showMoreDecks: true,
    showMoreTournamentDecks: false,
    showSubNav: true,
    ads: {
      every: 0,
      max: 0,
      placement: 'Decks',
    },
  },
  player: {
    backToAllDecks: true,
    bannerHero: false,
    contentTypeTabs: false,
    decksTitle: false,
    eventTitle: false,
    fetchFeatured: true,
    filterOnFormats: true,
    formatButtons: false,
    hasAdvancedSearch: false,
    hasFilter: true,
    hasListView: true,
    hasMobileFilters: true,
    hasSort: true,
    isDefault: false,
    latestDecks: false,
    latestEvents: false,
    playerTitle: true,
    hasPagination: true,
    showMoreDecks: false,
    showMoreTournamentDecks: false,
    showSubNav: true,
    ads: {
      every: 0,
      max: 0,
      placement: 'Decks',
    },
  },
  events: {
    backToAllDecks: true,
    bannerHero: false,
    contentTypeTabs: false,
    decksTitle: false,
    eventTitle: true,
    fetchFeatured: false,
    filterOnFormats: true,
    formatButtons: false,
    hasAdvancedSearch: false,
    hasFilter: true,
    hasListView: true,
    hasMobileFilters: true,
    hasSort: true,
    isDefault: false,
    latestDecks: false,
    latestEvents: true,
    playerTitle: false,
    hasPagination: true,
    showMoreDecks: false,
    showMoreTournamentDecks: false,
    showSubNav: true,
    ads: {
      every: 0,
      max: 0,
      placement: 'Decks',
    },
  },
};
