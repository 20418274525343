<template>
  <div v-if="authorName && authorUuid" class="author-bio" :data-testid="componentID()">
    <router-link
      v-if="authorName"
      :to="{ path: `/author/${authorName.replace(/\s+/g, '-')}/${authorUuid}` }"
      class="author-bio-link">
      <div class="wrapper">
        <div class="photo">
          <author-picture-template
            :data-testid="componentID('author-image')"
            :size="authorImageSize"
            :source="authorImage"
            :image-alt-name="authorName"/>
        </div>
        <div class="group">
          <p class="martech-text-sm martech-text-uppercase">
            Written By
          </p>
          <h4 class="martech-heading-md" :data-testid="componentID('author-name')">
            {{ authorName }}
          </h4>
          <p v-if="authorBio" class="martech-text-md bio" :data-testid="componentID('author-bio')">
            {{ truncateBio }}
            <span class="view-bio martech-text-sm martech-text-semibold">View Full Bio</span>
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { DeviceType } from '@tcgplayer/martech-components';
import AuthorPictureTemplate from '@/components/templates/AuthorPictureTemplate.vue';

export default {
  name: 'author-bio-small',
  components: {
    AuthorPictureTemplate,
  },
  mixins: [ DeviceType ],
  props: {
    authorName: {
      type: String,
      required: false,
      default: '',
    },
    authorBio: {
      type: String,
      required: false,
      default: '',
    },
    authorImage: {
      type: String,
      required: false,
      default: '',
    },
    authorUuid: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      desktopAuthorPhoto: 'large',
      mobileAuthorPhoto: 'medium',
    };
  },
  computed: {
    authorImageSize() {
      return this.deviceTypeSpecific === 'xs' ? this.mobileAuthorPhoto : this.desktopAuthorPhoto;
    },
    truncateBio() {
      const truncated = this.authorBio.slice?.(0, 185);
      return this.authorBio.length > 185 ? `${truncated}...` : this.authorBio;
    },
  },
};
</script>

<style lang="scss" scoped>
.author-bio-link,
.author-bio-link:hover {
  text-decoration: none;
  color: $martech-text-primary;
  font-size: $martech-type-14;
  line-height: $martech-type-20;
}

.author-bio {
  padding: $martech-spacer-3;

  @include breakpoint(1024) {
    padding: 0 0 $martech-spacer-3 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .photo {
    align-self: flex-start;

    @include breakpoint(1024) {
      align-self: center;
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    padding-left: $martech-spacer-3;

    h4 {
      padding: $martech-spacer-2 0;
      color: $martech-blue;

      &:hover {
        text-decoration: underline;
        color: $martech-blue-hover;
      }
    }

    .bio {
      width: 100%;

      @include breakpoint(1200) {
        width: 75%;
      }

      .view-bio {
        font-size: $martech-type-12;
        color: $martech-blue;
        font-weight: $martech-weight-semibold;

        &:hover {
          text-decoration: underline;
          color: $martech-blue-hover;
        }
      }
    }
  }
}
</style>
