<template>
  <base-link v-if="render" :link-url="returnTarget" :data-testid="componentID()">
    <div class="returnLinkContainer">
      <div class="returnLink">
        <p>
          <chevron-left/> Return to <span class="label">{{ truncateReturnLabel }}</span>
        </p>
      </div>
    </div>
  </base-link>
</template>

<script>
import delve from 'dlv';
import deviceType from '@/mixins/deviceType';
import ChevronLeft from '@/components/iconography/ChevronLeft.vue';
import url from '@/lib/url';
import { BaseLink } from '@tcgplayer/martech-components';

export default {
  name: 'return-link',
  components: {
    ChevronLeft,
    BaseLink,
  },
  mixins: [ deviceType ],
  data() {
    return {
      render: false,
      returnTarget: '',
      returnLabel: '',
    };
  },
  computed: {
    truncateReturnLabel() {
      return this.returnLabel.length > this.truncateTitle ? `${this.returnLabel.substring(0, this.truncation())}...` : this.returnLabel;
    },
    truncation() {
      return this.deviceType === 'desktop' ? 75 : 25;
    },
  },
  created() {
    this.returnTarget = delve(this, '$route.query.rtt');
    this.returnLabel = delve(this, '$route.query.rtl', null) || this.returnTarget;
    this.render = url.isTcgPlayerDomain(this.returnTarget) && this.returnTarget && this.returnLabel;
  },
};
</script>

<style lang="scss" scoped>
.returnLinkContainer {
  background-color: $martech-blue;
  bottom: 0;
  color: $martech-white;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 30;

  .returnLink {
    font-size: 1rem;
    padding: 1rem;

    p {
      display: flex;
      align-items: center;

      .label {
        font-weight: bold;
        padding-left: 0.3rem;
      }

      > a {
        color: $martech-white;
        text-decoration: none;
      }

      > svg {
        width: 11px;
        margin-right: $martech-spacer-3;
      }
    }
  }
}
</style>
