<template>
  <transition name="fade" :data-testid="componentID()">
    <div v-show="showModal" ref="modalWrapper" class="modal-wrapper">
      <div class="modal">
        <div class="header">
          <close-toggle-button @close="close"/>
        </div>
        <div ref="modalContent" class="content">
          <slot/>
          <div v-if="includeShadows" class="scroll-overlay scroll-right" aria-hidden="true"/>
          <div v-if="includeShadows" class="scroll-overlay scroll-left" aria-hidden="true"/>
        </div>
      </div>
      <screen-overlay @close-overlay="close"/>
    </div>
  </transition>
</template>

<script>
import CloseToggleButton from '@/components/elements/CloseToggleButton.vue';
import ScreenOverlay from '@/components/elements/screenOverlay.vue';

export default {
  name: 'modal', // eslint-disable-line
  components: {
    CloseToggleButton,
    ScreenOverlay,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    includeShadows: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    showModal(val) {
      if (val) {
        this.addShadows();
      }
    },
  },
  mounted() {
    const { modalContent } = this.$refs;

    document.addEventListener('keyup', this.escapeMagDrawer);
    modalContent.addEventListener('scroll', this.addShadows);
  },
  unmounted() {
    const { modalContent } = this.$refs;

    try {
      document.removeEventListener('keyup', this.escapeMagDrawer);
      modalContent.removeEventListener('scroll', this.addShadows);
    } catch (_) { /* catch */ }
  },
  created() {
    this.addShadows();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    escapeMagDrawer(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    },
    addShadows() {
      if (this.showModal && this.includeShadows) {
        const { modalContent } = this.$refs;
        const { image } = this.$parent.$refs;
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        // Adds a white fade on the right or left side based on scroll position
        if (modalContent.scrollLeft === 0 || modalContent.scrollLeft < image.offsetWidth - vw) {
          modalContent.classList.add('right');
        } else {
          modalContent.classList.remove('right');
        }

        if (modalContent.scrollLeft > 0) {
          modalContent.classList.add('left');
        }

        if (modalContent.scrollLeft === 0) {
          modalContent.classList.remove('left');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: relative;
  z-index: 2147483641;
}
.modal {
  background: $martech-white;
  padding: $martech-spacer-3;
  width: 95%;
  height: 95%;
  border-radius: $martech-radius-default;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 5%;
  }

  .content {
    height: 95%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    @include breakpoint(1200) {
      padding: $martech-spacer-3;
    }

    img {
      display: block;
      margin: 0 auto;
    }

    .scroll-overlay {
      bottom: 0;
      pointer-events: none;
      position: fixed;
      top: 75px;
      height: 87%;
      transition: all 0.2s ease-out;
      z-index: 20;
    }

    &.right {
      .scroll-right {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.9)
        );
        width: 50px;
        right: 25px;

        @include breakpoint(768) {
          width: 100px;
          right: 45px;
        }

        @include breakpoint(1200) {
          right: 55px;
        }

        @include breakpoint(1500) {
          display: none;
        }
      }
    }

    &.left {
      .scroll-left {
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.9)
        );
        width: 50px;
        left: 23px;

        @include breakpoint(768) {
          width: 100px;
          left: 35px;
        }

        @include breakpoint(1200) {
          left: 45px;
        }

        @include breakpoint(1500) {
          display: none;
        }
      }
    }
  }
}
</style>
