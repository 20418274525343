<template>
  <div class="article-line-spacer" :data-testid="componentID()">
    <hr>
  </div>
</template>

<script>
export default {
  name: 'article-line-spacer',
};
</script>

<style lang="scss" scoped>
.article-line-spacer {
  hr {
   border-bottom: 1px solid $martech-border;
  }
}
</style>
