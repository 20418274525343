<template>
  <div id="article-wrapper" :data-testid="componentID()">
    <div class="article-hero">
      <slot name="article-hero"/>
    </div>
    <div class="article-wrap">
      <div ref="articleBodyWrapper" class="article-body-wrapper">
        <slot name="article-body"/>
      </div>
      <div class="article-side-rail">
        <slot name="article-side-rail"/>
      </div>
    </div>
    <div class="article-related-content">
      <slot name="article-related-content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'article-page-grid',
  props: {
    siteAlert: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#article-wrapper {
  background-color: $martech-white;
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  max-width: 100%;
  grid-row-gap: $martech-spacer-4;

  @include breakpoint(1200) {
    grid-template-columns: repeat(32, 1fr);
  }
}

.article-hero {
  grid-column: 1 / span 12;
  grid-row: 1;

   @include breakpoint(1024) {
    grid-column: 1 / span 12;
  }

  @include breakpoint(1200) {
     grid-column: 2 / span 30;
  }

  @include breakpoint(1500) {
    grid-column: 3 / span 21;
  }

  @media only screen and (min-width: 1800px) {
    grid-column: 7 / span 21;
  }
}

.article-wrap {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  max-width: 100%;
  margin: 0 auto;
  grid-column: 1 / span 12;
  grid-row: 2;

  @include breakpoint(1200) {
    grid-column: 1 / span 32;
    grid-template-columns: repeat(32, 1fr);
  }
}

.article-body-wrapper{
  grid-column: 1 / span 12;

  @include breakpoint(1024) {
    grid-column: 1 / span 12;
  }

  @include breakpoint(1200) {
     grid-column: 2 / span 21;
     min-height: 850px;
  }

  @include breakpoint(1500) {
    grid-column: 3 / span 21;
  }

  @media only screen and (min-width: 1800px) {
    grid-column: 7 / span 15;
  }
}

.article-side-rail {
  grid-column: 1 / span 12;
  margin-top: $martech-spacer-4;

  @include breakpoint(1024) {
    grid-column: 3 / span 8;
  }

  @include breakpoint(1200) {
    grid-column: 24 / span 9;
    margin-top: 0;
    width: 320px;
  }

  @media only screen and (min-width: 1420px) {
    width: 344px;
  }

  @include breakpoint(1500) {
    grid-column: 25 / span 6;
  }

  @media only screen and (min-width: 1800px) {
    grid-column: 23 / span 6;
  }
}

.article-related-content {
  grid-column: 1 / span 12;

  @include breakpoint(1200) {
    grid-column: 1 / span 32;
  }
}
</style>
