<template>
  <div class="no-results" :data-testid="componentID()">
    <no-content-found v-if="contentType" :vertical-name="verticalName" :format="format" :content-type="contentType" @clear-filters="clearFilters"/>
    <template v-else>
      <div class="no-results__filters">
        <img :src="noResultsImage" alt="No results Found" class="no-results__image">
        <h1>Oh no! Nothing was found.</h1>
        <p v-if="!search">
          Try adjusting your filters.
        </p>
        <p v-if="search">
          Try adjusting your filters or broadening your search.
        </p>
      </div>
    </template>
  </div>
</template>

<script setup>
import NoContentFound from '@/components/article-widgets/NoContentFound.vue';

defineProps({
  contentType: {
    type: String,
    required: false,
    default: 'article',
  },
  search: {
    type: Boolean,
    default: false,
    required: false,
  },
  verticalName: {
    type: String,
    required: false,
    default: '',
  },
  format: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits([ 'clear-filters' ]);

const noResultsImage = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/no-results.svg';

const clearFilters = () => {
  emit('clear-filters');
};
</script>

<style lang="scss" scoped>
.no-results {
  padding: $martech-spacer-4 0;

  &__filters {
    text-align: center;

    h1 {
    font-family: $martech-display-semi-condensed;
    font-size: $martech-type-34;
    letter-spacing: 0.5px;
    color: $martech-gray-120;
    line-height: 2.5rem;
    padding: $martech-spacer-2;

    @include breakpoint(1024) {
      font-size: $martech-type-48;
      padding: $martech-spacer-3;
      line-height: 3rem;
    }
  }

  p {
    color: $martech-gray-170;
    font-size: $martech-type-16;
  }
  }

  &__image {
    width: 200px;
    display: block;
    margin: 0 auto;

    @include breakpoint(1024) {
      width: 250px;
    }
  }

}
</style>
