<template>
  <div v-if="loaded" class="related-content" :data-testid="componentID()">
    <div class="container">
      <div class="header-group">
        <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          {{ finalTitle }}
        </h2>
        <base-link v-if="seeAll && !isMobile" class="wrapper martech-see-all-link" :link-url="getVerticalLandingPath('/articles')" :aria-label="`See All ${title}`">
          See All
        </base-link>
      </div>
      <card-grid :row-count="1" :articles="articles"/>
      <base-link v-if="seeAll && isMobile" class="wrapper martech-see-all-link mobile-see-all" :link-url="getVerticalLandingPath('/articles')" :aria-label="`See All ${title}`">
        See All
      </base-link>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { get, set } from '@vueuse/core';

import Api from '@/api/api';
import {
  DateHelper as dates,
  BaseLink,
  useDeviceType,
  VerticalHelpers as verts
} from '@tcgplayer/martech-components';
import CardGrid from '@/components/article-list/CardGrid.vue';

const props = defineProps({
  exclude: {
    type: String,
    default: '',
    required: false,
  },
  title: {
    type: String,
    default: '',
    required: false,
  },
  vertical: {
    type: String,
    default: '',
    required: false,
  },
});

const { isMobile } = useDeviceType();
const siteConfig = getCurrentInstance()?.appContext.config.globalProperties.siteConfig;

const otherVerticals = [ 'announcements' ];
const rows = 4;
const offset = 0;

const articles = ref([]);
const loaded = ref(false);

const specialVertical = computed(() => otherVerticals.includes(props.vertical.toLowerCase()));
const popularVertical = computed(() => siteConfig('global.popularVerticals').includes(props.vertical.toLowerCase()));
const verticalName = computed(() => {
  if (get(specialVertical) || !get(popularVertical)) {
    return '';
  }

  return props.vertical.toLowerCase();
});
const seeAll = computed(() => get(popularVertical));

const finalTitle = computed(() => {
  if (!get(popularVertical)) {
    return 'Latest Articles';
  }

  const inOrOn = get(specialVertical) ? 'on' : 'in';
  return `Latest ${inOrOn} ${props.title}`;
});

const handleResponse = (response) => {
  const results = response?.data?.results || response?.data?.result || [];

  for (let i = 0; i < results.length; i++) {
    results[i].date = dates.format(results[i]?.date || new Date());
  }

  return results;
};

const articleResponse = async () => {
  const contentTypes = [ 'Article', 'Video' ];

  const results = await Api.getVerticalPage({
    verticals: get(verticalName),
    contentType: contentTypes,
    offset: get(offset),
    rows: get(rows),
    exclude: props.exclude,
  }).then(get(handleResponse));

  set(articles, results);
  set(loaded, true);
};

articleResponse();

const getVerticalLandingPath = (path) => {
  const link = verts.verticalLink(props.vertical);
  return `${link}${path}`;
};
</script>

<style lang="scss" scoped>
h2 {
  padding-bottom: $martech-spacer-2;
}

.header-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $martech-spacer-2;
}

.mobile-see-all {
  display: flex;
  justify-content: flex-end;
  margin-top: $martech-spacer-3;
}
</style>
