<template>
  <!--Do not add space or format {{displayText}}.
      If {{displayText}} is not aligned with the end of the span tag and start of <card-hover,
      a space will be added at the start or end of the card hover when next to punctuation.-->
  <!--eslint-disable-next-line max-len-->
  <span
    v-if="cardName"
    :id="`#${cardName}`"
    :key="cardName"
    v-clickoutside="closeAlertOnClickedOutside"
    tabindex="0"
    role="button"
    :name="cardName"
    class="card-hover-link"
    :data-testid="componentID()"
    @keydown.enter="mouseEnter"
    @keydown.esc="mouseLeave"
    @click="displayCardHover()"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave">
    <a v-if="route.query.prerender" :href="cardBuyUrl" target="_blank" rel="noopener noreferrer">{{ displayText }}</a>
    <template v-else>{{ displayText }}</template>
    <div v-if="displayErrorAlert && loadError" class="error-alert" @mouseleave="mouseLeave">
      <transition name="alert-fade" mode="out-in">
        <alert
          type="danger"
          rounded-corners
          centered-text
          alert-title="Product Not Found"
          alert-text="Sorry, this product is no longer available or may have been deleted."/>
      </transition>
    </div>
    <martech-card-spotlight
      v-if="!loadError && canRender && deviceType === 'desktop'"
      :data="cardData"
      :loaded="loaded"
      width="800px"
      :is-product="isProduct"
      :is-flipped="isFlipped"
      class="spotlight shadow"
      :card-product-url="cardBuyUrl"
      default-tab="details"
      :image-dimensions="imageDimensions"
      @amplitude-event="sendClickEvent"
      @magnify="magnifyCardImage"
      @flip="isFlipped = !isFlipped"/>
    <mobile-product-details-sheet
      v-if="render"
      :card-data="cardData"
      :loaded="loaded"
      :show="canRender"
      :is-flipped="isFlipped"
      :is-product="isProduct"
      default-tab="details"
      :image-dimensions="imageDimensions"
      :card-product-url="cardBuyUrl"
      analytics-medium="card-hover"
      @clicked="sendClickEvent"
      @flip="isFlipped = !isFlipped"
      @close="mobileToggleClosed"/>
  </span>
</template>

<script setup>
import {
  ref, computed, onMounted, nextTick
} from 'vue';
import { get, set } from '@vueuse/core';
import { useRoute } from 'vue-router';

import Api from '@/api/api';
import useSpotlightCore from '@/use/spotlights';
import time from '@/time';

import MobileProductDetailsSheet from '@/components/product-details/MobileProductDetailsSheet.vue';
import { Alert, MartechCardSpotlight, useDeviceType } from '@tcgplayer/martech-components';

const props = defineProps({
  cardName: {
    type: String,
    required: true,
    default: '',
  },
  displayText: {
    type: String,
    required: true,
    default: '',
  },
  cardVertical: {
    type: String,
    required: true,
    default: 'Magic',
  },
  isProduct: {
    type: Boolean,
    required: false,
    default: false,
  },
  variantId: {
    type: String,
    required: false,
    default: '',
  },
  variantSet: {
    type: String,
    required: false,
    default: '',
  },
  isCardImage: {
    type: Boolean,
    required: false,
  },
});

const { deviceType } = useDeviceType();
const route = useRoute();

const {
  article, cardData, cardBuyUrl, processCardResponse, processPricePointsResponse, processProductResponse, sendClickEvent,
} = useSpotlightCore(props.cardName);

const bodyBox = ref(0);
const lastToggle = ref(time.now());
const loaded = ref(false);
const render = ref(false);
const loadError = ref(false);
const isFlipped = ref(false);
const displayErrorAlert = ref(false);

onMounted(() => {
  const body = document.getElementsByClassName('article-body')[0];
  set(bodyBox, body.getBoundingClientRect());
});

const imageDimensions = computed(() => {
  if (props.isProduct) {
    if (props.isCardImage) {
      return { width: '314px', height: '439px' };
    }

    return { width: '300px', height: 'auto' };
  }

  if (get(cardData)?.types?.includes('Location')) {
    return { width: '310px', height: '225px' };
  }

  return { width: '314px', height: '439px' };
});

async function load() {
  if (props.cardName) {
    if (props.isProduct) {
      await Api.getProductByID(props.cardName)
        .then(response => processProductResponse(response))
        .catch((error) => { set(loadError, true); set(displayErrorAlert, true); });
    } else {
      let { variantSet } = props;

      if ((get(article)?.format || '').toLowerCase() === 'speed duel') {
        variantSet = 'speed duel';
      }

      await Api.getCard(props.cardName, props.cardVertical.toLowerCase(), props.variantId, variantSet)
        .then(response => processCardResponse(response))
        .catch((error) => {
          set(loadError, true);
          set(displayErrorAlert, true);
        });

      await Api.getPricePoints(get(cardData)?.tcgPlayerID)
        .then(response => processPricePointsResponse(response))
        .catch(() => {});
    }
  } else {
    set(cardData, {
      name: props.cardName,
      image: props.cardImage,
      pricePoints: {
        Normal: {},
        Foil: {},
      },
    });
  }

  set(loaded, true);

  if (!get(cardData)?.name && !get(cardData)?.imageURL) {
    set(render, false);
  }
}

if (get(route).query.prerender) {
  load();
}

const canRender = computed(() => {
  if (!get(render) || get(loadError)) {
    return false;
  }

  if (!get(loaded)) {
    load();
  }

  return true;
});

const displayCard = (e) => {
  const card = e.target.getElementsByClassName('spotlight')[0] || e.target.getElementsByClassName('error-alert')[0];

  // Seeing undefined on initial hover, guard against that
  if (!card?.getBoundingClientRect) return;

  const cardBox = card.getBoundingClientRect();
  const magicBody = get(bodyBox).left + get(bodyBox).width;
  const magicCard = cardBox.left + cardBox.width;

  if (magicBody < magicCard) {
    card.style.left = `${magicBody - magicCard}px`;
  }

  // This needs to go after left positioning or any card on the left hand side does not reposition itself properly if out of viewport
  if (cardBox.top >= 0 && cardBox.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
    // Checks if card hover is in the viewport completely and positions it below url like normal.
    card.style.top = '15px';
  } else if (cardBox.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
    // Positions card hover to be in viewport if it is found to be outside Viewport
    card.style.top = `${window.innerHeight - cardBox.bottom}px`;
  }
};

const mobileToggleOpen = () => {
  if (get(displayErrorAlert)) {
    document.documentElement.style.overflow = 'auto';
  }

  if ((time.now() - get(lastToggle)) < 50) {
    return;
  }

  set(render, true);

  if (get(render)) {
    document.documentElement.style.overflow = 'hidden';
    return;
  }
  set(lastToggle, time.now());
};

const mobileToggleClosed = () => {
  if ((time.now() - get(lastToggle)) < 50) {
    return;
  }

  set(render, false);

  document.documentElement.style.overflow = 'auto';

  set(lastToggle, time.now());
};

const mouseEnter = async (event) => {
  if (get(deviceType) === 'desktop') {
    if (get(loadError)) {
      set(displayErrorAlert, true);
      return;
    }

    set(render, true);

    await nextTick();
    displayCard(event);
  }
};

const mouseLeave = () => {
  if (get(deviceType) === 'desktop') {
    set(render, false);
    set(displayErrorAlert, false);
  }
};

const closeAlertOnClickedOutside = () => {
  if (!get(displayErrorAlert)) return;

  set(displayErrorAlert, false);
  document.documentElement.style.overflow = 'auto';
};

const displayCardHover = () => (get(deviceType) === 'mobile' ? mobileToggleOpen() : get(render));

</script>

<style lang="scss" scoped>
.card-hover-link {
  background-color: transparent;
  font-weight: $martech-weight-semibold;
  color: $martech-blue;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
}

.error-alert {
  background-color: $martech-white;
  border-radius: $martech-radius-medium;
  padding: $martech-spacer-4;
  font-weight: $martech-weight-normal;
  border: 1px solid $martech-border;
  color: $martech-system-danger-title;
  position: absolute;
  width: 280px;
  z-index: $martech-z-index-10;

  @include breakpoint(1024) {
    width: 768px;
  }

  a {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.card-hover-link, .card-hover-mobile{
  position: relative;

  .spotlight {
    position: absolute;
    z-index: 999;
    flex-shrink: 0;
    left: 0;
  }
}

.card-hover-mobile {
  display: block;

   @media only screen and (min-width: 1025px) {
      display: none;
    }
}

.alert-fade-enter,
.alert-fade-leave-active {
  opacity: 0;
}

.alert-fade-enter-active,
.alert-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
