<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 24" :data-testid="componentID()"><g data-name="Layer 2"><path d="M32 3v18a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3h26a3 3 0 013 3zM3 3v2.55c1.4 1.14 3.64 2.92 8.41 6.66C12.46 13 14.55 15 16 15s3.54-2 4.59-2.79c4.77-3.74 7-5.52 8.41-6.66V3zm26 18V9.4c-1.43 1.14-3.46 2.74-6.56 5.17C21 15.67 18.67 18 16 18s-5.08-2.36-6.44-3.43c-3.1-2.43-5.13-4-6.56-5.17V21z" fill="currentColor" data-name="Layer 1"/></g></svg>
</template>

<script>
export default {
  name: 'envelope-icon',
};
</script>
