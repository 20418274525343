import { getField, updateField } from 'vuex-map-fields';
import magic from '@/lib/magic';
import {
  FleshAndBloodHelpers as fab, LorcanaHelpers as lorcana, YugiohHelpers as yugioh, PokemonHelpers as pkmn
} from '@tcgplayer/martech-components';

export default ({
  namespaced: true,
  state: {
    ageOptions: {
      all: 'All time',
      '1w': 'This week',
      '1m': 'This month',
      '6m': 'The last six months',
      '1y': 'This year',
    },
    appliedAuthors: [],
    appliedCardTypes: [],
    appliedColors: [],
    appliedContentTypes: [],
    appliedEnergyTypes: [],
    appliedFormats: [],
    appliedInks: [],
    appliedRarities: [],
    appliedSeries: [],
    appliedSetNames: [],
    appliedTags: [],
    totalAppliedFilters: 0,
    cardTypeOptions: {
      magic: {
        magic: magic.getCardTypes(),
      },
      yugioh: {
        yugioh: [ 'monster', 'spell', 'trap', 'skill card' ],
      },
      pokemon: {
        pokemon: [ 'pokémon', 'trainer', 'energy' ],
      },
      lorcana: {
        lorcana: [ 'character', 'item', 'action' ],
      },
      'flesh and blood': {
        'flesh and blood': fab.getCardTypes(),
      },
      'all games': {
        magic: magic.getCardTypes(),
        yugioh: [ 'monster', 'spell', 'trap', 'skill card' ],
        pokemon: [ 'pokémon', 'trainer', 'energy' ],
        lorcana: [ 'character', 'item', 'action' ],
        'flesh and blood': fab.getCardTypes(),
      },
    },
    contentMode: 'article',
    colorOptions: {
      b: 'Black',
      u: 'Blue',
      g: 'Green',
      r: 'Red',
      w: 'White',
      colorless: 'Colorless',
      none: 'None (has no colors)',
    },
    contentTypeOptions: {
      article: [ 'article', 'podcast', 'video', ],
      deck: [ 'deck', 'event' ],
      card: [ 'card' ],
    },
    tournamentDecksOnly: false,
    energyTypeOptions: {
      grass: 'Grass',
      fire: 'Fire',
      water: 'Water',
      lightning: 'Lightning',
      fighting: 'Fighting',
      psychic: 'Psychic',
      colorless: 'Colorless',
      darkness: 'Darkness',
      metal: 'Metal',
      dragon: 'Dragon',
      fairy: 'Fairy',
    },
    eventsOpen: false,
    formatOptions: {
      magic: {
        magic: Object.values(magic.getFormats()),
      },
      yugioh: {
        yugioh: Object.values(yugioh.getFormats()),
      },
      pokemon: {
        pokemon: Object.values(pkmn.getFormats()),
      },
      lorcana: {
        lorcana: Object.values(lorcana.getFormats()),
      },
      'flesh and blood': {
        'flesh and blood': Object.values(fab.getFormats()),
      },
      'all games': {
        magic: Object.values(magic.getFormats()),
        yugioh: Object.values(yugioh.getFormats()),
        pokemon: Object.values(pkmn.getFormats()),
        lorcana: Object.values(lorcana.getFormats()),
        'flesh and blood': Object.values(fab.getFormats()),
      },
    },
    formatsOpen: false,
    inkOptions: {
      amber: 'Amber',
      amethyst: 'Amethyst',
      emerald: 'Emerald',
      ruby: 'Ruby',
      sapphire: 'Sapphire',
      steel: 'Steel',
    },
    isList: false,
    isVisible: false,
    order: null,
    searchTerm: null,
    selectedFilter: 'all games',
    selectedAge: 'all',
    selectedSort: 'Relevance',
    sort: null,
    sortOptions: {
      relevance: 'Relevance',
      alphabetical: 'A-Z',
      latest: 'Latest',
      marketHigh: 'Market Price: High to Low',
      marketLow: 'Market Price: Low to High',
    },
  },
  actions: {
    reset({ state }) {
      state.appliedAuthors = [];
      state.appliedCardTypes = [];
      state.appliedColors = [];
      state.appliedContentTypes = [];
      state.appliedEnergyTypes = [];
      state.appliedInks = [];
      state.appliedFormats = [];
      state.appliedRarities = [];
      state.appliedSeries = [];
      state.appliedSetNames = [];
      state.appliedTags = [];
      state.selectedFilter = 'all games';
      state.totalAppliedFilters = null;
      state.channelFireballOnly = false;
      state.tournamentDecksOnly = false;
      state.formatOptions.magic.magic = Object.values(magic.getFormats());
      state.formatOptions['all games'] = {
        magic: Object.values(magic.getFormats()),
        yugioh: Object.values(yugioh.getFormats()),
        pokemon: Object.values(pkmn.getFormats()),
        lorcana: Object.values(lorcana.getFormats()),
        'flesh and blood': Object.values(fab.getFormats()),
      };
    },
    setAuthor(context, author) {
      context.commit('setAuthor', author || []);
    },
    setCardType(context, type) {
      context.commit('setCardType', type || []);
    },
    setChannelFireballOnly(context, tdo) {
      context.commit('setChannelFireballOnly', tdo);
    },
    setContentMode(context, contentMode) {
      context.commit('setContentMode', contentMode || 'article');
    },
    setColor(context, color) {
      context.commit('setColor', color || []);
    },
    setContentType(context, contentType) {
      context.commit('setContentType', contentType);
    },
    setEnergyType(context, energy) {
      context.commit('setEnergyType', energy || []);
    },
    setSelectedFilter(context, game) {
      context.commit('setSelectedFilter', game || 'all games');
    },
    setSelectedAge(context, age) {
      context.commit('setSelectedAge', age || 'all');
    },
    setFormat(context, format) {
      context.commit('setFormat', format || []);
    },
    setInk(context, ink) {
      context.commit('setInk', ink || []);
    },
    setRarity(context, rarity) {
      context.commit('setRarity', rarity || []);
    },
    setSeries(context, series) {
      context.commit('setSeries', series || []);
    },
    setSetName(context, setName) {
      context.commit('setSetName', setName || []);
    },
    setTag(context, tag) {
      context.commit('setTag', tag || []);
    },
    setOrder(context, order) {
      context.commit('setOrder', order);
    },
    setSearchTerm(context, searchTerm) {
      context.commit('setSearchTerm', searchTerm);
    },
    setSort(context, sort) {
      context.commit('setSort', sort);
    },
    toggleSearchDrawer(context, isVisible) {
      context.commit('toggleSearchDrawer', isVisible);
    },
    setTournamentDecksOnly(context, tdo) {
      context.commit('setTournamentDecksOnly', tdo);
    },
    setTotalAppliedFilters(context, count) {
      context.commit('setTotalAppliedFilters', count || null);
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    toggleSearchDrawer(state, isVisible) {
      state.isVisible = isVisible;
    },
    setAuthor(state, author) {
      if (Array.isArray(author)) {
        state.appliedAuthors = author;
      } else {
        state.appliedAuthors = author.split(',');
      }
    },
    setCardType(state, type) {
      if (Array.isArray(type)) {
        state.appliedCardTypes = type;
      } else {
        state.appliedCardTypes = type.split(',');
      }
    },
    setChannelFireballOnly(state, cfo) {
      state.channelFireballOnly = cfo;
    },
    setColor(state, color) {
      if (Array.isArray(color)) {
        state.appliedColors = color;
      } else {
        state.appliedColors = color.split(',');
      }
    },
    setContentMode(state, contentMode) {
      state.contentMode = contentMode || 'article';
    },
    setContentType(state, contentType) {
      contentType = contentType || [];
      if (!Array.isArray(contentType)) {
        contentType = contentType.split(',');
      }
      state.appliedContentTypes = contentType;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setSearchTerm(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
    setSelectedFilter(state, game) {
      state.selectedFilter = game || 'all games';
    },
    setSelectedAge(state, age) {
      state.selectedAge = age || 'all';
    },
    setEnergyType(state, energy) {
      if (Array.isArray(energy)) {
        state.appliedEnergyTypes = energy;
      } else {
        state.appliedEnergyTypes = energy.split(',');
      }
    },
    setFormat(state, format) {
      if (Array.isArray(format)) {
        state.appliedFormats = format;
      } else {
        state.appliedFormats = format.split(',');
      }
    },
    setInk(state, ink) {
      if (Array.isArray(ink)) {
        state.appliedInks = ink;
      } else {
        state.appliedInks = ink.split(',');
      }
    },
    setRarity(state, rarity) {
      if (Array.isArray(rarity)) {
        state.appliedRarities = rarity;
      } else {
        state.appliedRarities = rarity.split(',');
      }
    },
    setSeries(state, series) {
      if (Array.isArray(series)) {
        state.appliedSeries = series;
      } else {
        state.appliedSeries = series.split(',');
      }
    },
    setSetName(state, setName) {
      if (Array.isArray(setName)) {
        state.appliedSetNames = setName;
      } else {
        state.appliedSetNames = setName.split(',');
      }
    },
    setTag(state, tag) {
      if (Array.isArray(tag)) {
        state.appliedTags = tag;
      } else {
        state.appliedTags = tag.split(',');
      }
    },
    setTournamentDecksOnly(state, tdo) {
      state.tournamentDecksOnly = tdo;
    },
    setTotalAppliedFilters(state, count) {
      state.totalAppliedFilters = count;
    },
    setSort(state, sort) {
      state.sort = sort;
    },
  },
});
