import { getField } from 'vuex-map-fields';
import { YugiohHelpers as yugioh, PokemonHelpers as pkmn } from '@tcgplayer/martech-components';

const NAMESPACE = 'content-filter-';
const SELECTED_FILTER = `${NAMESPACE}selected-filter`;
const APPLIED_FORMATS = `${NAMESPACE}applied-formats`;
const IS_LIST = `${NAMESPACE}is-list`;

const updateFns = {
  selectedFilter: (state, value) => {
    if (state.selectedFilter !== value) {
      state.appliedFormats = [];
      sessionStorage.setItem(APPLIED_FORMATS, JSON.stringify([]));
    }
    state.selectedFilter = value;
    localStorage.setItem(SELECTED_FILTER, value);
  },
  isList: (state, value) => {
    state.isList = value;
    localStorage.setItem(IS_LIST, value);
  },
  appliedFormats: (state, value) => {
    state.appliedFormats = value;
    sessionStorage.setItem(APPLIED_FORMATS, JSON.stringify(value));
  },
};

export default ({
  namespaced: true,
  state: {
    appliedFormats: JSON.parse(sessionStorage.getItem(APPLIED_FORMATS)) || [],
    formatOptions: {
      magic: [ 'Brawl', 'Commander', 'Historic', 'Legacy', 'Modern', 'Pauper', 'Pioneer', 'Standard', 'Vintage' ],
      pokemon: Object.values(pkmn.getFormats()),
      yugioh: Object.values(yugioh.getFormats()),
    },
    selectedFilter: localStorage.getItem(SELECTED_FILTER) || 'all games',
    isList: JSON.parse(localStorage.getItem(IS_LIST)) || false,

  },
  getters: {
    getField,
  },
  mutations: {
    updateField(state, { path, value }) {
      if (updateFns[path]) {
        updateFns[path](state, value);
        return;
      }

      state[path] = value;
    },
  },
});
