import { getField, updateField } from 'vuex-map-fields';
import Api from '@/api/api';
import delve from 'dlv';

const NAMESPACE = 'SUBSCRIPTIONS';
const SUBSCRIPTIONS_SET_WHOAMI = `${NAMESPACE}_SET_WHOAMI`;
const SUBSCRIPTIONS_SET_SUBSCRIPTION = `${NAMESPACE}_SET_SUBSCRIPTION`;
const SUBSCRIPTION_SET_PAYMENT_METHODS = `${NAMESPACE}_SET_PAYMENT_METHODS`;
const SUBSCRIPTION_SET_TRANSACTIONS = `${NAMESPACE}_SET_TRANSACTIONS`;
const SUBSCRIPTION_SET_VALID_LOCALE = `${NAMESPACE}_SET_VALID_LOCALE`;
const SUBSCRIPTION_SET_ERRORCODE = `${NAMESPACE}_SET_ERRORCODE`;
const SUBSCRIPTION_SET_ERROR = `${NAMESPACE}_SET_ERROR`;
const SUBSCRIPTION_SET_FETCHED = `${NAMESPACE}_SET_FETCHED`;
const SUBSCRIPTION_SET_LOADING = `${NAMESPACE}_SET_LOADING`;

export default ({
  namespaced: true,
  state: {
    loading: false,
    fetched: false,
    isVisible: false,
    error: null,
    errorCode: null,
    validLocale: null,
    disclaimer: {
      bonusBucksAvailable: '$35',
      bonusBucksCap: '$50',
    },
    whoami: {
      userName: null,
      firstName: null,
      externalUserId: null,
    },
    promo: {
      promoCode: null,
      promoCodeValidations: {},
    },
    payment: {
      cardType: null,
      lastFour: null,
      expirationMonth: null,
      expirationYear: null,
      isExpired: false,
      token: null,
      paymentType: null,
      isDefault: null,
      email: null,
    },
    paymentMethods: [],
    transactions: [],
    subscription: {
      active: null,
      activePerks: false,
      billingRenewal: null,
      braintreeID: false,
      daysTilRenewal: null,
      daysToNextTier: null,
      isFree: false,
      kickback: null,
      price: '$8.99',
      status: null,
      paymentToken: null,
      braintreeStatus: '',
    },
  },
  actions: {
    async getSignUpData({ commit }) {
      commit(SUBSCRIPTION_SET_LOADING, true);
      return Api.getSignupData()
        .then((response) => {
          commit(SUBSCRIPTION_SET_ERROR, null);
          commit(SUBSCRIPTION_SET_ERRORCODE, null);
          commit(SUBSCRIPTION_SET_FETCHED, true);
          commit(SUBSCRIPTION_SET_LOADING, false);
          const res = response.data.result;

          commit(SUBSCRIPTIONS_SET_WHOAMI, res);
          commit(SUBSCRIPTIONS_SET_SUBSCRIPTION, res);
          commit(SUBSCRIPTION_SET_PAYMENT_METHODS, res);
          commit(SUBSCRIPTION_SET_VALID_LOCALE, res);
        })
        .catch((error) => {
          commit(SUBSCRIPTION_SET_FETCHED, false);
          commit(SUBSCRIPTION_SET_LOADING, false);
          const errors = delve(error, 'response.data.errors', []);
          if (errors.length > 0) {
          /* eslint-disable-next-line */
            commit(SUBSCRIPTION_SET_ERROR, errors[0]);
            if (errors.length > 1) {
            /* eslint-disable-next-line */
              commit(SUBSCRIPTION_SET_ERRORCODE, errors[1]);
            }
          } else {
            commit(SUBSCRIPTION_SET_ERROR, 'error getting signup data');
          }
        });
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    [SUBSCRIPTIONS_SET_WHOAMI]: (state, payload) => {
      state.whoami = { userName: payload.userName, firstName: payload.firstName, externalUserId: payload.externalUserId };
    },
    [SUBSCRIPTIONS_SET_SUBSCRIPTION]: (state, payload) => {
      state.subscription = { active: payload.active, status: payload.subscriptionStatus, price: '$8.99' };
    },
    [SUBSCRIPTION_SET_PAYMENT_METHODS]: (state, payload) => {
      let { paymentMethods } = payload;

      if (!Array.isArray(paymentMethods)) paymentMethods = [];

      paymentMethods.sort((a, b) => ((b.isDefault ? 1 : 0) - (a.isDefault ? 1 : 0)));
      paymentMethods.filter(p => !p.isExpired);

      state.paymentMethods = paymentMethods;
    },
    [SUBSCRIPTION_SET_TRANSACTIONS]: (state, payload) => {
      let { transactions } = payload;

      if (!Array.isArray(transactions)) transactions = [];

      state.transactions = transactions;
    },
    [SUBSCRIPTION_SET_VALID_LOCALE]: (state, payload) => {
      state.validLocale = delve(payload, 'geoIPData.validLocale', false);
    },
    [SUBSCRIPTION_SET_ERRORCODE]: (state, errorCode) => {
      state.errorCode = errorCode;
    },
    [SUBSCRIPTION_SET_ERROR]: (state, error) => {
      state.error = error;
    },
    [SUBSCRIPTION_SET_FETCHED]: (state, fetched) => {
      state.fetched = fetched;
    },
    [SUBSCRIPTION_SET_LOADING]: (state, loading) => {
      state.loading = loading;
    },
    toggleSubSidebarExpanded(state) {
      state.isVisible = true;
    },
    toggleSubSidebarCollapsed(state) {
      state.isVisible = false;
    },
  },
});
