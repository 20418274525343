<template>
  <delay-load @visible="onVisible">
    <div v-if="render" class="author-more-content" :data-testid="componentID()">
      <h4 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
        More By {{ author.name }}
      </h4>
      <article-list :fetch="fetch" :on-error="onError" view="box" :paginated="false" :page-size="4" :featured-rows="0" :loader="false" :no-results="false"/>
    </div>
  </delay-load>
</template>

<script>
import { mapState } from 'vuex';
import delve from 'dlv';
import Authors from '@/api/authors';
import ArticleList from '@/components/article-list/ArticleList.vue';
import DelayLoad from '@/components/DelayLoad.vue';

export default {
  name: 'author-more-content',
  components: {
    ArticleList,
    DelayLoad,
  },
  props: {
    placement: {
      type: String,
      required: false,
      default: 'Article Page',
    },
  },
  data() {
    return {
      render: false,
    };
  },
  computed: {
    ...mapState('article', [ 'article', 'author' ]),
    fetch() {
      return Authors.Loader(this.author.uuid, '', this.article.uuid);
    },
  },
  methods: {
    async onVisible() {
      if (this.render === false) {
        this.$emit('no-data');
      }

      this.render = true;
    },
    onError(error) {
      const res = delve(error, 'response', {});

      if (typeof res !== 'undefined') {
        this.render = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.author-more-content {
  width: 100%;
  padding: 0 $martech-spacer-3 $martech-spacer-4 $martech-spacer-3;

  @include breakpoint(1200) {
    padding: 0;
  }

  h4 {
    padding-bottom: $martech-spacer-2;
  }

  :deep(.martech-two-lines) {
    -webkit-line-clamp: 3;
  }
}
</style>
