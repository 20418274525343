import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { get } from '@vueuse/core';

export default function useVerticals() {
  const route = useRoute();

  const verticalType = computed(() => {
    switch (true) {
      case get(route)?.path.includes('/topics'):
        return 'TCGplayer topics';
      case get(route)?.path.includes('/series'):
        return 'TCGplayer series';
      case get(route)?.path.includes('/yugioh'):
        return 'yugioh';
      case get(route)?.path.includes('/magic-the-gathering'):
        return 'magic';
      case get(route)?.path.includes('/pokemon'):
        return 'pokemon';
      case get(route)?.path.includes('/disney-lorcana'):
        return 'lorcana';
      case get(route)?.path.includes('/flesh-and-blood'):
        return 'flesh and blood';
      case get(route)?.path.includes('/more-games'):
        return 'more games';
      default:
        return '';
    }
  });

  return {
    verticalType,
  };
}
