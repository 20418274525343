import { ref } from 'vue';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';
// @ts-ignore
import { Auth } from '@tcgplayer/martech-components';

const externalUserId: Ref<string|null> = ref(null);
const productLineAffinity = useStorage('productLineAffinity', null);
const userKey: Ref<string|null> = ref(null);

let fetched = false;
// eslint-disable-next-line
export function useUser(defaultUserKey: string = '00000000-0000-0000-0000-000000000000') {
  if (!fetched) {
    fetched = true;
    Auth.getUserData()
      .then((data: any) => {
        externalUserId.value = data?.externalUserId || '';
        productLineAffinity.value = data?.productLineAffinity || '';
        userKey.value = data?.userKey || defaultUserKey;
      });
  }

  return { externalUserId, productLineAffinity, userKey };
}
