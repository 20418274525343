<template>
  <div
    ref="searchBar"
    v-clickoutside="clearAutocomplete"
    class="search-bar"
    role="searchbox"
    :aria-expanded="showInputDropdown"
    aria-controls="owned_listbox"
    tabindex="-1"
    :data-testid="componentID()">
    <div class="search-group">
      <div class="bar">
        <input
          ref="searchInput"
          tabindex="0"
          :value="updatedSearchTerm"
          class="bar__input"
          type="text"
          aria-label="Search"
          :placeholder="siteConfig('search.placeholder')"
          @input="searchInput"
          @focus="focus"
          @keydown.enter.self="submitSearchClick"
          @keydown.shift.tab="hideAutocomplete"
          @keydown.escape="hideAutocomplete">
        <div class="buttons-wrapper">
          <transition name="fade">
            <button
              v-show="updatedSearchTerm"
              class="bar__clear-button"
              tabindex="0"
              @click="clearSearch"
              @keydown.enter.stop="clearSearch"
              @keydown.space.stop="clearSearch">
              <icon-base icon-name="clear" icon-color="#676767" width="25">
                <close-icon/>
              </icon-base>
            </button>
          </transition>
          <span
            class="search-btn"
            :class="{
              'focused' : inputActive || searchTerm,
              'orange-brand': siteConfig('global.orangeBrand'),
            }"
            tabindex="0"
            @keyup.enter="submitSearchClick"
            @keydown.escape="hideAutocomplete"
            @focus="() => { focus(false); }">
            <div
              class="bar__search-button"
              :class="{
                'focused' : inputActive || searchTerm,
                'orange-brand': siteConfig('global.orangeBrand'),
              }"/>
            <search-icon
              role="button"
              aria-label="Search Button"
              @click="submitSearchClick"/>
          </span>
        </div>
      </div>
      <transition name="slide">
        <auto-complete
          v-show="showInputDropdown"
          :search-term="updatedSearchTerm || ''"
          @close-autocomplete="clearAutocomplete()"
          @keyselect="changeTerm"
          @clicked="clicked"
          @keydown.escape="hideAutocomplete"
          @keydown.tab="hideAutocomplete"/>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import delve from 'dlv';
import IconBase from '@/components/iconography/IconBase.vue';
import CloseIcon from '@/components/iconography/CloseIcon.vue';
import SearchIcon from '@/components/iconography/SearchIcon.vue';
import AutoComplete from '@/components/AutoComplete.vue';

export default {
  name: 'nav-search-bar',
  components: {
    IconBase,
    CloseIcon,
    SearchIcon,
    AutoComplete,
  },
  data() {
    return {
      inputActive: false,
      showInputDropdown: false,
      contentTypeDefault: 'Articles',
      updatedSearchTerm: '',
      height: window.innerHeight,
      body: document.getElementsByTagName('body')[0],
      tabKeyPressed: false,
    };
  },
  computed: {
    ...mapFields('search', [ 'searchTerm', ]),
  },
  watch: {
    visible() {
      this.$nextTick(() => {
        if (this.visible) {
          this.focus();
        }
      });
    },
  },
  methods: {
    hideAutocomplete(ev) {
      let allowBlur = true;
      // We need the slight delay so activeElement is the next element
      window.setTimeout(() => {
        if (delve(ev, 'key') !== 'Escape'
          && (delve(document, 'activeElement.classList', []).contains('search-btn')
          || delve(document, 'activeElement.innerText', '').includes('Deck Search')
          || delve(document, 'activeElement.parentElement.parentElement.classList', []).contains('autocomplete'))) {
          allowBlur = false;
        }
        if (allowBlur && this.blur()) {
          this.showInputDropdown = false;
        }
      }, 0);
    },
    searchInput(e) {
      this.updatedSearchTerm = e.target.value;
    },
    blur() {
      this.$refs.searchInput.blur();
      this.inputActive = false;

      // Without this we close before we navigate and the navigate never happens
      window.setTimeout(() => {
        this.removeScrollableAutocomplete();
        this.showInputDropdown = false;
      }, 200);
    },
    clearSearch() {
      this.updatedSearchTerm = null;
      this.searchTerm = null;
      this.focus();
    },
    focus(focusInput = true) {
      if (focusInput) {
        this.$refs.searchInput.focus();
      }

      this.inputActive = true;
      this.makeAutocompleteScrollable();
      this.showInputDropdown = true;

      // Move the cursor to the end of the text
      if (focusInput) {
        const input = delve(this, '$refs.searchInput', {});
        if (input.setSelectionRange) {
          // Delay required to get proper length
          window.setTimeout(() => {
            const len = delve(input, 'value.length', 0);
            input.setSelectionRange(len, len);
          }, 0);
        }
      }
    },
    clearAutocomplete() {
      this.showInputDropdown = false;
      this.removeScrollableAutocomplete();
    },
    changeTerm(newTerm) {
      this.searchTerm = newTerm;
      this.focus();
    },
    clicked() {
      // Close and blur when an autocomplete item is clicked
      this.showInputDropdown = false;
      this.blur();
    },
    submitSearch() {
      // The navigation for this usage is via autocomplete
      // Close and blur when we submit a search
      this.searchTerm = this.updatedSearchTerm;
      this.showInputDropdown = false;
      this.blur();
    },
    submitSearchClick() {
      // Close and blur when we submit a search
      this.searchTerm = this.updatedSearchTerm;
      this.showInputDropdown = false;
      this.blur();
      this.$router.push({
        path: '/search',
        query: {
          q: this.searchTerm || '', // '' keeps empty searches from breaking the game/format controls
          p: 1,
          contentMode: this.contentModeDefault,
        },
      });
    },
    makeAutocompleteScrollable() {
      this.body.classList.add('no-scroll');
      const { navHeader } = this.$parent.$refs;
      navHeader.style.height = '100%';
    },
    removeScrollableAutocomplete() {
      const { navHeader } = this.$parent.$refs;
      navHeader.style.overflowY = 'inherit';
      navHeader.style.height = 'auto';
      this.body.classList.remove('no-scroll');
    },
    deckAdvancedSearchRoute() {
      this.$router.push({ path: '/decks/advanced-search' });
      this.showInputDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  position: relative;
  z-index: 30;
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1025px) {
    padding: 0;
  }

  .search-group {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .bar {
    display: flex;
    align-items: center;
    background-color: $martech-gray-0;
    border-radius: 50px;
    height: 36px;
    width: 100%;
    border: 1px solid $martech-border;
    position: relative;

    &__input {
      width: 100%;
      border: none;
      font-size: 100%;
      line-height: $martech-type-16;
      background: transparent;
      font-family: $martech-display-inter;
      padding: $martech-spacer-3;
      color: $martech-black;

      @include breakpoint(1024) {
        font-size: $martech-type-14;
      }

      &::placeholder {
        color: $martech-gray-100;
        letter-spacing: 0.4px;
      }
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
    }

    &__search-button,
    &__clear-button {
      background-color: transparent;
      width: 48px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-btn {
      position: relative;

      :deep(.search-icon) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        margin: auto;
        cursor: pointer;
      }

      &.focused {
        color: $martech-white;

        &.orange-brand {
          color: $martech-black;
        }
      }
    }

    &__search-button {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      position: relative;
      transition: transform 300ms cubic-bezier(0.345, 0.115, 0.135, 1.42),
      opacity 300ms ease-in-out;
      transform: scale(0.7) perspective(1px);
      opacity: 0;

      svg {
        height: 18px;
        width: 18px;
        z-index: 3;
      }

      &.focused {
        color: $martech-white;
        background: $martech-black;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        opacity: 1;
        transform: scale(1) perspective(1px);
        margin-right: -2px;

        &.orange-brand {
          background: $martech-cfb-orange-base;
        }
      }
    }

    &__clear-button {
      svg {
        width: 12.5px;
        height: 12.5px;
      }
    }
  }
}

.slide-enter-active {
  transition: all 300ms cubic-bezier(0.17, 0.17, 0, 1);
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}
</style>
