<template>
  <span class="mint-loader" :data-testid="componentID()"/>
</template>

<script>
export default {
  name: 'mint-loader',
};
</script>

<style lang="scss" scoped>
.mint-loader {
  color: $martech-mint;
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid $martech-mint;
  border-color: $martech-mint $martech-mint $martech-mint-x-light $martech-mint-x-light;
  position: relative;
  transform: translateZ();
  animation: rotate 1.2s infinite ease;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
