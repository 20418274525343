import config from '@/config/sites/cfb';
import clickOutside from '@/directives/ClickOutside';
import componentID from '@/plugins/componentId';
import { setUserProperties as amplitudeUserProperties, VueAmplitude } from '@tcgplayer/amplitude';
import '@tcgplayer/martech-styles/martech-cfb.scss';
import { createHead } from '@vueuse/head';
import { Auth } from '@tcgplayer/martech-components';
import delve from 'dlv';
import { FocusTrap } from 'focus-trap-vue';
import { createApp, h } from 'vue';
import App from './AppCFB.vue';
import mock from './mock'; // eslint-disable-line
import router from './router_cfb';
import store from './store';

const vueAppVersion = process.env.VUE_APP_VERSION;
console.info('Version:', vueAppVersion); // eslint-disable-line

const app = createApp(App, {
  created() {
    const INFINITE_VERSION = 'infinite-app-version';
    const stored = localStorage.getItem(INFINITE_VERSION) || null;

    if (stored !== vueAppVersion) {
      amplitudeUserProperties({
        infiniteAppVersion: vueAppVersion,
      });
      localStorage.setItem(INFINITE_VERSION, vueAppVersion);
    }
  },
  render: () => h(App),
  router,
  store,
});

const head = createHead();

app.config.globalProperties.$site = 'cfb';
app.use(router);
app.use(store);
app.config.globalProperties.$store = store;
app.use(componentID);
app.use(VueAmplitude);
app.use(head);
app.config.globalProperties.siteConfig = path => (delve(config, path));
window.tcgSiteConfig = path => (delve(config, path));
app.component('focus-trap', FocusTrap);

// IMPORTANT: Any store that uses @/lib/config directly or indirectly MUST have a reset action that we
//            call here. This is because they are initialized on import before siteConfig is ready.
store.dispatch('search/reset');

// Click outside directive to be used inplace of overlays if an overlay is not needed.
app.directive('clickoutside', clickOutside);

// Setup our login status
Auth.silentLogin();

app.mount('#app');

window.TcgRouter = router;
window.TcgApp = app;

// Check for new versions of the code every 15 minutes and if found reload the page
const timer = 1000 * 60 * 15; // 15 minutes
window.setInterval(() => {
  fetch('/version.txt')
    .then((res) => {
      res.text()
        .then((version) => {
          if (version && version.trim().match(/^[0-9]+.[0-9]+.[0-9]+$/) && process.env.VUE_APP_VERSION.trim() !== version.trim()) {
            window.location.reload();
          }
        });
    })
    .catch(() => {});
}, timer);
