<template>
  <on-enter-navigate-card :data="data">
    <router-link v-if="render" :to="{path: canonical || '#'}" tabindex="-1" :data-testid="componentID()" @click="onClick(data)">
      <div class="condensed" tabindex="0">
        <div v-if="showVerticalLinks" class="condensed-labels">
          <p class="martech-labels martech-blue" :data-testid="componentID('vertical')">
            {{ verticalDisplay }}
          </p>
        </div>
        <div class="condensed-body">
          <div class="name">
            <p class="martech-body martech-barlow martech-text-capitalize martech-one-line martech-text-bold" :data-testid="componentID('title')">
              {{ title }}
            </p>
          </div>
          <div class="group">
            <div class="date">
              <p class="martech-text-md" :data-testid="componentID('event-date')">
                {{ formattedDate }} <span v-if="eventPlayers">{{ eventPlayers }} Players</span>
              </p>
            </div>
            <div v-if="format" class="format">
              <p class="martech-text-md" :data-testid="componentID('format')">
                <span class="martech-sm-divider martech-text-md">|</span>{{ format }}
              </p>
            </div>
            <div v-if="eventLevel" class="level">
              <span class="martech-sm-divider martech-text-md">|</span><event-star v-for="(l, i) in eventLevelStars" :key="i" :data-testid="componentID('event-level-stars')"/>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </on-enter-navigate-card>
</template>

<script>
import delve from 'dlv';
import EventStar from '@/components/iconography/deck-icons/EventStar.vue';
import eventMixin from '@/components/card-elements/Cards/mixins/event';
import OnEnterNavigateCard from '@/components/card-elements/OnEnterNavigateCard.vue';
import { VerticalHelpers as verts } from '@tcgplayer/martech-components';

export default {
  name: 'condensed-event',
  components: {
    EventStar,
    OnEnterNavigateCard,
  },
  mixins: [ eventMixin ],
  props: {
    data: {
      type: Object,
      required: true,
      default: null,
    },
    onClick: {
      type: Function,
      required: false,
      default: (data) => {},
    },
    showVerticalLinks: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      format: '',
      date: '',
      render: false,
    };
  },
  computed: {
    verticalDisplay() {
      return verts.displayName(this.vertical.toLowerCase());
    },
    // this.article is used in the event mixin while in this case it's coming into the component as this.data
    article() {
      return this.data;
    },
  },
  async created() {
    if (this.data) {
      this.title = delve(this.data, 'title', '');
      this.eventLevel = delve(this.data, 'eventLevel', '');
      this.eventPlayers = this.data?.eventPlayers;
      this.contentType = delve(this.data, 'contentType', 'event');
      this.vertical = delve(this.data, 'vertical', null);
    }
  },
};
</script>

<style lang="scss" scoped>
.name {
  display: flex;
  align-items: center;
  text-transform: capitalize;

  svg {
    margin-left: $martech-spacer-2;
  }
}

.condensed {
  padding: $martech-spacer-2 $martech-spacer-3;
  border-bottom: 1px solid $martech-border;
  color: $martech-text-primary;

  @include breakpoint(768) {
    flex-direction: row;
  }

  &-labels {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 4px;
  }

  .group {
    display: flex;
    align-items: center;
  }

  .level {
    color: $martech-gray-170;
    display: flex;
    align-items: center;

    #star-icon {
      filter: drop-shadow(0px 0px 0px);
      height: 14.22px;
      width: 14.86px;
    }
  }
}
</style>
