<template>
  <div class="article-header" :data-testid="componentID()" :class="{ 'light': colorMode === 'light' }">
    <img v-if="isPro & allowProIcon" :src="proIcon" alt="Pro" class="pro-icon">
    <div class="tag-wrapper" role="navigation" aria-label="breadcrumbs">
      <router-link :to="getVerticalLink" class="martech-labels martech-blue" @click="logTagClick(verticalDisplayName)">
        {{ verticalDisplayName }}
      </router-link>
      <template v-if="format">
        <span class="tag-separator">|</span>
        <router-link :to="getFormatLink" class="martech-labels martech-blue" @click="logTagClick(format)">
          {{ format }}
        </router-link>
      </template>
      <template v-for="tag in tags">
        <template v-if="tag !== 'animated article'">
          <!--Removes extra space on hover-->
          <!--eslint-disable-next-line vue/singleline-html-element-content-newline-->
          <span :key="`seperator-${tag}`" class="tag-separator">|</span>
          <router-link
            :key="tag"
            :to="{ name: 'topic', params: { tag: tag.replace(/ /g, '-') } }"
            class="martech-labels martech-blue"
            @click="logTagClick(tag)">
            {{ tag }}
          </router-link>
        </template>
      </template>
    </div>
    <h1 class="martech-title martech-text-semibold martech-semi-condensed">
      {{ title }}
    </h1>
    <p class="meta-desc">
      {{ teaser }}
    </p>
    <div v-if="deviceType === 'desktop'" class="group">
      <p class="martech-text-md author">
        By <router-link :to="authorCanonicalURL" class="martech-text-md">
          {{ authorName }}
        </router-link>
      </p>
      <div v-if="authorTwitter" class="author-twitter">
        <span class="tag-separator">|</span>
        <base-link :link-url="authorTwitter" class="martech-text-md" url-target="_blank" new-window>
          {{ authorTwitterHandle }}
        </base-link>
      </div>
      <span class="tag-separator">|</span>
      <p class="martech-text-md">
        {{ displayDate }}
      </p>
      <template v-if="readTime">
        <span class="tag-separator">|</span>
        <p class="martech-text-md">
          {{ readTime + ' ' + timePhrase }}
        </p>
      </template>
    </div>
    <template v-else>
      <div class="group mobile-group">
        <p class="martech-text-md author">
          By <router-link :to="authorCanonicalURL" class="martech-text-md">
            {{ authorName }}
          </router-link>
        </p>
        <div v-if="authorTwitter" class="author-twitter">
          <span class="tag-separator">|</span>
          <base-link :link-url="authorTwitter" class="martech-text-md" url-target="_blank" new-window>
            {{ authorTwitterHandle }}
          </base-link>
        </div>
      </div>
      <div class="group">
        <p class="martech-text-md">
          {{ displayDate }}
        </p>
        <span class="tag-separator">|</span>
        <p class="martech-text-md">
          {{ readTime + ' ' + timePhrase }}
        </p>
      </div>
    </template>
    <social-share-icons class="article-sub-header__share" :title="title" :author="authorName" :vertical="vertical"/>
  </div>
</template>

<script>
import delve from 'dlv';
import deviceType from '@/mixins/deviceType';
import amplitudeEvent from '@tcgplayer/amplitude';
import {
  BaseLink,
  SocialShareIcons,
  DateHelper as dates,
  VerticalHelpers as verts
} from '@tcgplayer/martech-components';

export default {
  name: 'article-header',
  components: {
    SocialShareIcons,
    BaseLink,
  },
  mixins: [ deviceType ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    authorData: {
      type: Object,
      required: true,
    },
    vertical: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      required: false,
      default: '',
    },
    colorMode: {
      type: String,
      required: false,
      default: '',
    },
    isPro: {
      type: Boolean,
      required: false,
    },
    allowProIcon: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      loaded: false,
      tags: [],
      proIcon: 'https://mktg-assets.tcgplayer.com/content/channel-fireball/site-images/pro-icon-black.svg',
    };
  },
  computed: {
    authorTwitterHandle() {
      const handle = this.authorTwitter.split('/').pop();

      return handle ? `@${handle}` : '';
    },
    displayDate() {
      return dates.diff(this.updatedDate, this.date, 'day') > 90
        ? `Updated ${dates.format(this.updatedDate)}`
        : `Published ${dates.format(this.date)}`;
    },
    timePhrase() {
      switch (this.contentType) {
        case 'Video':
          return 'min watch';
        case 'Podcast':
          return 'min listen';
        default:
          return 'min read';
      }
    },
    verticalDisplayName() {
      return verts.displayName(this.vertical);
    },
    getVerticalLink() {
      return verts.verticalLink(this.vertical);
    },
    getFormatLink() {
      return `${verts.verticalLink(this.vertical)}/articles/format/${this.format.toLowerCase().replaceAll(' ', '-')}`;
    },
  },
  async created() {
    if (this.data) {
      this.tags = delve(this, 'data.tags', []);
      this.teaser = delve(this, 'data.teaser', '');
      this.date = delve(this, 'data.date', '');
      this.updatedDate = delve(this, 'data.updated', '');
      this.readTime = delve(this, 'data.readTime', '');
      this.contentType = delve(this, 'data.contentType', '');
    }
    if (this.authorData) {
      this.authorName = delve(this, 'authorData.name', '');
      this.authorTwitter = delve(this, 'authorData.twitter', '');
      this.authorCanonicalURL = delve(this, 'authorData.canonicalURL', '');
    }
  },
  methods: {
    logTagClick(tag) {
      amplitudeEvent('infinite', 'infiniteArticleTagLinkClicked', {
        product_line: this.vertical,
        title: tag,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.article-header {
  padding: 0 $martech-spacer-3;

  @include breakpoint(1200) {
    padding: 0;
  }
}

.pro-icon {
  width: 50px;
  margin-bottom: $martech-spacer-1;
}

.light {
  color: $martech-white;

  .meta-desc,
  .martech-blue {
    color: $martech-white;
  }

  .author a {
    color: $martech-white;
  }

  :deep(.martech-base-link) {
    color: $martech-white;

    img {
      filter: brightness(0) invert(1);
    }
  }

  :deep(.copy-to-clipboard) {
    a {
      color: $martech-white;
    }

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $martech-spacer-1;

  .tag-separator {
    font-family: $martech-display-inter;
    font-size: $martech-type-10;
    font-weight: $martech-weight-semibold;
    letter-spacing: 1px;
    line-height: 12px;
    padding: 0.25rem;
  }
}

h1 {
  letter-spacing: 1px;
  margin-bottom: $martech-spacer-3;
}

.meta-desc {
  width: 100%;
  color: $martech-gray-100;
  font-style: italic;
  padding-bottom: $martech-spacer-3;
  font-size: $martech-type-14;

  @include breakpoint(1200) {
    font-size: $martech-type-18;
  }
}

.mobile-group {
  padding-bottom: $martech-spacer-2;
}

.group {
  display: flex;
  align-items: center;

  :deep(a) {
    color: $martech-blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

:deep(.social-share) {
  margin-top: $martech-spacer-3;

  a {
    margin-right: 0;
  }

  .martech-base-link {
    margin-right: $martech-spacer-4;
  }
}
</style>
