<template>
  <div class="page-not-found" :data-testid="componentID()">
    <div class="page-not-found__body">
      <div class="page-not-found__body-image">
        <img :src="raycoon" alt="500" class="page-not-found__body-image-raycoon">
        <h1 class="page-not-found__body-image-copy">
          Oops!
        </h1>
      </div>
      <p
        class="page-not-found__body-error-message">
        What you're looking for seems to be lost in space.
      </p>
      <div class="page-not-found__body-buttons">
        <base-button btn-style="martech-black" btn-size="martech-medium" @clicked="$router.push('/');">
          Take Me Home
        </base-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BaseButton } from '@tcgplayer/martech-components';
import { useHead } from '@vueuse/head';

useHead({
  meta: [
    { name: 'robots', content: 'noindex' },
  ],
});

const raycoon = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/404-raycoon.svg';
</script>

<style lang="scss" scoped>
.page-not-found {
  background: $martech-white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $martech-spacer-5 $martech-spacer-3; // 32px 16px
  height: 100%;

  @include breakpoint(1024) { // 1024px
    padding: $martech-spacer-7 0; // 48px
  }

  &__body {
    width: 100%;
    display: block;
    margin: auto;
    position: relative;

    &-image {
      position: relative;
      width: 300px;
      display: block;
      margin: auto;

      @include breakpoint(768) {
        width: 400px;
      }

      @include breakpoint(1024) {  // 1024px
        width: 500px;
      }

      &-raycoon {
        width: 100%;
        display: block;
        margin: auto;
        position: relative;
      }

      &-copy {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 70px;
        display: block;
        margin: auto;
        text-align: center;
        color: $martech-white;
        font-family: $martech-display-semi-condensed; // Barlow Semi Condensed
        font-weight: $martech-weight-semibold;
        font-size: $martech-type-32; // 32px

        @include breakpoint(768) {
          bottom: 95px;
        }
        @include breakpoint(1024) {  // 1024px
          bottom: 125px;
          font-size: $martech-type-48; // 48px
        }
      }
    }
    &-error-message {
      text-align: center;
      color: $martech-black;
      font-family: $martech-display-inter; // inter
      font-weight: $martech-weight-thin;
      font-size: $martech-text-primary; // 18px
    }
    &-buttons {
      padding-top: $martech-spacer-3; // 16px
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      @include breakpoint(1024) { // 1024px
        padding-top: $martech-spacer-4; // 24px
      }
    }
  }
}
</style>
