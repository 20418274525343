<template>
  <div :data-testid="componentID()">
    <div v-if="render" class="newsletter" :class="{orange: siteConfig('global.orangeBrand')}">
      <div class="photo">
        <img :src="imageURL" :alt="title">
        <i class="martech-icon martech-envelope-open-icon martech-medium" :class="siteConfig('global.orangeBrand') ? 'martech-cfb-orange-base' : 'martech-mint'"/>
        <div class="icon-background"/>
      </div>
      <div class="content">
        <h4 class="martech-sub-heading martech-text-uppercase martech-semi-condensed martech-text-semibold title">
          {{ title }}
        </h4>
        <p class="martech-text-md subtitle">
          {{ text }}
        </p>
        <form class="newsletter-form">
          <div
            class="input-wrapper"
            :class="[{'is-error' : error }, {'is-success' : signupSuccess },]">
            <default-input
              id="email"
              v-model="userName"
              :on-submit="subscribeUserToNewsletters"
              :button="{ label: 'Sign Up', position: 'right', size: 'martech-large', style: siteConfig('global.orangeBrand') ? 'martech-cfb-orange-base' : 'martech-mint' }"
              label="Email"
              hide-label
              :error="error"
              :error-message="error"
              placeholder="Email Address"/>
            <p v-show="signupSuccess" class="martech-text-sm success" :class="signupSuccess ? 'is-active' : 'is-hidden'">
              {{ confirmationText }}
            </p>
          </div>
        </form>
      </div>
    </div>
    <placeholder v-if="!render" :image-height="deviceType === 'mobile' ? '300px' : '110px'" :placeholder-height="deviceType === 'mobile' ? '358px' : '160px'"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import delve from 'dlv';
import amplitudeEvent from '@tcgplayer/amplitude';
import api from '@/api/api';
import deviceType from '@/mixins/deviceType';
import Placeholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import { DefaultInput, PageHelpers as page } from '@tcgplayer/martech-components';

export default {
  name: 'author-newsletter-widget',
  components: {
    Placeholder,
    DefaultInput,
  },
  mixins: [ deviceType ],
  props: {
    newsletterId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      signupSuccess: false,
      valid: null,
      userName: null,
      error: null,
      title: null,
      text: null,
      dripTag: null,
      imageURL: null,
      confirmationText: null,
      render: false,
      newsletterIcon: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/open-envelope.svg',
    };
  },
  async created() {
    api.getMarketingNewsletterCopy(this.newsletterId)
      .then((response) => {
        const res = response.data.result;
        this.title = delve(res, 'title', null);
        this.text = delve(res, 'text', null);
        this.imageURL = delve(res, 'imageURL', null);
        this.dripTag = delve(res, 'dripTag', null);
        this.confirmationText = delve(res, 'confirmationText', '');
        this.render = true;
      })
      .catch((newsletterTextError) => {
        this.newsletterTextError = 'Whoops! Something went wrong. Try again later.';
      });
  },
  methods: {
    ...mapActions({ gtmPushEvent: 'gtm/gtmPushEvent' }),
    subscribeUserToNewsletters() {
      amplitudeEvent('infinite', 'infiniteNewsletterSignup', {
        path: delve(window, 'location.pathname'),
        source: 'Author',
        contentVertical: page.getVertical(),
        dripTag: this.dripTag,
      });
      api.subscribeUserToNewsletters(this.userName, this.dripTag)
        .then((response) => {
          this.signupSuccess = true;
          this.gtmPushEvent([ 'newsletterSubClicked', 'Newsletters', `${deviceType === 'mobile' ? 'mobile' : 'desktop'}`, 'Article Author Widget', ]);
          this.error = null;
          setTimeout(() => {
            this.signupSuccess = false;
          }, 5000);
        })
        .catch((error) => {
          this.signupSuccess = false;
          this.error = 'Whoops! Something went wrong, please try again.';
          if (!this.userName) {
            this.error = 'This field is required.';
          } else if (!this.validEmail(this.userName)) {
            this.error = 'Invalid email format.';
          }
        });
    },
    validEmail(userName) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(userName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/components/_newsletter.scss';

.newsletter {
  border-radius: $martech-radius-medium;
  background-color: $martech-blue-5;
  padding: $martech-spacer-4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  &.orange {
    background-color: $martech-black;

    .title {
      color: $martech-cfb-orange-base;
    }

    .subtitle {
      color: $martech-white;
    }

    .photo {
      border-color: $martech-cfb-orange-base;

      .icon-background {
        background-color: $martech-black;
      }
    }

    :deep(.input-message) {
      p {
        color: $martech-system-danger;
      }
    }
  }

  @include breakpoint(1024) {
    flex-direction: row;
    padding: $martech-spacer-3 $martech-spacer-4;
  }

  .photo {
    border-radius: 50%;
    border: 2px solid $martech-mint;
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: $martech-spacer-5;

    @include breakpoint(1024) {
      width: 112px;
      height: 112px;
      margin: 0 $martech-spacer-3 $martech-spacer-3 $martech-spacer-3;
    }

    img {
      border-radius: 50%;
      width: 80px;
      height: 80px;

      @include breakpoint(1024) {
        width: 96px;
        height: 96px;
      }
    }

    .martech-icon {
      position: absolute;
      top: 80px;
      z-index: 3;

      @include breakpoint(1024) {
        top: 96px;
      }
    }

    .icon-background {
      position: absolute;
      top: 85px;
      z-index: 1;
      background-color: $martech-blue-5;
      height: 30px;
      width: 45px;

      @include breakpoint(1024) {
        top: 102px;
      }
    }
  }

  .content {
    width: 100%;
    text-align: center;

    @include breakpoint(1024) {
      width: 80%;
      text-align: left;
    }

    p {
      padding: $martech-spacer-1 0 $martech-spacer-3 0;
    }
  }

  .input {
    &-wrapper {
      flex-direction: column;

      @include breakpoint(768) {
        flex-direction: row;
      }
    }

    margin-right: 0;
    margin-bottom: $martech-spacer-2;

    @include breakpoint(768) {
      margin-bottom: 0;
      margin-right: $martech-spacer-2;
    }
  }

  .subscribe-button {
    width: 100%;

    @include breakpoint(768) {
      width: 25%;
    }
  }
}

.newsletter-form {
  .input-wrapper {
    span {
      display: block;
      width: 100%;
    }

    :deep(.martech-button) {
      white-space: nowrap;
    }

    &.is-error {
      p {
        bottom: -30px;
      }
    }
  }
}
</style>
