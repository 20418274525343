<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="youtube-icon" viewBox="0 0 57 40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="m277.85 6.25c.49 1.88.84 4.72 1.05 8.54l.1 5.21-.1 5.2c-.21 3.9-.56 6.78-1.05 8.65a7.26 7.26 0 0 1 -1.88 3.13c-.9.9-1.98 1.53-3.23 1.87-1.81.49-6.06.84-12.74 1.05l-9.5.1-9.5-.1c-6.68-.21-10.93-.56-12.74-1.05a7.24 7.24 0 0 1 -3.23-1.87 7.26 7.26 0 0 1 -1.88-3.13c-.49-1.87-.84-4.75-1.05-8.64l-.1-5.21c0-1.53.03-3.26.1-5.2.21-3.83.56-6.68 1.05-8.55a7.22 7.22 0 0 1 5.11-5.1c1.81-.49 6.06-.85 12.74-1.05l9.5-.1 9.5.1c6.68.21 10.93.56 12.74 1.05a7.24 7.24 0 0 1 5.11 5.1zm-33.2 22.3 14.93-8.55-14.93-8.44v16.98z" fill="currentColor" transform="translate(-222)"/></svg>
</template>

<script>
export default {
  name: 'youtube-icon',
};
</script>

<style lang="scss" scoped>
#youtube-icon {
  width: 28px;
  height: 28px;
}
</style>
