import Api from '@/api/api';
import { DateHelper as dates } from '@tcgplayer/martech-components';
import delve from 'dlv';

const processResponse = (response) => {
  const result = { articles: [] };
  const articles = delve(response, 'data.result', { author: {}, articles: [] }) || [];

  for (let i = 0; i < articles.length; i++) {
    if (articles[i].date != null) {
      articles[i].date = dates.format(articles[i].date);
    }
  }

  result.articles = articles;

  const { count, offset, total } = response.data;
  result.morePages = delve(response, 'data.meta.hasNextPage', (count + offset) < total);
  result.total = total;

  return result;
};

const processTagResponse = (response, type) => {
  response = delve(response, 'data.result.tags', delve(response, 'data.result.series', {}));
  const result = {
    title: response.name || '',
    imageURL: response.imageURL || '',
    mobileImageURL: response.mobileImageURL || '',
    subtitle: response.description || '',
    overlayImageURL: response.overlayImageURL || '',
    bodyText: response.bodyText || '',
  };

  return result;
};

export default {
  ArticleLoader({
    verticals, tags, series, internalTags, commanderColors, contentType, formats,
  }) {
    return ({ rows, offset }) => Api.getTaggedContent(
      {
        verticals, tags, series, internalTags, commanderColors, contentType, offset, rows, formats,
      }
    ).then(processResponse);
  },
  TagsMetadataLoader({ tagName }) {
    return () => Api.getTagMetadata({ tagName })
      .then(processTagResponse);
  },
  SeriesMetadataLoader({ seriesName }) {
    return () => Api.getSeriesMetadata({ seriesName })
      .then(processTagResponse);
  },
};
