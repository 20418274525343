<template>
  <delay-load @visible="onVisible">
    <div class="placeholder" :data-testid="componentID()">
      <div class="image" :class="{'shimmer' : visible }"/>
      <div class="text">
        <div class="text-long" :class="{'shimmer' : visible }"/>
        <div class="text-short" :class="{'shimmer' : visible }"/>
      </div>
    </div>
  </delay-load>
</template>

<script>
import DelayLoad from '@/components/DelayLoad.vue';

export default {
  name: 'small-card-placeholder',
  components: {
    DelayLoad,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onVisible() {
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: $martech-spacer-3;
  padding-bottom: $martech-spacer-2;

  .image {
    border-radius: $martech-radius-medium;
    width: 123px;
    height: 104px;
    position: relative;
  }

  .text {
      width: 100%;
      padding-left: $martech-spacer-3;
  }

  .text-long,
  .text-short {
    border-radius: $martech-radius-default;
  }

  .text-long {
    width: 70%;
    height: 15px;
    margin-bottom: 0.75rem;
    margin-top: $martech-spacer-2;
  }

  .text-short {
    width: 50%;
    height: 10px;
  }
}
</style>
