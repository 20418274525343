<template>
  <base-button
    button-type="submit"
    class="subscribe-button"
    :btn-style="btnStyle"
    :btn-size="btnSize"
    :data-testid="componentID()"
    :menu-open="menuOpen"
    @clicked="subscribeClicked">
    <envelope-icon v-if="icon" class="subscribe-button__icon"/>
    {{ text }}
  </base-button>
</template>

<script>
import { BaseButton } from '@tcgplayer/martech-components';
import EnvelopeIcon from '@/components/iconography/EnvelopeIcon.vue';

export default {
  name: 'subscribe-button',
  components: {
    BaseButton,
    EnvelopeIcon,
  },
  props: {
    icon: {
      type: Boolean,
      required: false,
    },
    btnStyle: {
      type: String,
      required: false,
      default: 'martech-black',
    },
    btnSize: {
      type: String,
      required: false,
      default: 'martech-medium',
    },
    text: {
      type: String,
      required: false,
      default: 'Sign Up',
    },
    menuOpen: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  methods: {
    subscribeClicked() {
      this.$emit('sub-clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe-button {
  display: flex;
  align-items: center;

  &__icon {
    fill: currentColor;
    width: 16px;
    margin-right: $martech-spacer-2;
  }
}
</style>
