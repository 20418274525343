import { getField } from 'vuex-map-fields';

const NAMESPACE = 'advancedsearch-sort-';
const SELECTED_SORT = `${NAMESPACE}selected-sort`;

const sortOptions = {
  latest: 'Latest',
  marketHigh: 'Market Price: High to Low',
  marketLow: 'Market Price: Low to High',
};

const updateFns = {
  selectedSort: (state, value) => {
    if (sortOptions[value]) {
      state.selectedSort = value;
      localStorage.setItem(SELECTED_SORT, value);
    }
  },
};

export default ({
  namespaced: true,
  state: {
    sortOptions,
    selectedSort: sortOptions[localStorage.getItem(SELECTED_SORT)] != null ? localStorage.getItem(SELECTED_SORT) : 'latest',
  },
  getters: {
    getField,
  },
  mutations: {
    updateField(state, { path, value }) {
      if (updateFns[path]) {
        updateFns[path](state, value);
        return;
      }

      state[path] = value;
    },
  },
});
