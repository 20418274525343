<template>
  <div class="article-list" :class="{'mobile-scroll' : deviceType === 'mobile' && mobileScroll}" :data-testid="componentID()">
    <image-article-card v-for="article in articles" :key="article.canonicalURL" :truncate-title="50" :article="article" @click="onClick(article)"/>
  </div>
</template>

<script>
import ImageArticleCard from '@/components/card-elements/Cards/Image/Article.vue';
import deviceType from '@/mixins/deviceType';

export default {
  name: 'image-view',
  components: {
    ImageArticleCard,
  },
  mixins: [ deviceType ],
  props: {
    articles: {
      type: Array,
      required: true,
      default: null,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
    mobileScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.article-list {
  width: 100%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 49%);

  &.mobile-scroll {
    display: inline-flex;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    grid-gap: 0px;
    padding-left: $martech-spacer-3;
    padding-right: $martech-spacer-3;
    width: 100vw;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    :deep(.accessibility-wrapper) {
      margin-right: $martech-spacer-2;

      &:last-of-type {
        margin-right: 0;
        padding-right: $martech-spacer-3;
      }

      .content-wrapper {
        width: 150px;
        scroll-snap-align: center;
      }
    }
  }
}
</style>
