<template>
  <div ref="navWrap" class="navigation-backfill" :data-testid="componentID()">
    <div v-show="alertVisible" ref="alertRef" class="alert-wrap">
      <alert
        type="info"
        :alert-title="alertTitle"
        :alert-text="alertText"
        :button="{buttonURL: termsURL, buttonText: termsText}"/>
    </div>
    <div ref="navHeader" class="navigation-header">
      <div class="nav-background" :class="[{'subscriptions-nav': $route.meta.changeNav === 'subscriptions',}]">
        <div class="navigation-header__content">
          <div class="container group">
            <div class="logo-verticals">
              <navigation-logo/>
              <div v-show="isDesktop && notSubscriptionsLandingPage" class="dropdown">
                <div v-for="(menu, index) in dropdownItems" :key="'menu'+index" class="dropdown-item">
                  <div
                    :id="`navigation-menu-${index}`"
                    class="dropdown-toggle martech-text-uppercase"
                    :class="{'is-active' : menu.open}"
                    tabindex="0"
                    aria-haspopup="true"
                    :aria-expanded="menu.open"
                    data-toggle="collapse"
                    data-target="#dropdown-menu"
                    @mouseenter="toggleDropdown(index)"
                    @mouseleave="closeDropdown(index)"
                    @keypress.enter.space.prevent.stop="toggleDropdown(index)"
                    @keydown.esc.prevent.stop="closeDropdown(index)"
                    @click="mainNavClick(index)">
                    <base-link
                      :link-url="menu.home || '#'"
                      class="home-link"
                      tabindex="-1"
                      :url-target="menu.externalLink ? '_blank' : ''">
                      {{ menu.title }}
                    </base-link>
                    <iconography-chevron v-if="menu?.links?.length" class="chevron-down" direction="down"/>
                    <div v-if="menu.externalLink" class="external-primary martech-icon martech-external-link-icon martech-white"/>

                    <transition name="fade">
                      <div v-show="menu.open" id="dropdown-menu" class="dropdown-menu shadow">
                        <template v-if="menu?.links?.length">
                          <div class="dropdown-menu__wrapper">
                            <ul>
                              <li v-for="item in menu.links" :key="item">
                                <base-link
                                  :link-url="item.link"
                                  :url-target="item.externalLink ? '_blank' : ''"
                                  @click="linkClick(item.link, item.linkText, menu.game)">
                                  {{ item.linkText }}
                                  <div v-if="item.externalLink" class="martech-icon martech-external-link-icon martech-black"/>
                                </base-link>
                              </li>
                            </ul>
                          </div>
                        </template>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <div class="navigation-icons">
              <search-bar v-if="isDesktop && $route.meta.changeNav !== 'subscriptions'"/>
              <div
                class="account"
                tabindex="-1"
                aria-haspopup="true"
                :aria-expanded="accountOpen"
                data-toggle="collapse"
                @click="accountOpen = !accountOpen"
                @mouseleave="closeAccountMenu">
                <button
                  class="menu-toggle"
                  tabindex="0"
                  type="button"
                  data-target="#account-menu"
                  aria-label="account menu">
                  <subscriber-user-icon v-if="subscriptionStatus === 'active' || subscription.status === 'active'"/>
                  <user-icon v-else/>
                </button>
                <transition name="fade">
                  <account-menu v-show="accountOpen" :user-name="userName" :subscription-status="subscriptionStatus"/>
                </transition>
              </div>
              <div :class="{'is-disabled' : $route.meta.changeNav === 'subscriptions'}" class="newsletter-button">
                <subscribe-button
                  icon
                  btn-style="martech-text"
                  btn-size="martech-small"
                  text="Emails"
                  :menu-open="isNewsVisible"
                  @sub-clicked="toggleNewsDrawer(true)"/>
              </div>
              <button
                v-show="isMobile && notSubscriptionsLandingPage"
                class="menu-toggle hamburger-button"
                type="button"
                data-toggle="collapse"
                data-target="#nav-menu"
                :aria-expanded="isVisible"
                aria-label="main navigation"
                @click="toggleNavDrawer(true)">
                <hamburger-icon/>
              </button>
            </div>
          </div>
          <search-bar v-if="isMobile && notSubscriptionsLandingPage"/>
        </div>
        <div v-show="isMobile" class="navigation-header__sidebar">
          <mobile-nav :user-name="userName" :subscription-status="subscriptionStatus"/>
        </div>
        <header-subscribe-drawer/>
      </div>
      <vert-sub-nav v-if="verticalType === 'magic'" :vertical="verticalType"/>
    </div>
  </div>
</template>

<script setup>
import {
  ref, computed, watch
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  get, set, useEventListener, useElementSize
} from '@vueuse/core';

import api from '@/api/api';
import useNavigation from '@/use/cfb/Navigation';
import useVerticals from '@/use/Verticals';

import NavigationLogo from '@/components/navigation-elements/NavigationLogo.vue';
import HamburgerIcon from '@/components/iconography/HamburgerIcon.vue';
import AccountMenu from '@/components/navigation-elements/AccountMenu.vue';
import SubscriberUserIcon from '@/components/iconography/user-icons/SubscriberUserIcon.vue';
import SubscribeButton from '@/components/newsletters/SubscribeButton.vue';
import HeaderSubscribeDrawer from '@/components/newsletters/HeaderSubscribeDrawer.vue';
import UserIcon from '@/components/iconography/user-icons/UserIcon.vue';
import VertSubNav from '@/components/navigation-elements/VerticalLandingSubNav.vue';
import SearchBar from '@/components/navigation-elements/SearchBar.vue';
import MobileNav from '@/components/navigation-elements/cfb/MobileNavigationCfb.vue';

import {
  Alert, useDeviceType, Auth, BaseLink, IconographyChevron
} from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';

defineProps({
  height: {
    type: Number,
    required: false,
    default: null,
  },
});

const route = useRoute();
const store = useStore();
const { dropdownItems } = useNavigation();
const { verticalType } = useVerticals();

const { isMobile, isDesktop } = useDeviceType();

const triedUserFetch = ref(false);
const subscriptionStatus = ref();
const userName = ref();
const loaded = ref(false);
const accountOpen = ref(false);
const otherClick = ref(false);
const navHeader = ref(null);
const alertRef = ref(null);
const navWrap = ref(null);

const alertTitle = ref();
const alertText = ref();
const termsURL = ref();
const termsText = ref();
const alertVisible = ref(false);

const emit = defineEmits([ 'update:height' ]);

const isNewsVisible = computed(() => store.state.sidebar.isNewsVisible);
const isVisible = computed(() => store.state.sidebar.isVisible);
const subscription = computed(() => store.state.subscription.subscription);

const { height: navHeight } = useElementSize(navHeader);
const { height: alertHeight } = useElementSize(alertRef);

watch(
  [ get(route).fullPath, navHeight, alertHeight ],
  () => emit('update:height', alertHeight.value + navHeight.value)
);

const toggleNewsDrawer = (val) => {
  store.dispatch('sidebar/toggleNewsDrawer', val);
};

const toggleNavDrawer = (val) => {
  store.dispatch('sidebar/toggleNavDrawer', val);
};

if (!get(triedUserFetch)) {
  Auth.getUserData().then((userData) => {
    set(triedUserFetch, true);
    set(loaded, userData.authenticated);
    set(userName, userData.userName);
    set(subscriptionStatus, userData.subscriptionStatus);
  });
}

const getAlert = () => {
  api.getAlerts()
    .then((response) => {
      const res = response.data.result;
      set(alertTitle, res?.title || null);
      set(alertText, res?.text || null);
      set(termsURL, res?.termsURL || null);
      set(termsText, res?.termsText || null);
      set(alertVisible, true);
    }).catch(() => {
      set(alertVisible, false);
    });
};

getAlert();

const notSubscriptionsLandingPage = computed(() => get(route).meta.changeNav !== 'subscriptions');

const updatePositionOnScroll = () => {
  // Positions nav bar at top 0px if scroll is 15px from top.
  if (!get(alertVisible)) return;

  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

  if (get(route).meta.changeNav === 'subscriptions') {
    get(navHeader).style.position = 'relative';
  }

  if (winScroll >= 15) {
    get(navHeader).style.transform = `translateY(-${get(alertRef).offsetHeight}px)`;
    get(navHeader).style.transition = 'transform .18s cubic-bezier(0.17, 0.17, 0, 1)';
    get(navHeader).style.height = 'auto';
  } else {
    get(navHeader).style.transform = 'translateY(0px)';
    get(navHeader).style.height = 'auto';
  }
};

useEventListener(document, 'scroll', () => {
  updatePositionOnScroll();
});

const linkClick = (linkURL, linkTitle, linkParent) => {
  set(otherClick, true);
  amplitudeEvent('infinite', 'infiniteNavigationClicked', {
    linkURL,
    linkTitle,
    linkParent,
    location: 'primary',
  });
};

const mainNavClick = (i) => {
  get(dropdownItems)[i].open = false;
  if (!get(otherClick)) linkClick(get(dropdownItems)[i].home, get(dropdownItems)[i].title);
  set(otherClick, false);
};

const closeAccountMenu = () => {
  setTimeout(() => {
    set(accountOpen, false);
  }, 300);
};

const toggleDropdown = (i) => {
  // Only allow 1 menu open at a time
  get(dropdownItems).forEach((menu, index) => {
    if (index !== i) menu.open = false;
  });

  get(dropdownItems)[i].open = !get(dropdownItems)[i].open;

  // Set focus so the escape key will work to close the menu
  document.getElementById(`navigation-menu-${i}`).focus();
};

const closeDropdown = (i) => {
  get(dropdownItems)[i].open = false;
};
</script>

<style lang="scss" scoped>
.navigation-backfill {
  position: fixed;
  top: 0;
  z-index: 2147483641;
  transform: translate3d(0, 0, 0);
  width: 100%;

  .navigation-header {
    background: transparent;
    width: 100%;
    min-height: 92px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;

    @media only screen and (min-width: 1025px) {
      min-height: 70px;
      padding: 0;
    }

    .logo-verticals {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;

      @include breakpoint(1024) {
        height: 100%;
      }

      @media only screen and (min-width: 1025px) {
        justify-content: space-between;
      }
    }

    .nav-background {
      background-color: $martech-black;
      width: 100%;
      border-bottom: 1px solid $martech-gray-130;

      &.subscriptions-nav {
        border-bottom: 1px solid $martech-black;
      }
    }

    &__content {
      width: 100%;
      min-height: 92px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      @include breakpoint(1200) {
        flex-direction: row;
        min-height: 70px;
      }

      .group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (min-width: 1025px) {
          flex-direction: column;
        }

        @include breakpoint(1500) {
          flex-direction: row;
        }
      }
    }
  }
}

.navigation-icons {
  display: flex;
  justify-content: flex-end;
  padding-left: $martech-spacer-2;
  align-items: center;

  @include breakpoint(1024) {
    width: 65%;
  }

  @media only screen and (min-width: 1025px) {
    width: 100%;
    padding-bottom: $martech-spacer-3;
    padding-left: 0;

    :deep(.search-bar) {
      padding: 0;
    }
  }

  @include breakpoint(1500) {
    width: 50%;
    padding-bottom: 0;
  }

  .icon-group {
    display: flex;
    align-items: center;
  }

  .menu-toggle {
    background: transparent;
    border: 0;
    color: $martech-white;
    height: 36px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    @include breakpoint(1024) {
      margin: 0 $martech-spacer-2;
      height: 48px;
    }

    &.search {
      margin: 0;

      svg {
        height: 20px;
        width: 20px;
        margin-top: -4px;
      }
    }

    &.is-disabled {
      display: none;
    }

    &.white-icon {
      color: $martech-white;
      padding-right: 0;
    }

    &:hover,
    &:focus {
      color: $martech-cfb-orange-base;
    }
  }

  .menu-toggle:last-of-type {
    padding-right: 0;
  }

  .hamburger-button {
    display: block;

    @include breakpoint(1200) {
      display: none;
    }
  }

  .logged-in {
    margin: 0;
    padding-right: $martech-spacer-4;
  }

  .subs-login-btn {
    margin-right: $martech-spacer-3;
  }

  .newsletter-button {
    :deep(.martech-button) {
      border: 0;
      padding: 0;
      color: $martech-white;

      &:hover {
        color: $martech-cfb-orange-base;
      }
    }

    &.is-disabled {
      display: none;
    }

    display: none;

    @media only screen and (min-width: 1025px) {
      display: block;
    }
  }
}

.account {
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;

  @include breakpoint(1500) {
    height: 70px;
  }
}

.shop-link {
  padding: 0 $martech-spacer-3;
  font-weight: $martech-weight-semibold;
  color: $martech-gray-160;
  font-size: $martech-type-14;
  letter-spacing: 0.5px;
  cursor: pointer;
  height: 70px;
  display: flex;
  align-items: center;
  transition: all 0.05s ease-in-out;
  border-bottom: 4px solid $martech-black;
  position: relative;
  line-height: 14px;

  @media only screen and (min-width: 1025px) {
    font-size: $martech-type-12;
  }

  @include breakpoint(1200) {
    font-size: $martech-type-14;
  }

  .martech-external-link-icon {
    margin-left: $martech-spacer-2;
    height: 12px;
    width: 12px;
    margin-bottom: 2px;
  }

  &:hover {
    color: $martech-cfb-orange-base;
  }
}

.dropdown {
  display: flex;
  position: relative;
  overflow: visible;
  padding-left: $martech-spacer-2;

  .external-primary {
    margin-left: $martech-spacer-1;
  }

  &-item {
    &:last-child {
      .dropdown-menu {
        @media only screen and (min-width: 1025px) {
          right: 0;
          left: auto;
        }

        @include breakpoint(1500) {
          left: 0;
          right: auto;
        }
      }
    }
  }

  &-toggle {
    padding: 0 $martech-spacer-2;
    font-weight: $martech-weight-semibold;
    letter-spacing: 0.5px;
    cursor: pointer;
    height: 70px;
    display: flex;
    align-items: center;
    transition: all 0.05s ease-in-out;
    border-bottom: 4px solid $martech-black;
    position: relative;
    transform: translate3d(0, 0, 0);
    z-index: 50;

    @media only screen and (min-width: 1025px) {
      padding: 0 $martech-spacer-2;
    }

    @include breakpoint(1200) {
      padding: 0 $martech-spacer-3;
    }

    .chevron-down {
      margin-left: $martech-spacer-3;
      width: 11px;
      height: 6px;
      color: $martech-white;
    }

    :deep(.home-link) a {
      color: $martech-white;
      font-size: $martech-type-14;

      @media only screen and (min-width: 1025px) {
        font-size: $martech-type-12;
      }

      @include breakpoint(1200) {
        font-size: $martech-type-14;
      }
    }

    &.is-active {
      border-bottom: 4px solid $martech-cfb-orange-base;
      transform: scaleX(1);

      :deep(.home-link) a {
        color: $martech-cfb-orange-base;
      }

      .chevron-down {
        transition: transform 0.15s ease-in;
        transform: rotateX(180deg);
      }
    }
  }

  &-menu {
    position: absolute;
    z-index: 3;
    background-color: $martech-white;
    top: 70px;
    left: 0;
    width: 300px;
    border-bottom-right-radius: $martech-radius-default;
    border-bottom-left-radius: $martech-radius-default;

    ul {
      list-style: none;
      position: relative;
      z-index: 3;
      padding: $martech-spacer-2 0;
      margin: 0;

      hr {
        margin: $martech-spacer-2 0;
      }

      .shop,
      .price-guide {
        .wrapper {
          display: flex;
          align-items: center;
          width: 100%;
        }
      }

      li {
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 $martech-spacer-3;
        font-size: $martech-type-14;
        letter-spacing: 0.4px;
        color: $martech-gray-160;
        text-transform: capitalize;
        width: 100%;
        font-weight: $martech-weight-normal;

        :deep(span) {
          width: 100%;
        }

        :deep(.martech-base-link) {
          color: $martech-gray-160;
          font-size: $martech-type-14;
          display: flex;
          justify-content: space-between;
        }

        .martech-icon {
          margin-right: $martech-spacer-3;
        }

        .pro-icon {
          width: 24px;
          margin-right: $martech-spacer-3;
        }

        &:hover {
          background: rgba(243, 109, 33, 0.15);
        }

        .martech-external-link-icon {
          margin-right: 0;
          height: 13px;
        }
      }
    }
  }
}

:deep(.sub-navigation) {
  top: 92px;

  @include breakpoint(1024) {
    height: 55px;
  }
}
</style>
