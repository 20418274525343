export default {
  namespaced: true,
  actions: {
    gtmPushEcommerceEvent(context, [ name, type, products, ]) {
      const event = {
        event: name,
        ecommerce: {
          currencyCode: 'USD',
          [type]: {},
        },
      };
      event.ecommerce[type].products = products;
      dataLayer.push(event);
    },
    gtmPushEvent(context, [ name, category, action, label, value = 0 ]) {
      const event = {
        event: name,
        event_category: category || '',
        event_action: action || '',
        event_label: label || '',
        event_value: value,
      };
      dataLayer.push(event);
    },
    gtmPushDataLayer(context, value) {
      dataLayer.push(value);
    },
  },
};
