<template>
  <div class="magnify-wrapper" :data-testid="componentID()">
    <div class="magnify-image" :class="imageSizes">
      <img :src="url" :alt="altText">
      <div class="caption">
        {{ caption }}
      </div>
      <magnify-button v-if="allowMagnify && deviceType === 'desktop'" @magnify="magnify"/>
    </div>
    <modal :show-modal="openModal" include-shadows :class="imageSizes" @close="closeModal">
      <img ref="image" :src="url" :alt="altText" :class="imageSizes">
    </modal>
  </div>
</template>

<script>
import delve from 'dlv';
import MagnifyButton from '@/components/elements/MagnifyButton.vue';
import Modal from '@/components/elements/Modal.vue';
import deviceType from '@/mixins/deviceType';

export default {
  name: 'magnify-image',
  components: {
    Modal,
    MagnifyButton,
  },
  mixins: [ deviceType ],
  props: {
    options: {
      type: String,
      required: false,
      default: '',
      note: 'should be a json STRING, which will be parsed',
    },
  },
  data() {
    return {
      openModal: false,
    };
  },
  computed: {
    imageSizes() {
      switch (this.imgSize) {
        case 'card':
        case 'tall-rectangle':
        case 'full-width':
          return this.imgSize;
        default: return 'half-width';
      }
    },
  },
  async created() {
    const imageOptions = JSON.parse(this.options);

    this.allowMagnify = true;
    this.caption = '';

    if (imageOptions) {
      this.url = delve(imageOptions, 'url', '');
      this.imgSize = delve(imageOptions, 'img-size');
      this.altText = delve(imageOptions, 'alt') || delve(imageOptions, 'alt-text', process.env.VUE_APP_TITLE);
      this.allowMagnify = !(delve(imageOptions, 'allow-magnify') === 'false');
      this.caption = delve(imageOptions, 'caption', '');
    }
  },
  methods: {
    magnify() {
      this.openModal = true;
    },
    closeModal() {
      this.openModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.magnify-image {
  position: relative;
  width: 100%;
  display: block;
  margin: auto;

  .caption {
    font-size: $martech-type-12;
    font-weight: $martech-weight-normal;
    color: $martech-gray-100;
    line-height: 12px;
    text-align: center;
    margin-top: $martech-spacer-1;
  }

  img {
    width: 100%;
  }

  &.full-width {
    img {
      width: 100%;
    }
  }

  &.tall-rectangle {
    width: 100%;

    @include breakpoint(1024) {
      width: 650px;
    }
  }

  &.half-width {
    width: 100%;
    @media only screen and (min-width: 768px) {
      width: 562px;
    }
  }

  &.card {
    width: 265px;
  }

  :deep(.magnify-button) {
    position: absolute;
    top: 40%;
    left: 0;
  }
}

:deep(.content) {
  overflow: scroll;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    background-color: $martech-white;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: $martech-white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: $martech-gray-50;
  }

  img {
    &.card {
      height: 500px;
      width: 350px;
      display: block;
      margin: auto;
    }

    &.full-width, &.half-width {
      width: auto;
      height: 650px;

      @include breakpoint(1200) {
        display: block;
        margin: auto;
      }
    }

    &.tall-rectangle {
      height: 100%;
      display: block;
      margin: auto;

      @include breakpoint(1024) {
        transform: scale(1.5);
        margin-top: 40%;
      }

      @include breakpoint(1200) {
        margin-top: 15%;
      }

      @include breakpoint(1500) {
        margin-top: 12%;
      }
    }
  }
}

:deep(.card) {
  .content {
    .scroll-overlay {
      display: none;
    }
  }
}
</style>
