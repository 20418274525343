<template>
  <div class="list-item" :data-testid="componentID()">
    <card-box-article
      v-if="type === 'article' || type === 'video' || type === 'podcast'"
      :article="article"
      :show-author="showAuthor"
      :show-date="showDate"
      :show-format="showFormat"
      :show-tag="showTag"
      :show-vertical="showVertical"
      :show-read-time="showReadTime"
      :large="large"
      @click="onClick(article)"/>
  </div>
</template>

<script>
import delve from 'dlv';
import CardBoxArticle from '@/components/card-elements/Cards/Box/Article.vue';

export default {
  name: 'box-card',
  components: {
    CardBoxArticle,
  },
  props: {
    article: {
      type: Object,
      required: true,
      default: null,
    },
    showAuthor: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    showFormat: {
      type: Boolean,
      default: false,
    },
    showTag: {
      type: Boolean,
      default: false,
    },
    showVertical: {
      type: Boolean,
      default: false,
    },
    showReadTime: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
  },
  computed: {
    type() {
      const type = delve(this.article, 'type', delve(this.article, 'contentType', '')) || 'article';
      return type.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  margin-bottom: $martech-spacer-2;

  :deep(.card-content) {
    border-bottom: 1px solid $martech-border;
  }
  &:last-of-type {
    margin-bottom: 0;

    :deep(.card-content) {
      border-bottom: 0;
    }
  }
}
</style>
