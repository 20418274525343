export default {
  mounted: (el, binding, vnode) => {
    // eslint-disable-next-line func-names
    el.clickOutsideEvent = function (event) {
      // here we check if the click event is outside the element and it's children
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    // register click and touch events
    document.body.addEventListener('click', el.clickOutsideEvent);
    document.body.addEventListener('touchstart', el.clickOutsideEvent);
  },
  unmounted(el) {
    // unregister click and touch events before the element is unmounted
    document.body.removeEventListener('click', el.clickOutsideEvent);
    document.body.removeEventListener('touchstart', el.clickOutsideEvent);
  },
};
