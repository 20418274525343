<template>
  <delay-load @visible="onVisible">
    <div class="article-grid">
      <template v-if="loading">
        <div v-for="(p, index) in rows" :key="index" class="item placeholder">
          <full-art-placeholder :include-text="false"/>
        </div>
      </template>
      <template v-else>
        <div v-for="article in articles" :key="article.uuid" class="item">
          <slim-article-card :data="article"/>
        </div>
      </template>
    </div>
  </delay-load>
</template>

<script setup>
import Api from '@/api/api';
import DelayLoad from '@/components/DelayLoad.vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import { SlimArticleCard } from '@tcgplayer/martech-components';
import { set } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps({
  vertical: {
    type: String,
    required: true,
  },
  rows: {
    type: Number,
    required: false,
    default: 1,
  },
  offset: {
    type: Number,
    required: false,
    default: 0,
  },
  contentType: {
    type: String,
    required: false,
    default: 'Article',
  },
  uniqueResultsField: {
    type: String,
    required: false,
    default: 'uuid',
  },
  highlighted: {
    type: Boolean,
    required: false,
    default: null,
  },
  featured: {
    type: Boolean,
    required: false,
    default: null,
  },
  tagName: {
    type: String,
    required: false,
    default: '',
  },
  seriesName: {
    type: String,
    required: false,
    default: '',
  },
});

const articles = ref([]);
const loading = ref(true);

async function onVisible() {
  if (props.tagName) {
    const res = await Api.getTaggedContent({
      verticals: props.vertical, tags: props.tagName, contentType: props.contentType, offset: props.offset, rows: props.rows, featured: props.featured, highlighted: props.highlighted, uniquedResultsField: props.uniqueResultsField,
    });

    set(articles, res?.data?.result || []);
    set(loading, false);
  } else if (props.seriesName) {
    const res = await Api.getTaggedContent({
      verticals: props.vertical, series: props.seriesName, contentType: props.contentType, offset: props.offset, rows: props.rows, featured: props.featured, highlighted: props.highlighted, uniquedResultsField: props.uniqueResultsField,
    });

    set(articles, res?.data?.result || []);
    set(loading, false);
  } else {
    const res = await Api.getVerticalPage({
      verticals: props.vertical,
      contentType: props.contentType,
      offset: props.offset,
      rows: props.rows,
      featured: props.featured,
      highlighted: props.highlighted,
      uniqueResultsField: props.uniqueResultsField,
    });

    set(articles, res?.data?.result || []);
    set(loading, false);
  }
}

</script>

<style lang="scss" scoped>
.article-grid {
    padding: $martech-spacer-4 0;

    .item {
      margin-bottom: $martech-spacer-3;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
}
</style>
