<!--You can embed a tweet into an article with the following markup: <tweet-embed id="12345"/> Where 12345 is the unique ID of the tweet from twitter. -->
<template>
  <div v-if="id" class="tweet-embed" :data-testid="componentID()">
    <div ref="container"/>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({ // eslint-disable-line
  id: {
    type: String,
    required: true,
  },
});

const container = ref();

function addScript() {
  if (window.twttr) return;

  const s = document.createElement('script');
  s.setAttribute('src', 'https://platform.twitter.com/widgets.js');
  s.addEventListener('load', display, { once: true }); // eslint-disable-line
  document.body.appendChild(s);
}

function display() {
  if (!window.twttr) return addScript();

  return window.twttr.ready().then(({ widgets }) => {
    widgets.createTweet(props.id, container.value);
  });
}

onMounted(async () => {
  display();
});
</script>

<style lang="scss" scoped>
.tweet-embed {
  > div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
