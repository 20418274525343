<template>
  <!--eslint-disable-next-line max-len-->
  <svg id="twitter-icon" viewBox="0 0 49 40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="m185.93 12c.06.26.1.67.1 1.25 0 4.62-1.12 9.07-3.36 13.37a27.42 27.42 0 0 1 -9.66 10.86 27.52 27.52 0 0 1 -15.61 4.52c-5.62 0-10.75-1.5-15.41-4.52.7.06 1.5.1 2.4.1 4.65 0 8.83-1.45 12.53-4.33-2.23 0-4.2-.66-5.89-1.97a10.04 10.04 0 0 1 -3.49-4.95c.64.06 1.24.1 1.82.1.9 0 1.79-.1 2.68-.3-2.3-.5-4.21-1.7-5.74-3.55a9.7 9.7 0 0 1 -2.3-6.35v-.2c1.4.84 2.9 1.3 4.5 1.35a11.18 11.18 0 0 1 -3.26-3.65 9.73 9.73 0 0 1 -1.24-4.86 9.7 9.7 0 0 1 1.44-5.04 27.74 27.74 0 0 0 9.13 7.5 27.72 27.72 0 0 0 11.54 3.07c-.13-.77-.2-1.53-.2-2.3 0-1.8.45-3.48 1.35-5.05a9.86 9.86 0 0 1 12.68-4.18c1.24.57 2.34 1.34 3.3 2.3 2.3-.45 4.43-1.25 6.41-2.4a10.01 10.01 0 0 1 -4.4 5.58 21.5 21.5 0 0 0 5.75-1.65 22.15 22.15 0 0 1 -5.07 5.29z" fill="currentColor" transform="translate(-142 -2)"/></svg>
</template>

<script>
export default {
  name: 'twitter-icon',
};
</script>

<style lang="scss" scoped>
#twitter-icon {
  width: 28px;
  height: 28px;
}
</style>
