<template>
  <delay-load :data-testid="componentID()" @visible="onVisible">
    <div class="article-grid" :class="{ 'large-feature': largeFeature }" :style="{ 'grid-template-columns': `repeat(${gridColumns}, 1fr)` }">
      <!--Placeholder-->
      <template v-if="loading">
        <div v-for="(p, index) in rows" :key="index" class="grid-item placeholder" :style="{ height: cardHeight }">
          <full-art-placeholder :include-text="false"/>
        </div>
      </template>
      <!--Content Card-->
      <template v-else>
        <div v-for="article in articles" :key="article" class="grid-item" :style="{ height: cardHeight }">
          <large-square-full-card :article-data="article"/>
        </div>
      </template>
    </div>
  </delay-load>
</template>

<script setup>
import Api from '@/api/api';
import DelayLoad from '@/components/DelayLoad.vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import { LargeSquareFullCard } from '@tcgplayer/martech-components';
import { set } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps({
  vertical: {
    type: String,
    required: true,
  },
  highlighted: {
    type: Boolean,
    required: false,
    default: null,
  },
  showVerticalLink: {
    type: Boolean,
    required: false,
    default: false,
  },
  featured: {
    type: Boolean,
    required: false,
    default: null,
  },
  rows: {
    type: Number,
    required: false,
    default: 1,
  },
  offset: {
    type: Number,
    required: false,
    default: 0,
  },
  contentType: {
    type: String,
    required: false,
    default: 'Article',
  },
  uniqueResultsField: {
    type: String,
    required: false,
    default: 'uuid',
  },
  largeFeature: {
    type: Boolean,
    required: false,
    default: false,
  },
  tagName: {
    type: String,
    required: false,
    default: '',
  },
  gridColumns: {
    type: Number,
    required: false,
    default: 2,
  },
  formats: {
    type: Array,
    default: () => ([]),
  },
  omitFormats: {
    type: Array,
    default: () => ([]),
  },
  cardHeight: {
    type: String,
    default: '',
  },
});

const articles = ref([]);
const loading = ref(true);

async function onVisible() {
  const params = {
    verticals: props.vertical,
    contentType: props.contentType,
    offset: props.offset,
    rows: props.rows,
    featured: props.featured,
    highlighted: props.highlighted,
    uniquedResultsField: props.uniqueResultsField,
  };

  if (props.formats.length) params.formats = props.formats.join('|');
  else if (props.omitFormats.length) params.omitFormats = props.omitFormats.join('|');

  if (props.tagName) params.tags = props.tagName;

  const res = await Api.getTaggedContent(params);

  set(articles, res?.data?.result || []);
  set(loading, false);
}
</script>

<style lang="scss" scoped>
.article-grid {
  display: flex;
  flex-direction: column;
  grid-gap: $martech-spacer-3;

  @include breakpoint(768) {
    display: grid;
    grid-gap: $martech-spacer-4;
  }

  &.large-feature {
    .grid-item {
      width: 100%;

      &:nth-child(1) {
        @include breakpoint(768) {
          height: 632px;
          grid-column: 1 / 3;
        }
      }
    }
  }

  .placeholder {
    :deep(.delay-wrapper) {
      height: 100%;
    }

    min-height: 400px;
  }
}
</style>
