<!--You can embed a youtube video/clip into an article with the following markup: <youtube-embed id="12345"/> Where 12345 is the unique ID of the youtube video/clip. -->
<template>
  <div :v-if="id" class="youtube-embed video-responsive" :data-testid="componentID()">
    <iframe
      ref="iframe"
      :src="videoURL"
      title="youtube embed"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      @mouseleave="mouseLeaveIframe"/>
  </div>
</template>

<script>

export default {
  name: 'youtube-embed',
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    onClick: {
      type: Function,
      required: false,
      default: null,
    },
    start: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      iframeClicked: false,
    };
  },
  computed: {
    videoURL() {
      let url = `https://www.youtube.com/embed/${this.id}`;

      if (this.start) {
        url += url.includes('?') ? '&' : '?';
        url += `start=${this.start}`;
      }

      return url;
    },
  },
  created() {
    if (!this.onClick) return;

    const listener = () => {
      if (document.activeElement === this.$refs.iframe) {
        if (!this.iframeClicked) {
          this.iframeClicked = true;
          this.onClick();
        }
      }
    };
    window.addEventListener('blur', listener);
  },
  methods: {
    mouseLeaveIframe() {
      // We need to set focus back to the window so that a click into another iframe causes a blur on the window
      window.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.youtube-embed {
  overflow: hidden;

  > iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}
</style>
