import { getField, updateField } from 'vuex-map-fields';
import delve from 'dlv';
import { DateHelper as dates } from '@tcgplayer/martech-components';
import Api from '@/api/api';

function clean(str) {
  if (typeof str !== 'string') return '';

  return str
    .trim()
    .toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, ' ')
    .replace(/\s/g, '-');
}

function getArticle(state, id) {
  // Required or missing fields will not overwrite the previous when everything is merged
  // and we'll have mixed/incorrect data
  state.article = { title: process.env.VUE_APP_TITLE };
  state.author = {
    analyticsSource: process.env.VUE_APP_NAME_ANALYTICS,
    analyticsMedium: 'referral',
    analyticsCampaign: '',
    analyticsContent: '',
  };
  state.analytics = {
    utm_source: process.env.VUE_APP_NAME_ANALYTICS,
    utm_medium: 'referral',
    utm_campaign: '',
    utm_content: '',
  };

  state.embedTypes = [];

  if (state.errors[id]) {
    return Promise.reject(state.errors[id]);
  }

  if (state.responses[id]) {
    const article = state.responses[id]?.data?.result?.article || {};
    const author = state.responses[id]?.data?.result?.author || {};

    Object.assign(state.article, article);
    Object.assign(state.author, author, {
      analyticsCampaign: clean(author.name),
      analyticsContent: clean(article.title),
    });
    Object.assign(state.embedTypes, state.responses[id]?.data?.result?.embedTypes || {});
    Object.assign(state.analytics, {
      utm_campaign: clean(author.name),
      utm_content: clean(article.title),
    });
    return Promise.resolve(state.responses[id]);
  }

  return Api.getArticle(id).then((response) => {
    state.responses[id] = response;
    state.errors[id] = null;

    const article = state.responses[id]?.data?.result?.article || {};
    const author = state.responses[id]?.data?.result?.author || {};

    Object.assign(state.article, article);
    Object.assign(state.author, author);
    Object.assign(state.embedTypes, delve(state.responses[id], 'data.result.embedTypes', {}));
    Object.assign(state.analytics, {
      utm_campaign: clean(author.name),
      utm_content: clean(article.title),
    });

    state.article.date = dates.format(state?.article?.date || new Date());
  }).catch((error) => {
    state.errors[id] = error;
    state.responses[id] = null;
    return Promise.reject(error);
  });
}

export default ({
  namespaced: true,
  state: {
    article: {
      title: process.env.VUE_APP_TITLE,
    },
    author: {
      analyticsSource: process.env.VUE_APP_NAME_ANALYTICS,
      analyticsMedium: 'referral',
      analyticsCampaign: '',
      analyticsContent: '',
    },
    analytics: {},
    embedTypes: [],
    errors: {},
    responses: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
  actions: {
    loadArticle({ state }, id) {
      return getArticle(state, id);
    },
  },
});
