<template>
  <button class="magnify-button" type="button" :data-testid="componentID()" @click="magnify">
    <icon-base width="24" height="24" color="#ffffff" class="magnify-button__icon" aria-label="magnify image">
      <magnify-spyglass-icon/>
    </icon-base>
  </button>
</template>

<script>
import IconBase from '@/components/iconography/IconBase.vue';
import MagnifySpyglassIcon from '@/components/iconography/MagnifySpyglassIcon.vue';

export default {
  name: 'magnify-button',
  components: {
    IconBase,
    MagnifySpyglassIcon,
  },
  methods: {
    magnify() {
      this.$emit('magnify');
    },
  },
};
</script>

<style lang="scss" scoped>
.magnify-button {
  border: 1px solid $martech-border;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 48px;
  width: 48px;
  padding: 0;
  cursor: pointer;

  &__icon {
    display: block;
    margin: auto;
  }

  &:focus {
    outline: none;
    box-shadow: 0px;
  }
}
</style>
