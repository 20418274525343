<template>
  <div class="grid-placeholder" :data-testid="componentID()">
    <div v-for="row in rowCount" :key="row" class="row">
      <full-art-placeholder
        v-for="p in placeholderCount"
        :key="p"
        class="placeholder-item"
        placeholder-height="305px"/>
    </div>
  </div>
</template>

<script>
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';

export default {
  name: 'placeholder-grid',
  components: {
    FullArtPlaceholder,
  },
  props: {
    placeholderCount: {
      type: Number,
      required: false,
      default: 4,
    },
    rowCount: {
      type: Number,
      required: false,
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-placeholder {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    padding-bottom: $martech-spacer-4;
    width: 100%;
    flex-direction: column;

    &:last-of-type {
      padding-bottom: 0;
    }

    @include breakpoint(768) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .placeholder-item {
    padding-bottom: $martech-spacer-4;

    &:last-of-type {
      padding-bottom: 0;
    }

     @include breakpoint(768) {
      width: 50%;
      padding-bottom: 0;

      &:nth-of-type(1), &:nth-of-type(2) {
        padding-bottom: $martech-spacer-4;
      }

      &:nth-of-type(1), &:nth-of-type(3) {
        padding-right: $martech-spacer-4;
      }
    }

    @include breakpoint(1200) {
      width: 25%;
      padding-right: $martech-spacer-4;
      &:nth-of-type(1), &:nth-of-type(2) {
        padding-bottom: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}
</style>
