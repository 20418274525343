<template>
  <div class="subscribe-confirmation-toast shadow" :class="[changeTheme, {'mobile' : mobileView}]" :data-testid="componentID()">
    <!--eslint-disable-next-line max-len-->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 24.33" class="subscribe-checkmark">
      <g data-name="Layer 2">
        <path
          d="M29.76.29a.72.72 0 011.15 0l.87.8a1 1 0 01.22.65.78.78 0 01-.22.58L10.06 24a.86.86 0 01-.61.29.89.89 0 01-.62-.29L.22 15.42a.76.76 0 01-.22-.58 1 1 0 01.22-.65l.87-.8a.68.68 0 01.58-.29.68.68 0 01.57.29l7.17 7.17z"
          fill="currentColor"
          data-name="Layer 1"/>
      </g>
    </svg>
    <p role="alert" aria-live="assertive">
      {{ confirmationText }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'subscribe-confirmation-toast',
  props: {
    theme: {
      type: String,
      required: false,
      default: 'newsletter-light',
    },
    confirmationText: {
      type: String,
      required: false,
      default: 'Got it! Keep an eye on your inbox.',
    },
    mobileView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    changeTheme() {
      if (this.theme === 'dark') {
        return 'newsletter-dark';
      }

      return 'newsletter-light';
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe-confirmation-toast {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $martech-mint;
  flex-direction: column;
  padding: $martech-spacer-5 $martech-spacer-6;

   @include breakpoint(1024) {
        flex-direction: row;
        padding: 0;
    }

  &.mobile {
    display: flex;
    padding: 0;

    .subscribe-checkmark {
      width: 24px;
      padding-bottom: $martech-spacer-1;
    }

    p {
      font-size: $martech-type-18;
      line-height: $martech-type-24;
      padding-left: 0;
    }
  }

  .subscribe-checkmark {
    width: 34px;
    padding-bottom: $martech-spacer-3;

    @include breakpoint(1024) {
        width: 48px;
        padding-bottom: 0;
    }
  }

  p {
    font-family: $martech-display-semi-condensed;
    font-size: $martech-type-26;
    line-height: $martech-type-30;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0;
    padding-left: $martech-spacer-3;
    font-weight: $martech-weight-semibold;
    text-align: center;

    @include breakpoint(1024) {
        text-align: left;
    }
  }

  &.newsletter-light {
    background-color: $martech-white;

    p {
      color: $martech-black;
    }
  }

  &.newsletter-dark {
    background-color: rgba(0, 0, 0, 0.9);
    p {
      color: $martech-white;
    }
  }
}
</style>
