<template>
  <delay-load @visible="onVisible">
    <div class="placeholder" :style="[{'height': placeholderHeight}, {'width': placeholderWidth}]" :data-testid="componentID()">
      <div class="image" :class="[{ halfpage: halfpage }, { 'medium-rect': mediumRectangle }, { leaderboard: leaderboard }, {'shimmer' : visible }]" :style="[{'min-height' : imageHeight}, {'height' : imageHeight}]">
        <div v-if="video" class="video">
          <video-play-icon/>
        </div>
      </div>
      <div v-if="includeText" class="text-long" :class="{'shimmer' : visible }"/>
      <div v-if="includeText" class="text-short" :class="{'shimmer' : visible }"/>
      <div v-if="author" class="author-group">
        <div class="photo" :class="{'shimmer' : visible }"/>
        <div class="group">
          <div class="name" :class="{'shimmer' : visible }"/>
          <div class="date" :class="{'shimmer' : visible }"/>
        </div>
      </div>
    </div>
  </delay-load>
</template>

<script>
import DelayLoad from '@/components/DelayLoad.vue';
import VideoPlayIcon from '@/components/iconography/VideoPlayIcon.vue';

export default {
  name: 'full-art-placeholder',
  components: {
    VideoPlayIcon,
    DelayLoad,
  },
  props: {
    halfpage: {
      type: Boolean,
      required: false,
      default: false,
      note: 'This is used for a half page ad',
    },
    mediumRectangle: {
      type: Boolean,
      required: false,
      default: false,
      note: 'This is used for a medium rectangle ad',
    },
    leaderboard: {
      type: Boolean,
      required: false,
      default: false,
      note: 'This is used for a leaderboard ad',
    },
    video: {
      type: Boolean,
      required: false,
      default: false,
      note: 'This is used to display a video play button for a video embed',
    },
    author: {
      type: Boolean,
      required: false,
      default: false,
      note: 'this is used to display an author placeholder for content cards with authors',
    },
    includeText: {
      type: Boolean,
      required: false,
      default: true,
      note: 'This is used to hide or show "Text" placeholders depending on card type',
    },
    imageHeight: {
      type: String,
      required: false,
      default: '',
    },
    placeholderHeight: {
      type: String,
      required: false,
      default: '',
    },
    placeholderWidth: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onVisible() {
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  .image {
    border-radius: $martech-radius-medium;
    width: 100%;
    height: 100%;
    min-height: 116px;
    position: relative;

    &.halfpage {
      height: 600px;
      width: 300px;
    }

    &.medium-rect {
      height: 300px;
      width: 250px;
    }

    &.leaderboard {
      height: 50px;
      width: 320px;
      @include breakpoint(1024) {
        height: 90px;
        width: 728px;
      }
    }

    .video {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text-long,
  .text-short {
    border-radius: $martech-radius-default;
  }

  .text-long {
    width: 80%;
    height: 15px;
    margin-bottom: 0.75rem;
    margin-top: $martech-spacer-3;
  }

  .text-short {
    width: 50%;
    height: 10px;
  }

  .author-group {
    display: flex;
    padding-top: $martech-spacer-3;
    align-items: flex-end;
    .photo {
      width: 52px;
      height: 52px;
      border-radius: 50%;
    }

    .group {
      padding-left: $martech-spacer-2;
      padding-bottom: $martech-spacer-1;
    }

    .name {
      height: 10px;
      width: 100px;
      border-radius: 8px;
    }

    .date {
      height: 8px;
      width: 50px;
      border-radius: 8px;
      margin-top: $martech-spacer-2;
    }
  }
}
</style>
