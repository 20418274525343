<template>
  <cfb-page-template title="Latest Articles" :controls="templateControls">
    <template v-slot:hero>
      <hero v-if="!hideHero" :loading="heroLoading" :data="heroData"/>
    </template>
    <template v-slot:large-column>
      <large-full-art-feature vertical="magic" highlighted :rows="3" large-feature :card-height="isPhone ? '332px' : '450px'"/>
    </template>
    <template v-slot:small-column>
      <format-nav-list-view/>
      <latest-slim-article-widget vertical="magic" :rows="5"/>
      <base-button btn-style="martech-cfb-orange-base" btn-size="martech-medium" class="article-button" @clicked="goToAllArticles">
        View All Articles
      </base-button>
    </template>
    <template v-slot:promo-one>
      <div class="promo">
        <promos-banner-vertical :vertical="'Homepage'" :domains="siteConfig('global.domain')" :placement="'Homepage'" promo-size="native full width"/>
      </div>
    </template>
    <template v-slot:large-section>
      <div class="cfb-large-section">
        <div class="best-of">
          <full-art-grid-widget vertical="magic" title="Editor's Picks" :rows="5" large-feature editors-choice see-all-url="/magic-the-gathering/articles"/>
        </div>
      </div>
    </template>
    <template v-slot:light-section>
      <div class="latest-events">
        <latest-events vertical-name="magic" placement="homepage" :rows="7" :see-more="eventsLandingLink" show-deck-vault/>
      </div>
    </template>
    <template v-slot:dark-section-three>
      <div class="cfb-header" aria-label="Latest Videos">
        <h2 class="martech-heading martech-semi-condensed martech-text-uppercase martech-text-semibold" aria-hidden="true">
          Latest Videos
        </h2>
        <base-link class="martech-see-all-link" :link-url="youtubeLink" url-target="_blank" new-window>
          See All
        </base-link>
      </div>
      <youtube-rss-feed channel-id="UCVmqfvWsKeFaMGY68CbcVVQ"/>
    </template>
  </cfb-page-template>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { set } from '@vueuse/core';
import Api from '@/api/api';

import FullArtGridWidget from '@/components/article-widgets/cfb/FullArtGridWidget.vue';
import LargeFullArtFeature from '@/components/article-widgets/cfb/LargeFullArtFeatures.vue';
import LatestSlimArticleWidget from '@/components/article-widgets/cfb/LatestSlimArticleWidget.vue';
import LatestEvents from '@/components/deck-widgets/LatestEvents.vue';
import FormatNavListView from '@/components/navigation-elements/cfb/FormatNavListView.vue';
import CfbPageTemplate from '@/components/templates/CfbPageTemplate.vue';
import YoutubeRssFeed from '@/components/video-widgets/YoutubeRssFeed.vue';
import useSiteConfig from '@/use/useSiteConfig';

import {
  BaseButton,
  BaseLink,
  PromosBannerVertical,
  useDeviceType,
  Hero
} from '@tcgplayer/martech-components';

const { isPhone } = useDeviceType();
const eventsLandingLink = 'magic-the-gathering/events';
const youtubeLink = process.env.VUE_APP_TCGPLAYER_YOUTUBE;
const router = useRouter();
const { siteConfig } = useSiteConfig();

const templateControls = {
  h1: true,
  h2: false,
};

const heroData = ref();
const heroLoading = ref(true);
const hideHero = ref(false);

Api.getVerticalHero({ domain: siteConfig('global.domain'), excludeVerticals: [ 'ChannelFireball Magic', 'Magic', 'TCGplayer Edge', 'Edge' ] })
  .then((res) => {
    set(heroData, res?.data?.result);
    set(heroLoading, false);
  }).catch(() => {
    set(heroLoading, false);
    set(hideHero, true);
  });

function goToAllArticles() {
  router.push({ name: 'Magic: The Gathering Articles' });
}
</script>

<style lang="scss" scoped>
.cfb-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $martech-spacer-4;
  color: $martech-white;

  .martech-see-all-link {
    :deep(.martech-base-link) {
      color: $martech-white;
      margin-left: $martech-spacer-2;
    }

    &:after {
      filter: brightness(0) invert(1);
    }
  }
}

.article-button {
  :deep(.martech-button) {
    width: 100%;
  }
}

.promo {
  padding-bottom: $martech-spacer-4;
}

.cfb-large-section {
  margin: $martech-spacer-6 0;
}

.best-of, .latest-events {
  margin-bottom: $martech-spacer-4;
}

:deep(.heading) {
  margin-top: $martech-spacer-4;
}
</style>
