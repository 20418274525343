import { computed, toValue } from 'vue';
import type { Ref } from 'vue';

export default function useCommanderColorLabels(color: Ref|string) {
  const commanderColorLabels = computed(() => {
    const mapping: { [key: string]: string; } = {
      colorless: 'Colorless',
      black: 'Monoblack',
      blue: 'Monoblue',
      green: 'Monogreen',
      red: 'Monored',
      white: 'Monowhite',
      // Two Color Combos
      'blue|white': 'Azorius',
      'red|white': 'Boros',
      'black|blue': 'Dimir',
      'black|green': 'Golgari',
      'green|red': 'Gruul',
      'blue|red': 'Izzet',
      'black|white': 'Orzhov',
      'black|red': 'Rakdos',
      'green|white': 'Selesnya',
      'blue|green': 'Simic',
      // Three Color Combos
      'black|green|white': 'Abzan',
      'blue|green|white': 'Bant',
      'black|blue|white': 'Esper',
      'black|blue|red': 'Grixis',
      'blue|red|white': 'Jeskai',
      'black|green|red': 'Jund',
      'black|red|white|': 'Mardu',
      'green|red|white': 'Naya',
      'black|blue|green': 'Sultai',
      'blue|green|red': 'Temur',
      // Four Color Combos
      'black|blue|green|red': '4C Black/blue/green/red',
      'black|blue|green|white': '4C Black/blue/green/white',
      'black|blue|red|white': '4C Black/blue/red/white',
      'black|green|red|white': '4C Black/green/red/white',
      'blue|green|red|white': '4C Blue/green/red/white',
      // Five Color Combo
      'black|blue|green|red|white': 'Five-color',
    };

    return mapping[toValue(color)] || toValue(color);
  });

  return { commanderColorLabels };
}