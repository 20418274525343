<template>
  <button
    class="close-button"
    type="button"
    data-toggle="collapse"
    :data-target="ariaTarget"
    aria-label="close"
    :data-testid="componentID()"
    @click="toggleClosed">
    <icon-base icon-name="close" icon-color="black" width="15" height="15">
      <close-icon/>
    </icon-base>
  </button>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'close-toggle-button',
  components: {
    IconBase: defineAsyncComponent(() => import('@/components/iconography/IconBase.vue')),
    CloseIcon: defineAsyncComponent(() => import('@/components/iconography/CloseIcon.vue')),
  },
  props: {
    ariaTarget: {
      type: String,
      default: '',
      required: false,
    },
  },
  methods: {
    toggleClosed() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  background: transparent;
  border: 0;
  &:hover,
  &:focus {
    background-color: transparent;
    outline: 0;
  }
}
</style>
