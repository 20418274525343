<template>
  <div>
    <!-- eslint-disable -->
    <component is="script" v-html="jsonld" type="application/ld+json"/>
    <!-- eslint-enable -->
  </div>
</template>

<script>

export default {
  name: 'article-schema',
  props: {
    headline: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    datePublished: {
      type: String,
      required: true,
    },
    dateModified: {
      type: String,
      required: true,
    },
    authorName: {
      type: String,
      required: true,
    },
    authorUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      jsonld: '',
    };
  },
  created() {
    const datePublished = new Date(this.datePublished);
    const dateModified = new Date(this.dateModified || this.datePublished);

    this.jsonld = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': 'https://google.com/article',
      },
      headline: this.headline,
      image: this.images,
      datePublished: datePublished.toISOString(),
      dateModified: dateModified.toISOString(),
      author: {
        '@type': 'Person',
        name: this.authorName,
        url: this.authorUrl,
      },
      publisher: {
        '@type': 'Organization',
        name: process.env.VUE_APP_TITLE,
        logo: {
          '@type': 'ImageObject',
          url: process.env.VUE_APP_LOGO,
        },
      },
    });
  },
};
</script>
