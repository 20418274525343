<template>
  <div class="right-text-overlay" :data-testid="componentID()">
    <p class="martech-text-sm martech-text-uppercase">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'right-text-overlay',
  props: {
    text: {
      type: String,
      default: 'New',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.right-text-overlay {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  background-color: $martech-pink;
  height: 1.5rem;
  width: 4rem;
  border-top-right-radius: $martech-radius-medium;
  border-bottom-left-radius: $martech-radius-default;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    text-align: center;
    color: $martech-white;
    font-weight: $martech-weight-bold;
  }
}
</style>
