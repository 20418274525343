<template>
  <!--eslint-disable-next-line-->
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64" :data-testid="componentID()"><g data-name="Layer 2"><g data-name="Layer 1"><circle cx="32" cy="32" r="32" fill="#fff" opacity=".8"/><path d="M28.71 43c-1.2.79-2.71-.22-2.71-1.89V22.4c0-1.65 1.5-2.67 2.71-1.88l13.36 9.61a2.38 2.38 0 010 3.77z"/></g></g></svg>
</template>

<script>
export default {
  name: 'video-play-icon',
};
</script>
