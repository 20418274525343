<template>
  <delay-load @visible="onVisible">
    <div v-if="loaded && articles.length > 2" class="popular-content" :class="{'mobile-scroll': mobileScroll}" :data-testid="componentID()">
      <div class="header">
        <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          Popular Content
        </h2>
      </div>
      <box-view
        v-if="view === 'box'"
        :articles="articles"
        :show-author="showAuthor"
        :show-date="showDate"/>
      <box-view
        v-if="view === 'box-large'"
        :articles="articles"
        :large="true"
        :show-author="true"
        :show-date="true"
        :show-format="true"
        :show-read-time="true"/>
      <image-view v-if="view === 'image'" :articles="articles" :on-click="onClick" :mobile-scroll="mobileScroll"/>
    </div>
  </delay-load>
</template>

<script>
import delve from 'dlv';
import Api from '@/api/api';
import ImageView from '@/components/article-list/ImageView.vue';
import BoxView from '@/components/article-list/BoxView.vue';
import DelayLoad from '@/components/DelayLoad.vue';

export default {
  name: 'popular-content',
  components: {
    BoxView,
    ImageView,
    DelayLoad,
  },
  props: {
    vertical: {
      type: String,
      required: false,
      default: '',
    },
    bucketSize: {
      type: String,
      required: false,
      default: 'month',
    },
    offset: {
      type: Number,
      required: false,
      default: 0,
    },
    rows: {
      type: Number,
      required: false,
      default: 4,
    },
    exclude: {
      type: String,
      required: false,
      default: '',
    },
    view: {
      type: String,
      required: false,
      default: 'image',
    },
    placement: {
      type: String,
      required: false,
      default: 'Article Page',
    },
    mobileScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      articles: [],
    };
  },
  methods: {
    async onVisible() {
      const results = await Api.getTrendingContent({
        bucketSize: this.bucketSize, game: this.vertical, offset: this.offset, rows: this.rows, exclude: this.exclude,
      }).then(this.handleResponse);

      this.articles = results;
      this.loaded = true;

      if (this.articles.length < 3) {
        this.$emit('no-data');
      } else {
        this.$emit('loaded');
      }
    },
    handleResponse(response) {
      return delve(response, 'data.results', delve(response, 'data.result', [])) || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-content {
  width: 100%;
  padding: 0 $martech-spacer-3;

  &.mobile-scroll {
    padding: 0;

    .header {
      padding-left: $martech-spacer-3;

      @include breakpoint(1200) {
        padding-left: 0;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $martech-spacer-2;
    min-height: 35px;

    @include breakpoint(1200) {
      padding-left: 0;
    }
  }
}
</style>
