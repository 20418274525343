<template>
  <delay-load :data-testid="componentID('grid-widget')" @visible="onVisible">
    <template v-if="showComponent">
      <div v-if="title" class="header" :aria-label="title">
        <h2 aria-hidden="true" class="martech-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          {{ title }}
        </h2>
        <base-link v-if="showLink" class="martech-see-all-link" :link-url="seeAllUrl" target="_blank" new-window>
          See All
        </base-link>
      </div>
      <div class="article-grid" :class="{ 'large-feature': largeFeature }">
        <!--Placeholder-->
        <template v-if="loading">
          <div v-for="(p, index) in rows" :key="index" class="grid-item placeholder">
            <full-art-placeholder :include-text="false"/>
          </div>
        </template>
        <!--Content Card-->
        <template v-else>
          <div v-for="article in articles" :key="article" class="grid-item">
            <large-square-full-card :article-data="article" :show-large-author="false" :show-teaser="false"/>
          </div>
        </template>
      </div>
    </template>
  </delay-load>
</template>

<script setup>
import Api from '@/api/api';
import DelayLoad from '@/components/DelayLoad.vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import { BaseLink, LargeSquareFullCard } from '@tcgplayer/martech-components';
import { get, set } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps({
  vertical: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  highlighted: {
    type: Boolean,
    required: false,
    default: null,
  },
  editorsChoice: {
    type: Boolean,
    required: false,
    default: null,
  },
  featured: {
    type: Boolean,
    required: false,
    default: null,
  },
  rows: {
    type: Number,
    required: false,
    default: 1,
  },
  offset: {
    type: Number,
    required: false,
    default: 0,
  },
  uniqueResultsField: {
    type: String,
    required: false,
    default: 'uuid',
  },
  largeFeature: {
    type: Boolean,
    required: false,
    default: false,
  },
  showLink: {
    type: Boolean,
    required: false,
    default: true,
  },
  seeAllUrl: {
    type: String,
    required: false,
    default: '',
  },
  tagName: {
    type: String,
    required: false,
    default: '',
  },
  seriesName: {
    type: String,
    required: false,
    default: '',
  },
  formats: {
    type: String,
    required: false,
    default: '',
  },
  omitFormats: {
    type: String,
    required: false,
    default: '',
  },
});

const articles = ref([]);
const loading = ref(true);
const showComponent = ref(true);

async function onVisible() {
  if (props.tagName || props.seriesName) {
    const params = {
      verticals: props.vertical,
      tags: props.tagName,
      series: props.seriesName,
      contentType: [ 'Article', 'Video', 'Podcast', ],
      offset: props.offset,
      rows: props.rows,
      featured: props.featured,
      highlighted: props.highlighted,
      uniquedResultsField: props.uniqueResultsField,
    };

    if (props.formats.length) params.formats = props.formats.join('|');
    else if (props.omitFormats.length) params.omitFormats = props.omitFormats.join('|');

    await Api.getTaggedContent(params).then((res) => {
      set(articles, res?.data?.result || []);
      set(loading, false);
      // Hide component if full component isnt filled out but articles are retrieved
      if (get(articles).length < props.rows) {
        set(showComponent, false);
      }
    });
  } else {
    await Api.getVerticalPage({
      verticals: props.vertical,
      contentType: props.contentType,
      offset: props.offset,
      rows: props.rows,
      featured: props.featured,
      highlighted: props.highlighted,
      editorsChoice: props.editorsChoice,
      uniqueResultsField: props.uniqueResultsField,
    }).then((res) => {
      set(articles, res?.data?.result || []);
      set(loading, false);
      // Hide component if full component isnt filled out but articles are retrieved
      if (get(articles).length < 5) {
        set(showComponent, false);
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.article-grid {
  display: flex;
  flex-direction: column;
  grid-gap: $martech-spacer-3;

  @include breakpoint(1024) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: $martech-spacer-4;
  }

  @include breakpoint(1200) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  @include breakpoint(1500) {
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  }

  &.large-feature {
    .grid-item {
      width: 100%;

      &:nth-child(1) {
        @include breakpoint(1024) {
          grid-column: span 2;
          grid-row: span 2;
          max-height: 497px;
          height: 100%;
        }

        @include breakpoint(1500) {
          grid-column: span 3;
        }
      }
    }
  }

  .grid-item {
    grid-column: span 2;
    height: 235px;
  }

  .placeholder {
    :deep(.delay-wrapper) {
      height: 100%;
    }

    min-height: 235px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $martech-spacer-3;

  .martech-see-all-link {
    :deep(.martech-base-link) {
      margin-left: $martech-spacer-2;
    }
  }
}
</style>
