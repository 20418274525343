<template>
  <div class="grid" tabindex="-1" :class="{'mobile-scroll' : mobileScroll && deviceType === 'mobile'}" :data-testid="componentID()">
    <div v-for="(n, key) in grid" :key="key" class="grid-row" tabindex="-1" :style="{'grid-template-columns':`repeat(${gridRows}, 1fr)`}">
      <div v-for="(col, index) in n" :key="index" class="grid-col" tabindex="-1" :class="gridColumnClasses">
        <full-art-placeholder v-if="loading" class="item"/>
        <promos-banner-vertical v-else-if="col && col.promo && deviceType === 'desktop' && includePromo" :promo-size="col.promo.size" :domains="siteConfig('global.domain')">
          <grid-card
            :key="col.canonicalURL"
            :article-data="col"
            :allow-author-link="allowAuthorLink"
            :show-vertical-link="showVerticalLink"
            :on-click="onClick"/>
        </promos-banner-vertical>
        <grid-card
          v-else-if="col"
          :key="col.canonicalURL"
          :article-data="col"
          :show-date="showDate"
          :allow-author-link="allowAuthorLink"
          :min-event-card-height="minEventCardHeight"
          :show-overlay="showCardOverlay"
          :show-vertical-link="showVerticalLink"
          :on-click="onClick"/>
      </div>
    </div>
  </div>
</template>

<script>
import { PromosBannerVertical } from '@tcgplayer/martech-components';
import GridCard from '@/components/card-elements/GridCard.vue';
import deviceType from '@/mixins/deviceType';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';

export default {
  name: 'content-card-grid',
  components: {
    GridCard,
    PromosBannerVertical,
    FullArtPlaceholder,
  },
  mixins: [ deviceType ],
  props: {
    articles: {
      type: Array,
      required: true,
      default: () => [],
    },
    filter: {
      type: Function,
      required: false,
      default: article => true,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
    allowAuthorLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    showVerticalLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDate: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCardOverlay: {
      type: Boolean,
      required: false,
      default: true,
    },
    sortable: {
      type: Boolean,
      required: false,
      default: false,
    },
    columnCount: {
      type: Number,
      default: 4,
      required: false,
    },
    gridRowsCount: {
      type: Number,
      default: null,
      required: false,
      note: 'defines a different count for the grid template rows styling other than column count if needed',
    },
    rowCount: {
      type: Number,
      required: false,
      default: 4,
    },
    maintainSize: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    includePromo: {
      type: Boolean,
      required: false,
      default: true,
    },
    minEventCardHeight: {
      type: String,
      default: '193px',
      required: false,
    },
  },
  data() {
    return {
      grid: [],
    };
  },
  computed: {
    loading() {
      return !this.articles.length;
    },
    gridRows() {
      return this.gridRowsCount || this.columnCount;
    },
    gridColumnClasses() {
      let classes = '';
      switch (this.columnCount) {
        case 2:
          classes += 'two-col';
          break;
        case 3:
          classes += 'three-col';
          break;
        default:
          classes += 'four-col';
          if (this.columnCount === 8) classes += ' four-row';
          break;
      }

      return classes;
    },
  },
  watch: {
    // When promotions get filtered out and replaced by articles we need to force an update so it displays
    articles: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  beforeUpdate() {
    this.createGrid();
  },
  created() {
    this.createGrid();
  },
  methods: {
    createGrid() {
      this.grid = [];
      let y = 0;

      const articles = this.articles.filter(this.filter);
      const totalCount = this.loading ? this.columnCount * this.rowCount : articles.length;

      if (!Array.isArray(this.grid[y])) {
        this.grid[y] = [];
      }

      for (let i = 0; i < totalCount && y < this.rowCount; i++) {
        if (this.grid[y].length >= this.columnCount) {
          y++;
          this.grid[y] = [];
        }

        if (y < this.rowCount) {
          if (this.loading) {
            this.grid[y].push('loading');
          } else {
            this.grid[y].push(articles[i]);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  width: 100%;
}

.grid-row {
  display: flex;
  flex-direction: column;
  padding-bottom: $martech-spacer-3;

  &:last-of-type  {
    padding-bottom: 0;
  }

  @include breakpoint(768) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  @include breakpoint(1200) {
    display: grid;
    padding-bottom: $martech-spacer-3;
    grid-gap: $martech-spacer-3;
  }
}

.grid-col {
  width: 100%;
  flex: 0 100%;
  padding-bottom: $martech-spacer-3;

  &:last-of-type {
    padding-bottom: 0;
  }

  @include breakpoint(768) {
    padding-bottom: 0;
    padding-right: $martech-spacer-3;

    &:last-of-type {
      padding-right: 0;
    }
  }

  &.two-col {
    @include breakpoint(768) {
      flex: 0 50%;
      width: 50%;
    }

    @include breakpoint(1200) {
      width: 100%;
      padding-right: 0;
    }
  }

  &.three-col {
    @include breakpoint(768) {
      flex: 0 50%;
      width: 50%;
    }

    @include breakpoint(1024) {
      flex: 0 33.333%;
      width: 33.333%;
    }

    @include breakpoint(1200) {
      width: 100%;
      padding-right: 0;
    }
  }

  &.four-col {
     @include breakpoint(768) {
      flex: 0 50%;
      width: 50%;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-bottom: $martech-spacer-3;
      }

      &:nth-of-type(2) {
        padding-right: 0;
      }

      &.four-row {
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
          padding-bottom: $martech-spacer-3;
        }

        &:nth-of-type(4),
        &:nth-of-type(6) {
          padding-right: 0;
        }
      }
    }

    @include breakpoint(1200) {
      width: 100%;
      padding-right: 0;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-bottom: 0;
      }

      &.four-row {
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
