import { createStore } from 'vuex';
import promosModule from './modules/promos';
import advancedDeckSearchModule from './modules/advancedDeckSearch';
import advancedDeckSearchSortModule from './modules/sort/advancedDeckSearch';
import articleModule from './modules/article';
import contentFilterModule from './modules/contentFilter';
import contentSortModule from './modules/contentSort';
import eventsSortModule from './modules/eventsSort';
import gtmModule from './modules/gtm';
import searchModule from './modules/search';
import sidebarModule from './modules/sidebar';
import subscriptionModule from './modules/subscription';
import alertModule from './modules/alert';

// IMPORTANT: When adding a store that uses @/lib/config directly or indirectly
//            you MUST add a reset action and call it from main_*.js
export const modules = {
  promos: promosModule,
  advancedDeckSearch: advancedDeckSearchModule,
  advancedDeckSearchSort: advancedDeckSearchSortModule,
  article: articleModule,
  contentFilter: contentFilterModule,
  contentSort: contentSortModule,
  eventsSort: eventsSortModule,
  gtm: gtmModule,
  search: searchModule,
  sidebar: sidebarModule,
  subscription: subscriptionModule,
  alert: alertModule,
};

const store = createStore({ modules });
export default store;
