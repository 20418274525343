import delve from 'dlv';

export default {
  created() {
    if (this.articleData) {
      this.render = delve(this.articleData, 'cardData', null) !== null;

      this.articleDate = delve(this.articleData, 'date', new Date().toDateString());
      this.canonicalURL = delve(this.articleData, 'canonicalURL', '');
      this.cardID = delve(this.articleData, 'cardID', null);
      this.cardType = delve(this.articleData, 'cardData.cardType', '');
      this.color = delve(this.articleData, 'cardData.color', '');
      this.contentType = delve(this.articleData, 'contentType', 'article');
      this.displayName = delve(this.articleData, 'cardData.cardName', this.title || '');
      this.imageAltName = delve(this.articleData, 'imageAltName', this.title || '');
      this.manaCost = delve(this.articleData, 'cardData.manaCost', '');
      this.cost = delve(this.articleData, 'cardData.cost', '');
      this.marketPrice = delve(this.articleData, 'cardData.marketPrice', null);
      this.rarity = delve(this.articleData, 'cardData.rarity', '');
      this.setName = delve(this.articleData, 'cardData.setName', '');
      this.vertical = delve(this.articleData, 'vertical', null);
    }
  },
};
