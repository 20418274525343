export default {
  methods: {
    getCookie(key) {
      const cookieName = `${process.env.VUE_APP_NAME}_${key}=`;
      const cookieValues = document.cookie.split(';');
      for (let i = 0; i < cookieValues.length; i++) {
        let value = cookieValues[i];
        while (value.charAt(0) === ' ') {
          value = value.substring(1);
        }
        if (value.indexOf(cookieName) === 0) {
          return value.substring(cookieName.length, value.length);
        }
      }
      return '';
    },
    setCookie(key, value, domain, expireDays) {
      const d = new Date();
      d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
      document.cookie = `${process.env.VUE_APP_NAME}_${key}=${value};domain=${domain};expires=${d.toUTCString()};path=/`;
    },
    deleteCookie(key, domain) {
      document.cookie = `${process.env.VUE_APP_NAME}_${key}=;domain=${domain};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    },
  },
};
