<template>
  <div id="grid-wrapper" :data-testid="componentID()">
    <slot name="header"/>
    <slot name="main"/>
    <slot name="footer"/>
  </div>
</template>

<script>
export default {
  name: 'grid-wrapper',
};
</script>

<style lang="scss" scoped>
#grid-wrapper {
    display: grid;
    position: relative;
    grid-template-columns: repeat(11, 1fr);
    grid-auto-rows: auto;
    max-width: 100%;
    margin: 0 auto;
}

:deep(header) {
    grid-column: 1/12;
    grid-row: 1/12;
}
:deep(main) {
    grid-column: 1/12;
}

:deep(footer) {
    grid-column: 1/12;
}
</style>
