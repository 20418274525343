import delve from 'dlv';
import magic from '@/lib/magic';

export default {
  computed: {
    canonical() {
      return delve(this.article, 'canonicalURL', '').replace(delve(this.article, 'title'), encodeURIComponent(delve(this.article, 'title')));
    },
    eventLevelStars() {
      return magic.getEventLevelNumber(this.article.eventLevel) || 0;
    },
    formattedDate() {
      return this.date.replaceAll('-', '/');
    },
  },
  async created() {
    if (this.article) {
      this.render = true;

      this.date = delve(this.article, 'date', '') || delve(this.article, 'eventDate', new Date().toDateString());
      this.format = delve(this.article, 'format', '') || delve(this.article, 'deckData.format') || delve(this.article, 'eventData.format');
    }
  },
  methods: {
    errorImageFallback(event) {
      event.target.src = this.fallbackImage;
    },
  },
};
