<template>
  <div class="loading-state" :data-testid="componentID()">
    <mint-loader/>
    <p v-show="loadingText" class="martech-heading">
      {{ loadingText }}
    </p>
  </div>
</template>

<script>
import MintLoader from '@/components/elements/MintLoader.vue';

export default {
  name: 'page-loader',
  components: {
    MintLoader,
  },
  props: {
    loadingText: {
      type: String,
      required: false,
      default: 'Loading...',
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  width: 100%;

  p {
    font-weight: $martech-weight-medium;
    text-align: center;
    padding-top: $martech-spacer-2;
    letter-spacing: 0.2px;
  }
}
</style>
