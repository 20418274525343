<template>
  <div class="social-media-buttons" :data-testid="componentID()">
    <a :href="`${facebookUrl}`" target="_blank" rel="noopener noreferrer" class="social-icon" :class="{'orange-social' : siteConfig('footer.orangeBrand')}" aria-label="TCGplayer Facebook">
      <facebook-icon/>
    </a>
    <a :href="instagramUrl" target="_blank" rel="noopener noreferrer" class="social-icon" :class="{'orange-social' : siteConfig('footer.orangeBrand')}" aria-label="TCGplayer Instagram">
      <instagram-icon/>
    </a>
    <a :href="`${twitterUrl}`" target="_blank" rel="noopener noreferrer" class="social-icon" :class="{'orange-social' : siteConfig('footer.orangeBrand')}" aria-label="TCGplayer Twitter">
      <twitter-icon/>
    </a>
    <a :href="youtubeUrl" target="_blank" rel="noopener noreferrer" class="social-icon" :class="{'orange-social' : siteConfig('footer.orangeBrand')}" aria-label="TCGplayer Youtube">
      <youtube-icon/>
    </a>
  </div>
</template>

<script>
import FacebookIcon from '@/components/iconography/social-media/FacebookIcon.vue';
import InstagramIcon from '@/components/iconography/social-media/InstagramIcon.vue';
import TwitterIcon from '@/components/iconography/social-media/TwitterIcon.vue';
import YoutubeIcon from '@/components/iconography/social-media/YoutubeIcon.vue';
import { VerticalHelpers as verts } from '@tcgplayer/martech-components';

export default {
  name: 'social-media-buttons',
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    YoutubeIcon,
  },
  data() {
    return {
      youtubeUrl: `${process.env.VUE_APP_TCGPLAYER_YOUTUBE}`,
      facebookUrl: `${process.env.VUE_APP_TCGPLAYER_FACEBOOK}`,
      twitterUrl: `${process.env.VUE_APP_TCGPLAYER_TWITTER}`,
      instagramUrl: `${process.env.VUE_APP_TCGPLAYER_INSTAGRAM}`,
    };
  },
  computed: {
    socialTag() {
      return verts.socialLink(this.$route.meta.social, 'TCGplayer');
    },
  },
};
</script>

<style lang="scss" scoped>
.social-media-buttons {
  display: flex;

  .social-icon {
    color: $martech-text-subdued;
    padding-right: $martech-spacer-3;
    transition: color 300ms ease-in-out;

    &.orange-social {
      color: $martech-cfb-orange-base;

      &:hover {
        color: $martech-cfb-orange-dark;
      }
    }

    &:hover {
      color: $martech-blue-hover;
    }
  }
}
</style>
