<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" id="hamburger-icon" viewBox="0 0 28 23.5" :data-testid="componentID()"><g data-name="Layer 2"><path d="M.75 3.5A.76.76 0 010 2.75v-2A.76.76 0 01.75 0h26.5a.76.76 0 01.75.75v2a.76.76 0 01-.75.75zm0 10a.76.76 0 01-.75-.75v-2A.76.76 0 01.75 10h26.5a.76.76 0 01.75.75v2a.76.76 0 01-.75.75zm0 10a.76.76 0 01-.75-.75v-2A.76.76 0 01.75 20h26.5a.76.76 0 01.75.75v2a.76.76 0 01-.75.75z" fill="currentColor" data-name="Layer 1"/></g></svg>
</template>

<script>
export default {
  name: 'hamburger-icon',
};
</script>

<style lang="scss" scoped>
#hamburger-icon {
  width: 20px;
}
</style>
