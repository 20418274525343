<template>
  <!--eslint-disable-next-line vue/no-v-model-argument-->
  <focus-trap v-model:active="isActive">
    <div v-if="deviceType === 'desktop'" class="magnify-image-container" :data-testid="componentID()" role="dialog" tabindex="-1">
      <martech-card-spotlight
        :data="cardData"
        :loaded="loaded"
        :is-flipped="isFlipped"
        :card-product-url="cardProductUrl"
        :card-id="cardId"
        :is-product="isProduct"
        class="magnified-popup"
        default-tab="details"
        width="800px"
        :image-dimensions="deviceType === 'desktop' ? { width: '314px', height: '439px', } : { width: '200px', height: '280px', }"
        @amplitude-event="buyEvent"
        @flip="$emit('flip')"/>
      <screen-overlay @close-overlay="close"/>
    </div>
  </focus-trap>
</template>

<script>
import deviceType from '@/mixins/deviceType';
import amplitudeEvent from '@tcgplayer/amplitude';
import { MartechCardSpotlight, ScreenOverlay, VerticalHelpers as verts } from '@tcgplayer/martech-components';
import delve from 'dlv';
import { mapState } from 'vuex';

export default {
  name: 'desktop-magnify-image',
  components: {
    MartechCardSpotlight,
    ScreenOverlay,
  },
  mixins: [ deviceType ],
  props: {
    cardData: {
      type: Object,
      required: false,
      default: null,
    },
    isFlipped: {
      type: Boolean,
      required: false,
      default: false,
    },
    isProduct: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardProductUrl: {
      type: String,
      required: false,
      default: '',
    },
    loaded: {
      type: Boolean,
      required: false,
    },
    cardId: {
      type: [ Number, String, ],
      required: false,
      default: '',
    },
    analyticsData: {
      type: Object,
      required: false,
      // eslint-disable-next-line
      default: () => { return { analyticsCampaign: '', analyticsSource: '', }; }
    },
  },
  data() {
    return {
      cardImageFallback: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/ghost_imageFallbackPlaceholder%402x.png',
    };
  },
  computed: {
    ...mapState('article', [ 'article', 'author' ]),
  },
  created() {
    this.isActive = this.loaded;
  },
  mounted() {
    document.addEventListener('keyup', this.escapeMagDrawer);
  },
  unmounted() {
    document.removeEventListener('keyup', this.escapeMagDrawer);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    escapeMagDrawer(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    },
    buyEvent(view) {
      amplitudeEvent('martech', 'buyButton', {
        component: this.$options.name,
        component_view: view,
        title: delve(this, 'article.title'),
        type: 'card',
        name: this.name,
        game: verts.displayName(delve(this, 'cardData.game', delve(this, 'cardData.vertical', ''))),
        utm_source: this.analyticsData.analyticsSource || this.analyticsData.utm_source,
        itm_source: this.analyticsData.analyticsSource || this.analyticsData.utm_source,
        author: delve(this, 'author.name'),
        date_published: delve(this, 'article.dateTime'),
        format: delve(this, 'article.format'),
        tags: delve(this, 'article.tags'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.magnify-image-container {
  position: fixed;
  z-index: 2147483641;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100px;
  left: 0;
  width: 100%;
}

.magnified-popup {
  position: absolute;
  z-index: 2;
  top: 0;
}
</style>
